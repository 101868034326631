/* eslint-disable prefer-template */
import { TIME_SERVICE_URL } from '../apis';
import { AppointmentState } from './interfaces';

const APPOINTMENTS_APIS = {
  createAppointment: () => `${TIME_SERVICE_URL}appointments`,
  createAppointmentWithoutRooms: () => `${TIME_SERVICE_URL}appointments/no-room-support`,
  updateStatus: (appointmentId: string) =>
    `${TIME_SERVICE_URL}appointments/${appointmentId}/status`,
  updateStaff: (appointmentId: string) => `${TIME_SERVICE_URL}appointments/${appointmentId}/staff`,
  cancelAppointment: (appointmentId: string) =>
    `${TIME_SERVICE_URL}appointments/${appointmentId}/cancel`,
  reschedule: (appointmentId: string) =>
    `${TIME_SERVICE_URL}appointments/${appointmentId}/reschedule`,
  getBookedAppointments: (start: string, end: string, clinic?: string, staff?: string) =>
    `${TIME_SERVICE_URL}appointments/booked?start=${start}&end=${end}` +
    (clinic ? `&clinic=${clinic}` : '') +
    (staff ? `&staff=${staff}` : ''),
  getAvailableAppointments: (day: string, clinic: string, staff: string) =>
    `${TIME_SERVICE_URL}appointments/available?start=${day}&end=${day}&staff=${staff}&clinic=${clinic}`,
  queryAppointments: (
    clinicId: string,
    query?: {
      supportRooms?: boolean,
      clinicName?: string;
      patientName?: string;
      patientId?: string;
      staffId?: string;
      status?: AppointmentState | '';
      minStart?: string;
      maxStart?: string;
      minCreatedAt?: string;
      maxCreatedAt?: string;
      page?: number;
      pageSize?: number;
      sortType?: string;
      sortOrder?: 'asc' | 'desc';
    },
  ) =>
    `${TIME_SERVICE_URL}appointments?clinicId=${clinicId}` +
    (query?.clinicName ? `&clinicName=${query.clinicName}` : '') +
    (query?.patientName ? `&patientName=${query.patientName}` : '') +
    (query?.patientId ? `&patientId=${query.patientId}` : '') +
    (query?.staffId ? `&staffId=${query.staffId}` : '') +
    (query?.status ? `&status=${query.status}` : '') +
    (query?.supportRooms ? `&supportRooms=${query.supportRooms}` : '') +
    (query?.minStart ? `&minStart=${query.minStart}` : '') +
    (query?.maxStart ? `&maxStart=${query.maxStart}` : '') +
    (query?.minCreatedAt ? `&minCreatedAt=${query.minCreatedAt}` : '') +
    (query?.maxCreatedAt ? `&maxCreatedAt=${query.maxCreatedAt}` : '') +
    (query?.page ? `&page=${query.page}` : '') +
    (query?.pageSize ? `&pageSize=${query.pageSize}` : '') +
    (query?.sortType ? `&sortType=${query.sortType}` : '') +
    (query?.sortOrder ? `&sortOrder=${query.sortOrder}` : ''),
  addMedicalRecord: (appointmentId: string) =>
    `${TIME_SERVICE_URL}appointments/${appointmentId}/medicalRecord`,
  updateMedicalRecord: (appointmentId: string, medicalRecordId: string) =>
    `${TIME_SERVICE_URL}appointments/${appointmentId}/medicalRecord/${medicalRecordId}`,
  removeMedicalRecord: (appointmentId: string, medicalRecordId: string) =>
    `${TIME_SERVICE_URL}appointments/${appointmentId}/medicalRecord/${medicalRecordId}`,
  getMinimalMetrics: (
    clinicId: string,
    query?: {
      clinicName?: string;
      patientName?: string;
      patientId?: string;
      staffId?: string;
      status?: AppointmentState | '';
      minStart?: string;
      maxStart?: string;
      minCreatedAt?: string;
      maxCreatedAt?: string;
    }
  ) =>
    `${TIME_SERVICE_URL}appointments/metrics/minimal?clinicId=${clinicId}` +
    (query?.clinicName ? `&clinicName=${query.clinicName}` : '') +
    (query?.patientName ? `&patientName=${query.patientName}` : '') +
    (query?.patientId ? `&patientId=${query.patientId}` : '') +
    (query?.staffId ? `&staffId=${query.staffId}` : '') +
    (query?.status ? `&status=${query.status}` : '') +
    (query?.minStart ? `&minStart=${query.minStart}` : '') +
    (query?.maxStart ? `&maxStart=${query.maxStart}` : '') +
    (query?.minCreatedAt ? `&minCreatedAt=${query.minCreatedAt}` : '') +
    (query?.maxCreatedAt ? `&maxCreatedAt=${query.maxCreatedAt}` : '')
};
export default APPOINTMENTS_APIS;
