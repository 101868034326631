import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { feeStatus, IClinicInvoicesMetrics, IInvoice, IInvoiceType } from './interfaces';
import APIS from './apis';

export const getCurrentPatientInvoice = createAsyncThunk(
  'invoices/getCurrentPatientInvoice',
  async (
    requestData: {
      patientId: string;
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice[] | any> => {
    const { patientId, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getPatientInvoice(patientId, organizationId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getClinicInvoicesMetrics = createAsyncThunk(
  'invoices/getClinicInvoicesMetrics',
  async (
    requestData: {
      organizationId: string;
      clinicId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinicInvoicesMetrics | any> => {
    const { organizationId, clinicId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getClinicMetrics(organizationId, clinicId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getOrganizationInvoice = createAsyncThunk(
  'invoices/getOrganizationInvoice',
  async (
    requestData: {
      organizationId: string;
      clinicId?: string;
      clinicName?: string;
      patientName?: string;
      packageId?: string;
      appointment?: string;
      minDiscount?: number;
      maxDiscount?: number;
      minTotal?: number;
      maxTotal?: number;
      status?: feeStatus | '';
      type?: IInvoiceType | '';
      minCreatedAt?: string;
      maxCreatedAt?: string;
      page?: number;
      pageSize?: number;
      sortType?: string;
      sortOrder?: 'asc' | 'desc';
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice[] | any> => {
    const {
      organizationId,
      clinicId,
      clinicName,
      patientName,
      appointment,
      packageId,
      minDiscount,
      maxDiscount,
      minTotal,
      maxTotal,
      status,
      type,
      minCreatedAt,
      maxCreatedAt,
      page,
      pageSize,
      sortType,
      sortOrder,
      runFunction
    } = requestData;
    const query = {
      clinicId,
      clinicName,
      patientName,
      packageId,
      appointment,
      minDiscount,
      maxDiscount,
      minTotal,
      maxTotal,
      status,
      type,
      minCreatedAt,
      maxCreatedAt,
      page,
      pageSize,
      sortType,
      sortOrder
    };
    try {
      const response = await axiosInstance.get(
        APIS.queryOrganizationInvoice(organizationId, query)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
