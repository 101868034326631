// material-ui
import { Grid, Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import MenuProfile from './MenuProfile';
import { useAppSelector } from 'store/hooks';
import CreateAppointmentDialog from './CreateAppointment';
import BuyPackageDialog from './BuyPackage';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const addRoleToData = (user: any, organization: any, clinic: any): any => {
  if (!organization._id || !clinic._id) return null;
  const userOrg = user?.organizations?.find((org: any) => org._id === organization._id);

  return {
    role: userOrg?.role,
    occupation: userOrg?.clinics.find((cl: any) => cl._id === clinic._id)?.role
  };
};

const MenuList = () => {
  const { data: user } = useAppSelector((state) => state.user);
  const { organization } = useAppSelector((state) => state.organization);
  const { clinic } = useAppSelector((state) => state.clinics);
  const role = addRoleToData(user, organization, clinic);

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} role={role?.role} />;

      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <MenuProfile key={user._id} item={{ ...user, ...role }} />
      <Grid container width="100%" padding={1}>
        <CreateAppointmentDialog patientProfile="false" />
        <Grid item xs={12} marginTop={1}>
          <BuyPackageDialog />
        </Grid>
      </Grid>
      {navItems}
    </>
  );
};

export default MenuList;
