import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { INameAndId } from '../general.interface';
import { IOrganization } from '../organization/interfaces';
import APIS from './apis';
import {
  IAppointment,
  IAppointmentAvailable,
  AppointmentState,
  IAppointmentList,
  IMinMetrics
} from './interfaces';

// export const getBookedAppointments = createAsyncThunk(
//   'appointments/getBookedAppointments',
//   async (
//     requestData: {
//       clinic?: string;
//       staff?: string;
//       startDate: Date;
//       endDate?: Date;
//       runFunction?: () => void;
//     },
//     { rejectWithValue }
//   ): Promise<IAppointment[] | any> => {
//     const { clinic, staff, startDate, runFunction } = requestData;
//     let { endDate } = requestData;

//     startDate.setUTCHours(0, 0, 0, 0);

//     if (!endDate) {
//       endDate = new Date(startDate);
//       endDate.setDate(startDate.getDate() + 1);
//     }
//     const start = startDate.toISOString();
//     const end = endDate.toISOString();

//     try {
//       const response = await axiosInstance.get(
//         APIS.getBookedAppointments(start, end, clinic, staff)
//       );
//       if (runFunction) runFunction();
//       return response.data;
//     } catch (error: any) {
//       return rejectWithValue(error?.response?.data);
//     }
//   }
// );

export const getAvailableAppointments = createAsyncThunk(
  'appointments/getAvailableAppointments',
  async (
    requestData: {
      clinic: string;
      staff: string;
      day: Date;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointmentAvailable[] | any> => {
    const { clinic, staff, day, runFunction } = requestData;
    const startDate = day.toISOString();

    try {
      const response = await axiosInstance.get(
        APIS.getAvailableAppointments(startDate, clinic, staff)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createAppointment = createAsyncThunk(
  'appointments/createAppointment',
  async (
    requestData: {
      scheduleId: string;
      patient: INameAndId;
      start: string;
      organization: IOrganization;
      services: any;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { scheduleId, patient, start, organization, services, runFunction } = requestData;

    const body = {
      schedule: scheduleId,
      patient,
      start,
      discount: 0,
      status: 'Pending',
      organization: {
        // eslint-disable-next-line no-underscore-dangle
        _id: organization._id,
        name: organization.name
      },
      services
    };
    try {
      const response = await axiosInstance.post(APIS.createAppointment(), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateAppointment = createAsyncThunk(
  'appointments/updateAppointment',
  async (
    requestData: {
      appointmentId: string;
      status: AppointmentState;
      duration?: number;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, status, duration, runFunction } = requestData;

    const body = {
      status,
      duration
    };
    try {
      const response = await axiosInstance.patch(APIS.updateStatus(appointmentId), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const queryAppointments = createAsyncThunk(
  'appointments/queryAppointments',
  async (
    requestData: {
      clinicId: string;
      query: {
        supportRooms?: boolean;
        patientId?: string;
        patientName?: string;
        staffId?: string;
        status?: AppointmentState | '';
        minStart?: string;
        maxStart?: string;
        minCreatedAt?: string;
        maxCreatedAt?: string;
        page?: number;
        pageSize?: number;
        sortType?: string;
        sortOrder?: 'asc' | 'desc';
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointmentList | any> => {
    const { clinicId, query, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(APIS.queryAppointments(clinicId, query));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getMinimalMetrics = createAsyncThunk(
  'appointments/getMinimalMetrics',
  async (
    requestData: {
      clinicId: string;
      query: {
        patientId?: string;
        patientName?: string;
        staffId?: string;
        status?: AppointmentState | '';
        minStart?: string;
        maxStart?: string;
        minCreatedAt?: string;
        maxCreatedAt?: string;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IMinMetrics | any> => {
    const { clinicId, query, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(APIS.getMinimalMetrics(clinicId, query));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateAppointmentStaff = createAsyncThunk(
  'appointments/updateAppointmentStaff',
  async (
    requestData: {
      appointmentId: any;
      staff: any;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, staff, runFunction } = requestData;

    const body = {
      staff
    };
    try {
      const response = await axiosInstance.patch(APIS.updateStaff(appointmentId), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
