// hooks
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// material ui
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider,
  Button,
  Tooltip,
  IconButton,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// project imports
import SubCard from 'ui-component/cards/SubCard';

// types
import { gridSpacing } from 'store/constant';
import { IAppointmentMedicalRecord } from 'redux/appointments/interfaces';

// actions
import { changeMedicalRecord } from 'redux/createAppointment';

// Formik
import * as yup from 'yup';
import { useFormik } from 'formik';

// icons
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

const sxDivider = {
  borderColor: 'primary.light'
};

const AddRecordSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required()
});

export default function MedicalRecordSection() {
  const dispatch = useAppDispatch();
  const { medicalRecord } = useAppSelector((state) => state.createAppointment);
  const { data: user } = useAppSelector((state) => state.user);
  const [addRecord, setAddRecord] = useState(false);
  const toggleAddRecord = () => {
    setAddRecord(!addRecord);
  };

  const handleRemoveRecord = (medicalRecordIndex: number) => {
    let newRecord: Partial<IAppointmentMedicalRecord>[] = [];
    newRecord = newRecord.concat(medicalRecord);
    newRecord.splice(medicalRecordIndex, 1);
    dispatch(changeMedicalRecord(newRecord));
  };

  const submitAddRecord = (values: { title: string; description: string }) => {
    if (user._id) {
      let newRecord: Partial<IAppointmentMedicalRecord>[] = [];
      newRecord = newRecord.concat(medicalRecord);
      newRecord.push({
        ...values,
        author: {
          _id: user._id,
          name: user.name
        }
      });
      dispatch(changeMedicalRecord(newRecord));
      formik.resetForm();
      toggleAddRecord();
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: ''
    },
    validationSchema: AddRecordSchema,
    onSubmit: submitAddRecord,
    enableReinitialize: true
  });

  return (
    <SubCard
      title="Records"
      secondary={
        <Button
          variant={addRecord ? 'contained' : 'outlined'}
          size="small"
          onClick={toggleAddRecord}
        >
          Add Record
        </Button>
      }
      content={false}
    >
      {addRecord && (
        <Grid margin={2}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={3}>
              <TextField
                id="medical-record-title"
                label="Title *"
                placeholder="Treatment Area"
                fullWidth
                size="small"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                id="medical-record-description"
                label="Description *"
                placeholder="Patient Symptoms"
                multiline
                fullWidth
                size="small"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>
            <Grid item xs={6} md={2} container justifyContent="flex-end">
              <Grid item>
                <LoadingButton onClick={formik.submitForm} variant="outlined">
                  Add
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {addRecord && (
        <Grid item xs={12}>
          <Divider sx={sxDivider} />
        </Grid>
      )}
      {medicalRecord?.length > 0 && (
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ pl: 3 }}>
                      Staff
                    </TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="right" sx={{ pr: 3 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicalRecord.map((record, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={{ pl: 3 }}>
                        {record.author.name}
                      </TableCell>
                      <TableCell>
                        <Typography align="left" variant="subtitle1">
                          {record.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{record.description}</TableCell>
                      <TableCell sx={{ pr: 3 }} align="right">
                        <Tooltip placement="top" title="Remove Service">
                          <IconButton
                            color="error"
                            aria-label="delete"
                            size="small"
                            onClick={() => handleRemoveRecord(index)}
                          >
                            <DeleteTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </SubCard>
  );
}
