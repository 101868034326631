import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { INameAndId } from '../general.interface';
import APIS from './apis';
import { DayOfWeek, ISchedule, ScheduleType } from './interfaces';

export const getStaffSchedule = createAsyncThunk(
  'schedules/queryScheduleByClinicOrStaff',
  async (
    requestData: {
      clinicId: string;
      staffId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { clinicId, staffId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        APIS.getAllClinicOrStaffSchedules(clinicId, staffId)
      );
      if (runFunction) runFunction();
      return response.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getClinicSchedules = createAsyncThunk(
  'schedules/getClinicSchedules',
  async (
    requestData: {
      clinicId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { clinicId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getAllClinicSchedules(clinicId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createSchedule = createAsyncThunk(
  'schedules/createSchedule',
  async (
    requestData: {
      createBody: {
        staff: INameAndId;
        clinic: INameAndId;
        slotDuration: number;
        room: boolean;
        scheduleType: ScheduleType;
        saturday?: DayOfWeek[];
        sunday?: DayOfWeek[];
        monday?: DayOfWeek[];
        tuesday?: DayOfWeek[];
        wednesday?: DayOfWeek[];
        thursday?: DayOfWeek[];
        friday?: DayOfWeek[];
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { createBody, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(APIS.createSchedule(), { ...createBody });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  'schedules/updateSchedule',
  async (
    requestData: {
      scheduleId: string;
      body: {
        slotDuration?: number;
        saturday?: DayOfWeek;
        sunday?: DayOfWeek;
        monday?: DayOfWeek;
        tuesday?: DayOfWeek;
        wednesday?: DayOfWeek;
        thursday?: DayOfWeek;
        friday?: DayOfWeek;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { scheduleId, body, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(APIS.updateSchedule(scheduleId), body);
      if (runFunction) runFunction();

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getRoomsAvailability = createAsyncThunk(
  'schedules/getRoomsAvailability',
  async (
    requestData: {
      clinicId: string;
      start: Date;
      end: Date;
      rooms?: [];
      doctors?: string[];
      staffOriented: boolean;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { clinicId, start, end, rooms, doctors, staffOriented, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getRoomsSlots(), {
        params: { clinic: clinicId, start, end, rooms, doctors, staffOriented }
      });
      if (runFunction) runFunction();

      return { data: response.data, staffOriented };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
