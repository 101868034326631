// hooks
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

// material-ui
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField, Typography, DialogContentText } from '@mui/material';
import { gridSpacing } from 'store/constant';

// formik
import * as yup from 'yup';
import { useFormik } from 'formik';

// interfaces
import { Role } from 'redux/organization/interfaces';

// actions
import { inviteStaff } from 'redux/organization/actions';
import { SNACKBAR_OPEN } from 'store/actions';
import { ClinicRole } from 'redux/clinics/interfaces';

const InviteStaffSchema = yup.object().shape({
  email: yup.string().required(),
  role: yup.string().required()
});

const InviteStaffForm = ({ handleSteps }: { handleSteps: (action: 'next' | 'back') => void }) => {
  const dispatch = useAppDispatch();
  const { organization } = useAppSelector((state) => state.organization);
  const { clinic, loading, error } = useAppSelector((state) => state.clinics);

  const [invitedStaff, setInvitedStaff] = useState<{ email: string; role: string }[]>([]);

  const successFn = () => {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Invitation Sent',
      variant: 'alert',
      alertSeverity: 'success'
    });
    invitedStaff.push(formik.values);
    setInvitedStaff(invitedStaff);
    formik.resetForm();
  };

  const submitInviteStaff = (values: { email: string; role: string; occupation: string }) => {
    dispatch(
      inviteStaff({
        ...values,
        organizationId: organization._id,
        clinicId: clinic._id,
        runFunction: successFn
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
      occupation: ''
    },
    validationSchema: InviteStaffSchema,
    onSubmit: submitInviteStaff,
    enableReinitialize: true
  });

  useEffect(() => {
    if (error?.validationErrors) formik.setErrors(error?.validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.validationErrors]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DialogContentText>
          <Typography variant="body2" color="secondary" component="h3">
            Now, let’s add your Clinic’s staff members and assign them appropriate roles and
            permissions{' '}
          </Typography>
          <Typography variant="body2" color="grey.900" component="h3">
            Enter the email and role of each member then click invite so that he can receive your
            invitation.{' '}
          </Typography>{' '}
        </DialogContentText>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          name="email"
          id="staff-email-invite-onboarding"
          label="Email *"
          size="small"
          fullWidth
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          name="role"
          id="staff-role-invite-onboarding"
          label="Role *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.role}
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value={Role.Staff}>{Role.Staff}</MenuItem>
          <MenuItem value={Role.Manager}>{Role.Manager}</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={8} sm={4} md={3}>
        <TextField
          name="occupation"
          id="staff-assign-role-onboarding"
          label="Occupation *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.occupation}
          error={formik.touched.occupation && Boolean(formik.errors.occupation)}
          helperText={formik.touched.occupation && formik.errors.occupation}
        >
          <MenuItem value="" disabled>
            Select Occupation
          </MenuItem>
          <MenuItem value={ClinicRole.DOCTOR}>Doctor</MenuItem>
          <MenuItem value={ClinicRole.ACCOUNTANT}>Accountant</MenuItem>
          <MenuItem value={ClinicRole.RECEPTIONIST}>Receptionist</MenuItem>
          <MenuItem value={ClinicRole.NURSE}>Nurse</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6} sm={1} container justifyContent="flex-end">
        <Grid item>
          <LoadingButton variant="outlined" disabled={loading} onClick={formik.submitForm}>
            Invite
          </LoadingButton>
        </Grid>
      </Grid>
      {invitedStaff.map((inv, index) => (
        <Grid key={inv.email + index} item xs={12}>
          <Typography>
            {inv.email} ( {inv.role} )
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default InviteStaffForm;
