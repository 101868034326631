import { createSlice } from '@reduxjs/toolkit';
import { IRoom, IRoomState } from './interfaces';
import {
  createRoom,
  deleteRoom,
  deleteShift,
  getCurrentRoomSchedule,
  retrieveRooms,
  updateRoom,
  updateSchedule
} from './actions';
import { createSchedule } from 'redux/schedules/actions';

const initialState: IRoomState = {
  rooms: [],
  schedule: null,
  loading: false,
  error: false
};

const rooms = createSlice({
  name: 'rooms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRoom.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createRoom.fulfilled, (state, action) => {
        state.loading = false;
        const newRooms: IRoom[] = Object.assign([], state.rooms);
        newRooms.push(action.payload);
        state.rooms = newRooms;
      })
      .addCase(createRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(createSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(retrieveRooms.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(retrieveRooms.fulfilled, (state, action) => {
        state.loading = false;
        state.rooms = action.payload;
      })
      .addCase(retrieveRooms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRoom.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteRoom.fulfilled, (state, action) => {
        state.loading = false;
        const newRooms: IRoom[] = Object.assign([], state.rooms);
        state.rooms = newRooms.filter((room) => room._id !== action.payload._id);
      })
      .addCase(deleteRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateRoom.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateRoom.fulfilled, (state, action) => {
        state.loading = false;
        const newRooms: IRoom[] = Object.assign([], state.rooms);
        state.rooms = newRooms.map((room) => {
          if (room._id === action.payload._id) {
            room = action.payload;
          }
          return room;
        });
      })
      .addCase(updateRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentRoomSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCurrentRoomSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(getCurrentRoomSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteShift.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteShift.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(deleteShift.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addDefaultCase((state, action) => {});
  }
});

export default rooms.reducer;
