import { TIME_SERVICE_URL } from '../apis';

const SCHEDULES_APIS = {
  createSchedule: () => `${TIME_SERVICE_URL}schedule`,
  getAllClinicOrStaffSchedules: (clinicId?: string, staffId?: string) =>
    // eslint-disable-next-line prefer-template
    `${TIME_SERVICE_URL}schedule?` +
    (clinicId ? `clinic=${clinicId}` : '') +
    (staffId ? `&staff=${staffId}` : ''),
  getAllClinicSchedules: (clinicId: string) =>
    // eslint-disable-next-line prefer-template
    `${TIME_SERVICE_URL}schedule?clinic=${clinicId}`,
  getAllClinicStaffRoomSchedules: (clinicId: string) =>
    // eslint-disable-next-line prefer-template
    `${TIME_SERVICE_URL}schedule/clinic/${clinicId}`,
  getScheduleById: (scheduleById: string) => `${TIME_SERVICE_URL}schedule/${scheduleById}`,
  updateSchedule: (scheduleById: string) => `${TIME_SERVICE_URL}schedule/${scheduleById}`,
  deleteSchedule: (scheduleById: string) => `${TIME_SERVICE_URL}schedule/${scheduleById}`,
  deleteAllStaffSchedule: (staffId: string) => `${TIME_SERVICE_URL}schedule/staff/${staffId}`,
  deleteAllClinicSchedule: (clinicId: string) => `${TIME_SERVICE_URL}schedule/clinic/${clinicId}`,
  getRoomsSlots: () => `${TIME_SERVICE_URL}appointments/rooms/available`,
  getAvailableResources: () => `${TIME_SERVICE_URL}resources`
};
export default SCHEDULES_APIS;
