import { CLINIC_SERVICE_URL } from '../apis';

const CLINICS_APIS = {
  getClinics: () => `${CLINIC_SERVICE_URL}clinics`,
  getClinic: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}`,
  create: () => `${CLINIC_SERVICE_URL}clinics`,
  updateClinic: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}`,
  deleteClinic: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}`,
  getStaff: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}/staff`,
  updateStaff: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}/staff`,
  getClinicService: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}/services`,
  addClinicService: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}/services`,
  removeClinicService: (clinicId: string, serviceId: string) =>
    `${CLINIC_SERVICE_URL}clinics/${clinicId}/services/${serviceId}`,
  createClinicServiceFees: (clinicId: string, serviceId: string) =>
    `${CLINIC_SERVICE_URL}clinics/${clinicId}/services/${serviceId}`,
  updateServiceFees: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}/services`,
  removeClinicServiceFees: (clinicId: string, serviceId: string, serviceFeeId: string) =>
    `${CLINIC_SERVICE_URL}clinics/${clinicId}/services/${serviceId}/servicefee/${serviceFeeId}`,
  createPackage: (clinicId: string) => `${CLINIC_SERVICE_URL}clinics/${clinicId}/packages`,
  deletePackage: (clinicId: string, packageId: string) =>
    `${CLINIC_SERVICE_URL}clinics/${clinicId}/packages/${packageId}`,
  getPackage: (packageId: string) => `${CLINIC_SERVICE_URL}packages/${packageId}`,
  updatePackageDetails: (packageId: string) => `${CLINIC_SERVICE_URL}packages/${packageId}`,
  addPackageService: (packageId: string) => `${CLINIC_SERVICE_URL}packages/${packageId}/service`,
  updatePackageService: (packageId: string, serviceId: string) =>
    `${CLINIC_SERVICE_URL}packages/${packageId}/service/${serviceId}`,
  removePackageService: (packageId: string, serviceId: string) =>
    `${CLINIC_SERVICE_URL}packages/${packageId}/service/${serviceId}`
};
export default CLINICS_APIS;
