import { IAppointmentMedicalRecord } from 'redux/appointments/interfaces';
import { INameAndId } from 'redux/general.interface';
import { IInvoiceServices } from 'redux/invoices/interfaces';
import { IOrganization } from 'redux/organization/interfaces';
import { IPatient } from 'redux/patients/interfaces';
import { ISchedule } from 'redux/schedules/interfaces';

export interface IAppointmentAvailable {
  start: string;
  end: string;
  _id: string;
}
export enum Steps {
  patient = 'Patient',
  date = 'Date'
}
export enum IAppointmentType {
  diagnosis = 'Diagnosis',
  followUp = 'Follow Up',
  consultation = 'Consultation'
}
export interface ICreateAppointmentState {
  loading: boolean;
  error: any;
  step: Steps;
  createPatient: boolean;
  availableSlots: IAppointmentAvailable[];
  staff?: string;
  schedule?: ISchedule;
  patient?: IPatient;
  services: IInvoiceServices[];
  resources: INameAndId[];
  medicalRecord: IAppointmentMedicalRecord[];
  selectedSlot?: IAppointmentAvailable;
  type?: IAppointmentType;
}

export interface ICreateAppointment {
  scheduleId: string;
  shift: string;
  patient: INameAndId;
  expectedStartTime: string;
  expectedEndTime?: string;
  organization: IOrganization;
  runFunction?: () => void;
}
