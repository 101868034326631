import { TIME_SERVICE_URL } from '../apis';

const ROOMS_APIS = {
  createRoom: () => `${TIME_SERVICE_URL}rooms`,
  createSchedule: () => `${TIME_SERVICE_URL}schedule`,
  findAllRooms: (name?: string, clinic?: string) =>
    // eslint-disable-next-line prefer-template
    `${TIME_SERVICE_URL}rooms/find?` +
    (name ? `name=${name}` : '') +
    (clinic ? `clinic=${clinic}` : ''),

  updateRoom: (roomId: any) => `${TIME_SERVICE_URL}rooms/${roomId}`,
  getCurrentRoomSchedule: (clinicId: any, roomId: any) =>
    // eslint-disable-next-line prefer-template
    `${TIME_SERVICE_URL}schedule?` +
    (clinicId ? `clinic=${clinicId}` : '') +
    (roomId ? `&staff=${roomId}` : ''),
  deleteRoom: (roomId: string) => `${TIME_SERVICE_URL}rooms/${roomId}`,
  deleteShift: (scheduleId: string, day: string, shiftId: string) =>
    `${TIME_SERVICE_URL}schedule/${scheduleId}/day/${day}/shift/${shiftId}`,
  updateSchedule: (scheduleById: string) => `${TIME_SERVICE_URL}schedule/${scheduleById}`
};
export default ROOMS_APIS;
