import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';

export default function Index({ note }: { note: string }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InfoIcon color="info" style={{ marginRight: '5px' }} />
      <Typography color="info.main" component="span" variant="subtitle1">
        {note}
      </Typography>
    </div>
  );
}
