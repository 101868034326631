import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const MyDayPage = Loadable(lazy(() => import('views/my-day-page')));
const SettingsPage = Loadable(lazy(() => import('views/settings-page')));
const PatientPage = Loadable(lazy(() => import('views/patients/list')));
const CalendarPage = Loadable(lazy(() => import('views/calendar-page')));
const Billing = Loadable(lazy(() => import('views/billing-page')));
const ManageStaff = Loadable(lazy(() => import('views/settings-page/organization/manage-staff')));
const InvitationsPage = Loadable(
  lazy(() => import('views/settings-page/organization/invitations'))
);
const ManageClinics = Loadable(lazy(() => import('views/settings-page/organization/clinics')));
const ManagePermissions = Loadable(
  lazy(() => import('views/settings-page/organization/manage-permissions'))
);
const ManageMedicalTemplates = Loadable(
  lazy(() => import('views/settings-page/organization/medical-templates'))
);
const TemplateForm = Loadable(
  lazy(() => import('views/settings-page/organization/medical-templates/create-profile-sections'))
);
const ServicesPage = Loadable(lazy(() => import('views/settings-page/clinic/services')));
const ClinicStaffPage = Loadable(lazy(() => import('views/settings-page/clinic/staff')));
const ClinicInformation = Loadable(lazy(() => import('views/settings-page/clinic/general-info')));
const ManageClinicPackages = Loadable(lazy(() => import('views/settings-page/clinic/packages')));
const ManageClinicRooms = Loadable(lazy(() => import('views/settings-page/clinic/rooms')));
const PatientProfilePage = Loadable(lazy(() => import('views/patient-profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <MyDayPage />
    },
    {
      path: '/settings',
      element: <SettingsPage />
    },
    {
      path: '/billing',
      element: <Billing />
    },
    {
      path: '/calendar',
      element: <CalendarPage />
    },
    {
      path: '/patients',
      element: <PatientPage />
    },
    {
      path: '/organization/manage-staff',
      element: <ManageStaff />
    },
    {
      path: '/organization/invitations',
      element: <InvitationsPage />
    },
    {
      path: '/clinic/services',
      element: <ServicesPage />
    },
    {
      path: '/organization/clinics',
      element: <ManageClinics />
    },
    {
      path: '/organization/permissions',
      element: <ManagePermissions />
    },
    {
      path: '/clinic/packages',
      element: <ManageClinicPackages />
    },
    {
      path: '/clinic/rooms',
      element: <ManageClinicRooms />
    },
    {
      path: '/clinic/information',
      element: <ClinicInformation />
    },
    {
      path: '/clinic/staff',
      element: <ClinicStaffPage />
    },
    {
      path: '/patient/:id',
      element: <PatientProfilePage />
    },
    {
      path: '/organization/medical-templates',
      element: <ManageMedicalTemplates />
    },
    {
      path: '/organization/medical-templates/create',
      element: <TemplateForm />
    },
    {
      path: '/organization/medical-templates/edit/:id',
      element: <TemplateForm />
    }
  ]
};

export default MainRoutes;
