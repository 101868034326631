import { CLINIC_SERVICE_URL, SUBSCRIPTION_SERVICE_URL } from '../apis';

const APIS = {
  getUserOrganizations: (staffId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/staff/${staffId}`,
  getUserClinics: (organizationId: string, staffId: string) =>
    `${CLINIC_SERVICE_URL}clinics/organization/${organizationId}/staff/${staffId}`,
  getOrganizationClinics: (organizationId: string) =>
    `${CLINIC_SERVICE_URL}clinics/organization/${organizationId}`
};
export default APIS;
