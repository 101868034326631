import { Dispatch, SetStateAction } from 'react';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function DatePicker(props: {
  value: Date | null;
  setValue: Dispatch<SetStateAction<any>>;
}) {
  const { value, setValue } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        // displayStaticWrapperAs="desktop"
        orientation="landscape"
        openTo="day"
        value={dayjs(value)}
        // disabled={disabled}
        // maxDate={maxDate ? dayjs(maxDate) : undefined}
        onChange={(newValue: any) => setValue(newValue)}
        minDate={dayjs(new Date())}
        // renderInput={(params: any) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
