import { INameAndId } from '../general.interface';
import { IPlan } from '../plans/interfaces';
import { Invitation } from '../user/interfaces';

export enum Role {
  Manager = 'manager',
  Owner = 'owner',
  Staff = 'staff'
}
export interface IOrganizationStaff {
  _id: string;
  name: string;
  role: Role;
  joinedAt: string;
}

export interface IOrganizationClinic {
  _id: string;
  name: string;
  supportRooms: boolean;
}

export interface IOrganization {
  _id: string;
  owner: INameAndId;
  name: string;
  country: string;
  onBoarding?: Boolean;
  staff?: IOrganizationStaff[];
  clinics?: IOrganizationClinic[];
  currentPlan?: {
    plan: IPlan;
    createdAt: string;
    duration: number;
    expiration: string;
    autoRenew: boolean;
  };
  createdAt?: string;
  updatedAt?: string;
}

export interface IOrganizationState {
  organization: IOrganization;
  invitations?: Invitation[];
  loading: boolean;
  error: boolean | any;
  isSubscripted: boolean;
}
