import { createSlice } from '@reduxjs/toolkit';
import { IPlanState } from './interfaces';
import { retrievePlans } from './actions';

const initialState: IPlanState = {
  data: {
    data: [],
    page: 0,
    pages: 0,
    count: 0,
    pageSize: 0
  },
  loading: false,
  error: false
};

const plans = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePlans.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(retrievePlans.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(retrievePlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});

export default plans.reducer;
