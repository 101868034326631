export const CLINIC_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3006/'
    : 'http://beta.clinera.net/clinic/api/';

export const SUBSCRIPTION_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3007/'
    : 'http://beta.clinera.net/subscription/api/';

export const USER_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3008/'
    : 'http://beta.clinera.net/user/api/';

export const TIME_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3009/'
    : 'http://beta.clinera.net/appointment/api/';

export const FINANCE_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3010/'
    : 'http://beta.clinera.net/finance/api/';

export const PATIENT_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3011/'
    : 'http://beta.clinera.net/patient/api/';
