import { createSlice } from '@reduxjs/toolkit';
import { IClinicState } from './interfaces';
import {
  addPackageService,
  addService,
  addServiceFee,
  addStaffToClinic,
  createClinic,
  createPackage,
  deletePackage,
  deletePackageService,
  getPackage,
  removeService,
  removeServiceFee,
  retrieveClinic,
  updateClinic,
  updatePackageDetails
} from './actions';

const clinicInitialState = {
  _id: '',
  name: '',
  owner: '',
  organization: '',
  supportQueue: false,
  supportRooms: false,
  active: false
};
const packageInitialState = {
  _id: '',
  name: '',
  fee: 0,
  services: []
};
const initialState: IClinicState = {
  clinic: clinicInitialState,
  package: packageInitialState,
  loading: false,
  error: false,
  packageLoading: false,
  packageError: false
};

const clinics = createSlice({
  name: 'clinics',
  initialState,
  reducers: {
    clearClinicData(state) {
      state.clinic = clinicInitialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(retrieveClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(retrieveClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }).addCase(createClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(createClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(updateClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addStaffToClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addStaffToClinic.fulfilled, (state, action) => {
        state.loading = false;
        // state.clinic = action.payload;
      })
      .addCase(addStaffToClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addServiceFee.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addServiceFee.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(addServiceFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeServiceFee.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeServiceFee.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(removeServiceFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeService.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(removeService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addService.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic = action.payload;
      })
      .addCase(addService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPackage.pending, (state) => {
        state.packageLoading = true;
        state.packageError = false;
      })
      .addCase(createPackage.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.clinic = action.payload;
      })
      .addCase(createPackage.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      })
      .addCase(updatePackageDetails.pending, (state) => {
        state.packageLoading = true;
        state.packageError = false;
      })
      .addCase(updatePackageDetails.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.package = action.payload;
      })
      .addCase(updatePackageDetails.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      })
      .addCase(deletePackage.pending, (state) => {
        state.packageLoading = true;
        state.packageError = false;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.clinic = action.payload;
      })
      .addCase(deletePackage.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      })
      .addCase(getPackage.pending, (state) => {
        state.packageLoading = true;
        state.packageError = false;
      })
      .addCase(getPackage.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.package = action.payload;
      })
      .addCase(getPackage.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      })
      .addCase(addPackageService.pending, (state) => {
        state.packageLoading = true;
        state.packageError = false;
      })
      .addCase(addPackageService.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.package = action.payload;
      })
      .addCase(addPackageService.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      })
      .addCase(deletePackageService.pending, (state) => {
        state.packageLoading = true;
        state.packageError = false;
      })
      .addCase(deletePackageService.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.package = action.payload;
      })
      .addCase(deletePackageService.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      })
      .addDefaultCase((state, action) => { });
  }
});
export const { clearClinicData } = clinics.actions;
export default clinics.reducer;
