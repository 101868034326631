import { USER_SERVICE_URL } from '../apis';

const PERMISSIONS_APIS = {
  createGroup: () => `${USER_SERVICE_URL}groupPermissions`,
  getPermissionsEnum: () => `${USER_SERVICE_URL}policies/permissions`,
  getPolicies: () => `${USER_SERVICE_URL}policies`,
  getOrganizationPermissionGroups: (organizationId: string) =>
    `${USER_SERVICE_URL}groupPermissions/${organizationId}`,
  addPermissionToGroup: (groupId: string) =>
    `${USER_SERVICE_URL}groupPermissions/${groupId}/actions`,
  removePermissionFromGroup: (groupId: string) =>
    `${USER_SERVICE_URL}groupPermissions/${groupId}/actions/remove`,
  addUserToGroup: (groupId: string) => `${USER_SERVICE_URL}groupPermissions/${groupId}/users`,
  removeUserFromGroup: (groupId: string, userId: string, organizationId: string) =>
    `${USER_SERVICE_URL}groupPermissions/${groupId}/users/${userId}/organization/${organizationId}`,
  addGroupToPolicy: (policyId: string) => `${USER_SERVICE_URL}policies/${policyId}/group`,
  removePolicyFromGroup: (groupId: string, policyId: string) =>
    `${USER_SERVICE_URL}groupPermissions/${groupId}/policy/${policyId}/`,
  deleteGroup: (groupId: string) => `${USER_SERVICE_URL}groupPermissions/${groupId}`,
  createPolicy: () => `${USER_SERVICE_URL}policies`
};
export default PERMISSIONS_APIS;
