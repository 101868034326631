import axios from 'axios';
import { removeFromLocalStorage } from './shared';
import { INameAndId } from './general.interface';

const getHeaderInfo = () => {
  let token: string | null = null;
  let organization: string | INameAndId | null = null;

  try {
    token = localStorage.getItem('userToken');
    organization = localStorage.getItem('currentOrganization');
    if (token) token = JSON.parse(token);
    if (organization) organization = JSON.parse(organization);
  } catch (error) {
    console.error(error);
  }
  return { token, organization };
};
const config = {
  headers: {
    'content-type': 'application/json'
  }
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  (config) => {
    const { token, organization } = getHeaderInfo();
    // Modify the request config here (e.g., add headers)
    config.headers = {
      Authorization: `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest',
      'X-TENANT-ID': organization && typeof organization !== 'string' ? organization._id : null
    };
    return config;
  },
  (error) =>
    // Handle request error
    Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response)
      if (error.response.status === 401 && error.response.data.message === 'Invalid JWT token') {
        removeFromLocalStorage('userProfile');
        removeFromLocalStorage('userToken');
        window.location.href = '/login';
      }

    return Promise.reject(error);
  }
);
// axiosInstance.interceptors.response.use(
//   (response) => Promise.resolve(response),
//   (error) => {
//     if (error.response.status === 401) {
//       localStorage.removeItem("userToken");
//       localStorage.removeItem("userProfile");
//       alert(error.response.status);
//       window.location.replace("/login");
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
