import { createAsyncThunk } from '@reduxjs/toolkit';
import PATIENT_PACKAGE_APIS from 'redux/patientPackages/apis';
import INVOICES_APIS from 'redux/invoices/apis';
import PATIENT_APIS from 'redux/patients/apis';
import { IPackage } from 'redux/patientPackages/interfaces';
import axiosInstance from '../axios-instance';
import {
  EditPackageAction,
  IInvoice,
  IInvoiceServices,
  PaymentMethod,
  PaymentType
} from 'redux/invoices/interfaces';
import { IPatient } from 'redux/patients/interfaces';
import { IAppointment, IAppointmentMedicalRecord } from 'redux/appointments/interfaces';
import APPOINTMENTS_APIS from 'redux/appointments/apis';

export const getPackages = createAsyncThunk(
  'appointmentDetails/getPackages',
  async (
    requestData: {
      patientId: string;
      organizationId: string;
      clinicId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPackage[] | any> => {
    const { patientId, clinicId, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        PATIENT_PACKAGE_APIS.getPatientPackages(patientId, organizationId, clinicId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getAppointmentInvoice = createAsyncThunk(
  'appointmentDetails/getAppointmentInvoice',
  async (
    requestData: {
      organizationId: string;
      appointmentId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { organizationId, appointmentId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        INVOICES_APIS.getAppointmentInvoice(organizationId, appointmentId)
      );
      if (runFunction) runFunction();
      return response.data.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateInvoiceDiscountOrService = createAsyncThunk(
  'appointmentDetails/updateInvoiceDiscountOrService',
  async (
    requestData: {
      invoiceId: string;
      discount?: number;
      services?: IInvoiceServices[];
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { invoiceId, discount, services, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(INVOICES_APIS.updateInvoiceById(invoiceId), {
        discount,
        services
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cashFlow = createAsyncThunk(
  'appointmentDetails/cashFlow',
  async (
    requestData: {
      invoiceId: string;
      amount: number;
      type: PaymentType;
      method: PaymentMethod;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { invoiceId, amount, type, method, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(INVOICES_APIS.cashFlow(invoiceId), {
        amount,
        type,
        method
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addPackageToInvoice = createAsyncThunk(
  'appointmentDetails/addPackageToInvoice',
  async (
    requestData: {
      invoiceId: string;
      invoicePackage: {
        _id: string;
        servicesConsumed: { _id: string; qty: number; name: string }[];
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { invoiceId, invoicePackage, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        INVOICES_APIS.addPackageToInvoice(invoiceId),
        invoicePackage
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editInvoicePackage = createAsyncThunk(
  'appointmentDetails/editInvoicePackage',
  async (
    requestData: {
      invoiceId: string;
      packageId: string;
      action: EditPackageAction;
      servicesConsumed: { _id: string; qty: number; name: string }[];
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { invoiceId, packageId, action, servicesConsumed, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        INVOICES_APIS.editInvoicePackage(invoiceId, packageId, action),
        {
          servicesConsumed
        }
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removePackageFromInvoice = createAsyncThunk(
  'appointmentDetails/removePackageFromInvoice',
  async (
    requestData: {
      invoiceId: string;
      packageId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { invoiceId, packageId, runFunction } = requestData;

    try {
      const response = await axiosInstance.delete(
        INVOICES_APIS.removePackageFromInvoice(invoiceId, packageId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPatientById = createAsyncThunk(
  'appointmentDetails/getPatientById',
  async (
    requestData: {
      patientId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { patientId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(PATIENT_APIS.getPatientById(patientId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addMedicalRecord = createAsyncThunk(
  'appointmentDetails/addMedicalRecord',
  async (
    requestData: {
      appointmentId: string;
      medicalRecordDate: Partial<IAppointmentMedicalRecord>;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, medicalRecordDate, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        APPOINTMENTS_APIS.addMedicalRecord(appointmentId),
        medicalRecordDate
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateMedicalRecord = createAsyncThunk(
  'appointmentDetails/updateMedicalRecord',
  async (
    requestData: {
      appointmentId: string;
      medicalRecordId: string;
      medicalRecordDate: Partial<IAppointmentMedicalRecord>;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, medicalRecordDate, medicalRecordId, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        APPOINTMENTS_APIS.updateMedicalRecord(appointmentId, medicalRecordId),
        medicalRecordDate
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeMedicalRecord = createAsyncThunk(
  'appointmentDetails/removeMedicalRecord',
  async (
    requestData: {
      appointmentId: string;
      medicalRecordId: string;

      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, medicalRecordId, runFunction } = requestData;

    try {
      const response = await axiosInstance.delete(
        APPOINTMENTS_APIS.updateMedicalRecord(appointmentId, medicalRecordId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const rescheduleAppointment = createAsyncThunk(
  'appointmentDetails/rescheduleAppointment',
  async (
    requestData: {
      appointmentId: string;
      rescheduleDate: {
        shift: string;
        expectedStartTime: string;
        expectedEndTime?: string;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, rescheduleDate, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        APPOINTMENTS_APIS.reschedule(appointmentId),
        rescheduleDate
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelAppointment = createAsyncThunk(
  'appointmentDetails/cancelAppointment',
  async (
    requestData: {
      appointmentId: string;
      cancelReason: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const { appointmentId, cancelReason, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        APPOINTMENTS_APIS.cancelAppointment(appointmentId),
        { cancelReason }
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
