// hooks
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

// material-ui
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField, Typography, DialogContentText } from '@mui/material';
import { gridSpacing } from 'store/constant';

// formik
import * as yup from 'yup';
import { useFormik } from 'formik';

// actions
import { SNACKBAR_OPEN } from 'store/actions';
import { createRoom } from 'redux/rooms/actions';

const CreateRoomSchema = yup.object().shape({
  name: yup.string().required(),
  clinic: yup.string().required()
});

const CreateRoomClinic = ({ handleSteps }: { handleSteps: (action: 'next' | 'back') => void }) => {
  const dispatch = useAppDispatch();
  const { organization } = useAppSelector((state) => state.organization);
  const { rooms, loading, error } = useAppSelector((state) => state.rooms);

  const successFn = () => {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Room Created',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };

  const submitCreateRoom = (values: { name: string; clinic: string }) => {
    const clinicId = organization.clinics?.find((clinic) => clinic.name === values.clinic)?._id;
    if (clinicId) {
      dispatch(
        createRoom({
          name: values.name,
          clinic: clinicId,
          runFunction: successFn
        })
      );
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      clinic: ''
    },
    validationSchema: CreateRoomSchema,
    onSubmit: submitCreateRoom,
    enableReinitialize: true
  });

  useEffect(() => {
    if (error?.validationErrors) formik.setErrors(error?.validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.validationErrors]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DialogContentText>
          <Typography variant="body2" color="grey.900" component="h3">
            Create Rooms in the clinic which support rooms systems{' '}
          </Typography>{' '}
        </DialogContentText>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="name"
          id="name"
          label="Name *"
          size="small"
          fullWidth
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="clinic"
          id="clinic"
          label="Clinic *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.clinic}
          error={formik.touched.clinic && Boolean(formik.errors.clinic)}
          helperText={formik.touched.clinic && formik.errors.clinic}
        >
          <MenuItem value="" disabled>
            Select Clinic
          </MenuItem>
          {organization.clinics &&
            organization.clinics.map((clinic) =>
              clinic.supportRooms ? (
                <MenuItem key={clinic._id} value={clinic.name}>
                  {clinic.name}
                </MenuItem>
              ) : null
            )}
        </TextField>
      </Grid>
      <Grid item xs={12} md={2} sm={3} container justifyContent="flex-end">
        <Grid item>
          <LoadingButton variant="outlined" disabled={loading} onClick={formik.submitForm}>
            Add
          </LoadingButton>
        </Grid>
      </Grid>
      {rooms?.map((room, index) => (
        <Grid key={room._id} item xs={12}>
          <Typography>
            Room: {room.name} -{' '}
            {organization.clinics?.filter((clinic) => clinic._id === room.clinic)[0].name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default CreateRoomClinic;
