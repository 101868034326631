import { createSlice } from '@reduxjs/toolkit';

import {
  getClinicInvoicesMetrics,
  getCurrentPatientInvoice,
  getOrganizationInvoice
} from './actions';
import { IInvoiceList, IInvoicesServiceState } from './interfaces';

const initialInvoices: IInvoiceList = {
  page: 0,
  pages: 0,
  pageSize: 0,
  count: 0,
  data: []
};
const initialState: IInvoicesServiceState = {
  invoices: initialInvoices,
  loading: false,
  error: false,
  metricsLoading: false,
  metricsError: false
};

const invoices = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentPatientInvoice.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCurrentPatientInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPatientInvoice = action.payload.data[0];
      })
      .addCase(getCurrentPatientInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOrganizationInvoice.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOrganizationInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.invoices = action.payload;
      })
      .addCase(getOrganizationInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getClinicInvoicesMetrics.pending, (state) => {
        state.metricsLoading = true;
        state.metricsError = false;
      })
      .addCase(getClinicInvoicesMetrics.fulfilled, (state, action) => {
        state.metricsLoading = false;
        state.metrics = action.payload;
      })
      .addCase(getClinicInvoicesMetrics.rejected, (state, action) => {
        state.metricsLoading = false;
        state.metricsError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export default invoices.reducer;
