import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { styled } from '@mui/system';

const DatePickerContainer = styled('div')({
  position: 'relative',
  marginBottom: '8px',
  '&::after': {
    content: 'attr(data-helper-text)',
    display: 'block',
    position: 'absolute',
    bottom: '-20px',
    left: '2%',
    color: 'red',
    fontSize: '0.75rem',
  },
});

const DatePickerInput = (props: {
  value: Date | null;
  handleChange: (value: any, context: any) => void;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  label?: string;
  error?: boolean;
  helperText?: boolean | string;
  openTo?: 'day' | 'month' | 'year';
}) => {
  const {
    value,
    handleChange,
    disabled = false,
    maxDate,
    minDate,
    label = 'Date Of Birth',
    error = false,
    helperText = false,
    openTo = 'day'
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerContainer data-helper-text={helperText && error ? helperText : ''}>
        <DesktopDatePicker
          label={label}
          value={dayjs(value)}
          onChange={handleChange}
          openTo={openTo}
          disabled={disabled}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
          minDate={minDate ? dayjs(minDate) : undefined}
          sx={{
            '& .MuiInputLabel-root.Mui-error': {
              color: error ? 'red' : 'inherit',
            },
            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? 'red' : 'inherit',
            },
          }}
        />
      </DatePickerContainer>
    </LocalizationProvider>
  );
};

export default DatePickerInput;
