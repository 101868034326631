/* eslint-disable prefer-template */
import { PATIENT_SERVICE_URL } from '../apis';

const MEDICAL_TEMPLATE_APIS = {
  createMedicalTemplate: () => `${PATIENT_SERVICE_URL}medical-record-templates`,
  createProfileSections: () => `${PATIENT_SERVICE_URL}medical-record-templates/create-profile-sections`,
  getMRTById: (medicalTemplateId: string) =>
    `${PATIENT_SERVICE_URL}medical-record-templates/${medicalTemplateId}`,
  queryMRT: (organizationId: string, page?: number, pageSize?: number) =>
    `${PATIENT_SERVICE_URL}medical-record-templates/?organizationId=${organizationId}` +
    (page ? `&page=${page}` : '') +
    (pageSize ? `&pageSize=${pageSize}` : ''),
  updateInfo: (medicalTemplateId: string) =>
    `${PATIENT_SERVICE_URL}medical-record-templates/${medicalTemplateId}`,
  addHeader: (medicalTemplateId: string) =>
    `${PATIENT_SERVICE_URL}medical-record-templates/${medicalTemplateId}/header`,
  removeHeader: (medicalTemplateId: string, headerId: string) =>
    `${PATIENT_SERVICE_URL}medical-record-templates/${medicalTemplateId}/header/${headerId}`,
  removeMRT: (medicalTemplateId: string) =>
    `${PATIENT_SERVICE_URL}medical-record-templates/${medicalTemplateId}`
};
export default MEDICAL_TEMPLATE_APIS;
