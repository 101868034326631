// MUI Components
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText
} from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InfoIcon from '@mui/icons-material/Info';

import { gridSpacing } from 'store/constant';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { createPatient, searchPatientsWithPhone } from 'redux/createAppointment/actions';
import { clearState, setCreatePatient } from 'redux/createAppointment';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import DatePickerInput from '../../../../../components/select-date';
import { SNACKBAR_OPEN } from 'store/actions';
import { setRefresh } from 'redux/patients';

const SearchPatientSchema = yup.object().shape({
  phone: yup
    .string()
    .required()
    .matches(/^01(0|1|2|5)[0-9]{8}$/, 'phone number is not supported')
});
const PatientSection = () => {
  const dispatch = useAppDispatch();
  const { patient, loading, createPatient } = useAppSelector((state) => state.createAppointment);
  const { organization } = useAppSelector((state) => state.organization);
  const successFn = () => {
    // formik.resetForm();
  };
  const submitSearchWithPhone = (values: { phone: string }) => {
    dispatch(
      searchPatientsWithPhone({
        phone: values.phone,
        organizationId: organization._id,
        runFunction: successFn
      })
    );
  };
  const formik = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: SearchPatientSchema,
    onSubmit: submitSearchWithPhone,
    enableReinitialize: true
  });
  const resetSearch = () => {
    formik.resetForm();
    dispatch(clearState());
  };
  const handleToggleCreate = () => {
    dispatch(setCreatePatient(!createPatient));
  };
  return (
    <Grid container alignItems="center" spacing={gridSpacing}>
      <Grid item container xs={12}>
        <Typography variant="h4">
          <InfoIcon color="secondary" />
          <span style={{ verticalAlign: 'super' }}> Patient Information</span>
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={gridSpacing}>
        <Grid item xs={4}>
          <TextField
            id="phone"
            name="phone"
            label="phone"
            type="tel"
            size="small"
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            disabled={!!(loading || patient?._id || createPatient)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        {createPatient ? (
          !patient?._id &&
          createPatient && (
            <Grid item>
              <LoadingButton
                sx={{ mx: 1 }}
                loading={loading}
                variant="outlined"
                color="error"
                onClick={resetSearch}
              >
                cancel
              </LoadingButton>
            </Grid>
          )
        ) : (
          <Grid item>
            {!patient?._id ? (
              <LoadingButton loading={loading} variant="outlined" onClick={formik.submitForm}>
                search
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={loading}
                variant="outlined"
                color="error"
                onClick={resetSearch}
              >
                clear
              </LoadingButton>
            )}
          </Grid>
        )}

        {!patient && !createPatient && (
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={handleToggleCreate}>
              Create Patient
            </Button>
          </Grid>
        )}
        {!patient && !createPatient && (
          <Grid item xs={12} container>
            <Typography variant="subtitle1" color="error">
              No Patient Found
            </Typography>
          </Grid>
        )}
        {createPatient && (
          <Grid item xs={12}>
            <CreatePatient phone={formik.values.phone} />
          </Grid>
        )}

        <Grid item xs={12}>
          {patient?._id && <PatientProfile />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const PatientProfile = () => {
  const { patient } = useAppSelector((state) => state.createAppointment);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Name</Typography>
        <Typography>{patient?.name}</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Email</Typography>
        <Typography>{patient?.email}</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Date Of Birth</Typography>
        {patient?.dateOfBirth ? format(new Date(patient?.dateOfBirth), 'dd MMMM yyyy') : 'N/A'}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Gender</Typography>
        <Typography>{patient?.gender}</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Joined At</Typography>
        {patient?.createdAt ? format(new Date(patient?.createdAt), 'dd MMMM yyyy') : 'N/A'}
      </Grid>
    </Grid>
  );
};

const CreatePatientSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  dateOfBirth: yup.string().required(),
  phone: yup.string().required()
});

const CreatePatient = ({ phone }: { phone: string }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.createAppointment);
  const { refresh } = useAppSelector((state) => state.patients);
  const { organization } = useAppSelector((state) => state.organization);
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const handleChangeDate = (newValue: Date | null) => {
    setDateValue(newValue);
  };
  const [valueBasic, setValueBasic] = useState('female');
  const successFn = () => {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Patient Created',
      variant: 'alert',
      alertSeverity: 'success'
    });
    dispatch(setRefresh(!refresh));
  };
  const handleCreatePatient = (values: {
    name: string;
    email?: string;
    phone: string;
    dateOfBirth: string;
  }) => {
    dispatch(
      createPatient({
        ...values,
        organizationId: organization._id,
        gender: valueBasic,
        runFunction: successFn
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phone,
      dateOfBirth: ''
    },
    validationSchema: CreatePatientSchema,
    onSubmit: handleCreatePatient,
    enableReinitialize: true
  });

  useEffect(() => {
    if (dateValue && dateValue?.toString() !== 'Invalid Date')
      formik.values.dateOfBirth = dateValue?.toISOString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Name</Typography>
        <TextField
          id="name"
          name="name"
          label="Name"
          size="small"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched?.name && Boolean(formik.errors?.name)}
          helperText={formik.touched?.name && formik.errors?.name}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Email</Typography>
        <TextField
          id="email"
          name="email"
          label="Email"
          size="small"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched?.email && Boolean(formik.errors?.email)}
          helperText={formik.touched?.email && formik.errors?.email}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Date Of Birth</Typography>
        <FormControl
          fullWidth
          margin="dense"
          error={formik.touched?.dateOfBirth && Boolean(formik.errors?.dateOfBirth)}
        >
          <DatePickerInput value={dateValue} handleChange={handleChangeDate} maxDate={new Date()} />
          <FormHelperText>
            {formik.touched?.dateOfBirth && formik.errors?.dateOfBirth}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4">Gender</Typography>
        <FormControl fullWidth margin="normal">
          <RadioGroup
            row
            id="gender"
            name="row-radio-buttons-group"
            aria-label="gender"
            value={valueBasic}
            onChange={(e) => setValueBasic(e.target.value)}
          >
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="female" control={<Radio />} label="Female" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item>
        <LoadingButton
          sx={{ mx: 1 }}
          loading={loading}
          variant="outlined"
          onClick={formik.submitForm}
        >
          Create New Patient
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default PatientSection;
