import { createSlice } from '@reduxjs/toolkit';
import {
  acceptInvitation,
  checkUserToken,
  getInvitations,
  getSpecialities,
  getUserData,
  login,
  logOut,
  register,
  updateProfile,
  verifyInvitation
} from './actions';
import { User, UserState } from './interfaces';

let localUserProfile: User = {
  _id: '',
  name: '',
  email: '',
  phone: ''
};
let localUserToken: string = '';

const localUserProfileUnParsed: any = localStorage.getItem('userProfile');
const localUserTokenUnParsed: any = localStorage.getItem('userToken');
localUserProfile = JSON?.parse(localUserProfileUnParsed) || {};
localUserToken = JSON?.parse(localUserTokenUnParsed) || '';

const initialState: UserState = {
  data: localUserProfile,
  token: localUserToken,
  invitations: [],
  loading: false,
  error: false,
  loginError: false,
  registerError: false,
  specialities: []
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearSpecialities(state) {
      state.specialities = [];
    },
    setUser(state, action) {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.loginError = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.loginError = action.payload;
      })
      .addCase(logOut.pending, (state) => {
        state.loading = true;
        state.loginError = false;
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.loading = false;
        state.data = localUserProfile;
        state.token = '';
      })
      .addCase(logOut.rejected, (state, action) => {
        state.loading = false;
        state.loginError = action.payload;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.registerError = false;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.registerError = action.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSpecialities.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecialities.fulfilled, (state, action) => {
        state.loading = false;
        state.specialities = action.payload;
      })
      .addCase(getSpecialities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getInvitations.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInvitations.fulfilled, (state, action) => {
        state.loading = false;
        state.invitations = action.payload;
      })
      .addCase(getInvitations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(acceptInvitation.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.invitations = action.payload;
        // Find the organization that matches the action's organization (assuming some unique identifier like an ID)
        const organizationToUpdate = state.data.organizations?.find(
          (org) => org._id === action.payload.organization._id
        );

        if (organizationToUpdate)
          // Clone the organization and update its clinics array with the new clinic
          organizationToUpdate.clinics = [
            ...(organizationToUpdate.clinics || []), // Clone existing clinics or create a new array if it doesn't exist
            action.payload.organization.clinics // Add the new clinic
          ];

        state.data.organizations = [
          ...(state.data.organizations || []),
          organizationToUpdate || action.payload.organization
        ];
      })
      .addCase(acceptInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(checkUserToken.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(checkUserToken.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(checkUserToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.token = '';
      })
      .addCase(verifyInvitation.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(verifyInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.invitation = action.payload;
      })
      .addCase(verifyInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserData.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { clearSpecialities, setUser } = user.actions;

export default user.reducer;
