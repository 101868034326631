// import { Divider } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import UserProfileCard from 'ui-component/cards/UserProfileCard';

const MenuProfile = ({ item }: { item: any }) => (
  <>
    <SubCard sx={{ width: '80%', margin: 'auto' }} contentSX={{ p: 0, pb: '0 !important' }}>
      <UserProfileCard {...item} />
    </SubCard>
    {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
  </>
);

export default MenuProfile;
