const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

const saveDataToLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getValueFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  if (value) return JSON.parse(value)
  return null
}

export { removeFromLocalStorage, saveDataToLocalStorage, getValueFromLocalStorage };
