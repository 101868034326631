import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import PERMISSIONS_APIS from './apis';
import { ICreateGroupPermissions } from './interfaces';
import Permission from './permissionsEnum';

export const createGroupPermission = createAsyncThunk(
  'permissions/createGroupPermission',
  async (
    requestData: {
      createGroupData: ICreateGroupPermissions;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const { createGroupData, runFunction } = requestData;
      const response = await axiosInstance.post(PERMISSIONS_APIS.createGroup(), createGroupData);

      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGroupPermissions = createAsyncThunk(
  'permissions/getGroupPermissions',
  async (
    requestData: {
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { organizationId, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(
        PERMISSIONS_APIS.getOrganizationPermissionGroups(organizationId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActions = createAsyncThunk(
  'permissions/getActions',
  async (
    requestData: {
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { runFunction } = requestData;
    try {
      const response = await axiosInstance.get(PERMISSIONS_APIS.getPermissionsEnum());
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPermissionToGroup = createAsyncThunk(
  'permissions/addPermissionToGroup',
  async (
    requestData: {
      groupId: string;
      action: { name: Permission };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { groupId, action, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(
        PERMISSIONS_APIS.addPermissionToGroup(groupId),
        action
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserToGroup = createAsyncThunk(
  'permissions/addUserToGroup',
  async (
    requestData: {
      groupId: string;
      addUserDto: {
        _id: string;
        organizationId: string;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { groupId, addUserDto, runFunction } = requestData;
    try {
      const response = await axiosInstance.post(
        PERMISSIONS_APIS.addUserToGroup(groupId),
        addUserDto
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeUserFromGroup = createAsyncThunk(
  'permissions/removeUserFromGroup',
  async (
    requestData: {
      groupId: string;
      userId: string;
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { groupId, userId, organizationId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(
        PERMISSIONS_APIS.removeUserFromGroup(groupId, userId, organizationId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addGroupToPolicy = createAsyncThunk(
  'permissions/addGroupToPolicy',
  async (
    requestData: {
      policyId: string;
      groupId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { groupId, policyId, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(PERMISSIONS_APIS.addGroupToPolicy(policyId), {
        groupId
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removePolicyFromGroup = createAsyncThunk(
  'permissions/removePolicyFromGroup',
  async (
    requestData: {
      groupId: string;
      policyId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { groupId, policyId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(
        PERMISSIONS_APIS.removePolicyFromGroup(groupId, policyId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  'permissions/deleteGroup',
  async (
    requestData: {
      groupId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { groupId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(PERMISSIONS_APIS.deleteGroup(groupId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPolicies = createAsyncThunk(
  'permissions/getPolicies',
  async (
    requestData: {
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { runFunction } = requestData;
    try {
      const response = await axiosInstance.get(PERMISSIONS_APIS.getPermissionsEnum());
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPolicy = createAsyncThunk(
  'permissions/createPolicy',
  async (
    requestData: {
      name?: string[];
      actions: Permission[];
      groups?: string[];
      resources?: string[];
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { runFunction, ...data } = requestData;
    try {
      const response = await axiosInstance.post(PERMISSIONS_APIS.createPolicy(), data);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
