import { USER_SERVICE_URL } from '../apis';

const USERS_APIS = {
  getUser: (userId: string) => `${USER_SERVICE_URL}user/${userId}`,
  login: () => `${USER_SERVICE_URL}user/login`,
  register: () => `${USER_SERVICE_URL}user/register`,
  updateProfile: (userId: string) => `${USER_SERVICE_URL}user/${userId}/profile`,
  getSpecialities: () => `${USER_SERVICE_URL}specialities`,
  getInvitations: () => `${USER_SERVICE_URL}verification/invitations`,
  acceptInvitation: (token: string) => `${USER_SERVICE_URL}verification/invitation?token=${token}`,
  checkUserToken: () => `${USER_SERVICE_URL}user/refreshToken`
};
export default USERS_APIS;
