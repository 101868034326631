import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'redux/axios-instance';
import { IPackage } from 'redux/patientPackages/interfaces';
import PATIENT_APIS from 'redux/patients/apis';
import INVOICES_APIS from 'redux/invoices/apis';
import { IPatient } from 'redux/patients/interfaces';
import {
  IInvoice,
  IInvoiceList,
  IInvoiceType,
  PaymentMethod,
  PaymentType
} from 'redux/invoices/interfaces';

export const getInvoicePackage = createAsyncThunk(
  'packageDetails/getInvoicePackage',
  async (
    requestData: {
      organizationId: string;
      packageId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPackage | any> => {
    const { organizationId, packageId, runFunction } = requestData;
    const query = {
      organizationId,
      packageId,
      type: IInvoiceType.package
    };
    try {
      const response = await axiosInstance.get(
        INVOICES_APIS.queryOrganizationInvoice(organizationId, query)
      );
      if (runFunction) runFunction();
      return response.data.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPatientById = createAsyncThunk(
  'packageDetails/getPatientById',
  async (
    requestData: {
      patientId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { patientId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(PATIENT_APIS.getPatientById(patientId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPackageInvoiceInAppointments = createAsyncThunk(
  'packageDetails/getPackageInvoiceInAppointments',
  async (
    requestData: {
      organizationId: string;
      packageId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoiceList | any> => {
    const { organizationId, packageId, runFunction } = requestData;
    const query = { packageId, type: IInvoiceType.appointment };
    try {
      const response = await axiosInstance.get(
        INVOICES_APIS.queryOrganizationInvoice(organizationId, query)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cashFlow = createAsyncThunk(
  'packageDetails/cashFlow',
  async (
    requestData: {
      invoiceId: string;
      amount: number;
      type: PaymentType;
      method: PaymentMethod;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { invoiceId, amount, type, method, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(INVOICES_APIS.cashFlow(invoiceId), {
        amount,
        type,
        method
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
