import { INameAndId } from 'redux/general.interface';
import { IPackage } from 'redux/patientPackages/interfaces';

export interface IInvoice {
  id: number;
  _id: string;
  organization: INameAndId;
  clinic: INameAndId;
  type: IInvoiceType;
  status: feeStatus;
  patient: INameAndId;
  appointment?: string;
  services?: IInvoiceServices[];
  package?: IPackageInvoice;
  discount: number;
  total: number;
  paidTotal: number;
  cashFlow: ICashFlow[];
  createdAt: string;
  updatedAt: string;
}
export enum IInvoiceType {
  appointment = 'Appointment',
  package = 'Package'
}

export enum EditPackageAction {
  consume = 'consume',
  increase = 'increase'
}
export interface IInvoiceList {
  pages: number;
  page: number;
  pageSize: number;
  count: number;
  data: IInvoice[];
}
export interface IInvoicePackageService {
  _id: string;
  name: string;
  qty: number;
}
export interface IPackageInvoice {
  _id: IPackage;
  servicesConsumed?: IInvoicePackageService[];
}

export interface ICashFlow {
  amount: number;
  type: PaymentType;
  method: PaymentMethod;
  date: string;
}
export interface IInvoiceServices {
  _id: string;
  fee: number;
  name: string;
  qty: number;
}

export interface IClinicInvoicesMetrics {
  revenue: number;
  collected: number;
  appointments: number;
  packages: number;
  appointmentsRevenue: number;
  packagesRevenue: number;
}

export interface IInvoicesServiceState {
  invoices: IInvoiceList;
  currentPatientInvoice?: IInvoice;
  loading: boolean;
  error: any;
  metrics?: IClinicInvoicesMetrics;
  metricsLoading: boolean;
  metricsError: any;
}

export enum feeStatus {
  pending = 'Pending',
  billed = 'Billed'
}

export enum PaymentType {
  paid = 'Paid',
  refound = 'Refound'
}

export enum PaymentMethod {
  creditCard = 'Credit Card',
  cash = 'Cash'
}
