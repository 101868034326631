import { createSlice } from '@reduxjs/toolkit';
import { IPackageDetails } from './interfaces';
import {
  getInvoicePackage,
  getPatientById,
  getPackageInvoiceInAppointments,
  cashFlow
} from './actions';

const initialState: IPackageDetails = {
  packageInvoiceLoading: false,
  packageInvoiceError: false,
  patientLoading: false,
  patientError: false,
  invoicesLoading: false,
  invoicesError: false
};

const packageDetails = createSlice({
  name: 'packageDetails',
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoicePackage.pending, (state) => {
        state.packageInvoiceLoading = true;
        state.packageInvoiceError = false;
        state.packageInvoice = undefined;
      })
      .addCase(getInvoicePackage.fulfilled, (state, action) => {
        state.packageInvoiceLoading = false;
        state.packageInvoice = action.payload;
      })
      .addCase(getInvoicePackage.rejected, (state, action) => {
        state.packageInvoiceLoading = false;
        state.packageInvoiceError = action.payload;
      })
      .addCase(getPatientById.pending, (state) => {
        state.patientLoading = true;
        state.patientError = false;
        state.patient = undefined;
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        state.patientLoading = false;
        state.patient = action.payload;
      })
      .addCase(getPatientById.rejected, (state, action) => {
        state.patientLoading = false;
        state.patientError = action.payload;
      })
      .addCase(getPackageInvoiceInAppointments.pending, (state) => {
        state.invoicesLoading = true;
        state.invoicesError = false;
        state.invoices = undefined;
      })
      .addCase(getPackageInvoiceInAppointments.fulfilled, (state, action) => {
        state.invoicesLoading = false;
        state.invoices = action.payload;
      })
      .addCase(getPackageInvoiceInAppointments.rejected, (state, action) => {
        state.invoicesLoading = false;
        state.invoicesError = action.payload;
      })
      .addCase(cashFlow.pending, (state) => {
        state.packageInvoiceLoading = true;
        state.packageInvoiceError = false;
      })
      .addCase(cashFlow.fulfilled, (state, action) => {
        state.packageInvoiceLoading = false;
        state.packageInvoice = action.payload;
      })
      .addCase(cashFlow.rejected, (state, action) => {
        state.packageInvoiceLoading = false;
        state.packageInvoiceError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { resetState } = packageDetails.actions;
export default packageDetails.reducer;
