import {
  Grid,
  DialogContentText,
  Typography,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Divider
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// Formik
import * as yup from 'yup';
import { useFormik } from 'formik';
import { SNACKBAR_OPEN } from 'store/actions';
import { useEffect, useState } from 'react';
import { DayOfWeek, ScheduleType } from 'redux/schedules/interfaces';
import { createSchedule, getStaffSchedule, updateSchedule } from 'redux/schedules/actions';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { selectClinic } from 'redux/sideMenu';
import { format } from 'date-fns';
import { retrieveRooms } from 'redux/rooms/actions';

const CreateScheduleSchema = yup.object().shape({
  staffId: yup.string().required(),
  staffName: yup.string().required(),
  clinicId: yup.string().required(),
  clinicName: yup.string().required(),
  slotDuration: yup
    .number()
    .min(0, 'time can not be less than 0')
    .required('appointment duration in minutes'),
  scheduleType: yup.string().required(),
  start: yup.string().required(),
  end: yup.string().required()
  // saturday: yup.string(),
  // sunday: yup.string(),
  // monday: yup.string(),
  // tuesday: yup.string(),
  // wednesday: yup.string(),
  // thursday: yup.string(),
  // friday: yup.string()
});
const weekDays = [
  {
    label: 'SAT',
    value: 'saturday'
  },
  {
    label: 'SUN',
    value: 'sunday'
  },
  {
    label: 'MON',
    value: 'monday'
  },
  {
    label: 'TUS',
    value: 'tuesday'
  },
  {
    label: 'WEN',
    value: 'wednesday'
  },
  {
    label: 'THU',
    value: 'thursday'
  },
  {
    label: 'FRI',
    value: 'friday'
  }
];

/* eslint-disable arrow-body-style */
const CreateScheduleForm = ({
  handleSteps
}: {
  handleSteps: (action: 'next' | 'back') => void;
}) => {
  const dispatch = useAppDispatch();
  const { clinic } = useAppSelector((state) => state.clinics);
  const { rooms } = useAppSelector((state) => state.rooms);
  const { organization } = useAppSelector((state) => state.organization);
  const { error, loading, schedule } = useAppSelector((state) => state.schedules);
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);

  const successFnUpdateDuration = () => {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Appointment Duration Updated',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };
  const successFnCreateSchedule = () => {
    setStart(null);
    setEnd(null);
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Schedule Created',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };
  const successFnAddShift = () => {
    setStart(null);
    setEnd(null);
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Shift Added',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };

  const submitAddService = (values: {
    staffName: string;
    staffId: string;
    end: string;
    start: string;
    clinicId: string;
    clinicName: string;
    slotDuration: number;
    scheduleType: ScheduleType;
    saturday?: string;
    sunday?: string;
    monday?: string;
    tuesday?: string;
    wednesday?: string;
    thursday?: string;
    friday?: string;
  }) => {
    const day: any = {};
    if (values.saturday && values.saturday?.length)
      day.saturday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };
    if (values.sunday && values.sunday?.length)
      day.sunday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };
    if (values.monday && values.monday?.length)
      day.monday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };
    if (values.tuesday && values.tuesday?.length)
      day.tuesday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };
    if (values.wednesday && values.wednesday?.length)
      day.wednesday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };
    if (values.thursday && values.thursday?.length)
      day.thursday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };
    if (values.friday && values.friday?.length)
      day.friday = {
        start: new Date(values.start).toISOString(),
        end: new Date(values.end).toISOString()
      };

    if (!schedule) {
      if (values.saturday && values.saturday?.length)
        day.saturday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      if (values.sunday && values.sunday?.length)
        day.sunday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      if (values.monday && values.monday?.length)
        day.monday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      if (values.tuesday && values.tuesday?.length)
        day.tuesday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      if (values.wednesday && values.wednesday?.length)
        day.wednesday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      if (values.thursday && values.thursday?.length)
        day.thursday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      if (values.friday && values.friday?.length)
        day.friday = [
          { start: new Date(values.start).toISOString(), end: new Date(values.end).toISOString() }
        ];
      dispatch(
        createSchedule({
          createBody: {
            staff: { _id: values.staffId, name: values.staffName },
            clinic: { _id: values.clinicId, name: values.clinicName },
            slotDuration: values.slotDuration,
            scheduleType: clinic.supportQueue ? ScheduleType.queue : ScheduleType.slot,
            ...day
          },
          runFunction: successFnCreateSchedule
        })
      );
    } else {
      if (values.slotDuration !== schedule.slotDuration)
        dispatch(
          updateSchedule({
            scheduleId: schedule._id,
            body: { ...day },
            runFunction: successFnUpdateDuration
          })
        );
      dispatch(
        updateSchedule({
          scheduleId: schedule._id,
          body: { ...day },
          runFunction: successFnAddShift
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      clinicId: clinic?._id || '',
      clinicName: clinic?.name || '',
      staffId: '',
      staffName: '',
      slotDuration: 15,
      scheduleType: ScheduleType.slot,
      start: '',
      end: ''
    },
    validationSchema: CreateScheduleSchema,
    onSubmit: submitAddService,
    enableReinitialize: true
  });

  useEffect(() => {
    if (error?.message)
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: error.message,
        variant: 'alert',
        alertSeverity: 'error'
      });
  }, [dispatch, error]);

  const handleChangeStartDate = (newDate: Date | null) => {
    if (newDate && newDate?.toString() !== 'Invalid Date') {
      // newDate.setSeconds(0);
      // newDate.setMilliseconds(0);
      formik.setFieldValue('start', newDate?.toISOString());
      setStart(newDate);
    } else {
      formik.setErrors({ start: 'start is required' });
    }
  };
  const handleChangeEndDate = (newDate: Date | null) => {
    if (newDate && newDate?.toString() !== 'Invalid Date') {
      // newDate.setSeconds(0);
      // newDate.setMilliseconds(0);
      formik.setFieldValue('end', newDate?.toISOString());
      setEnd(newDate);
    } else {
      formik.setErrors({ end: 'end is required' });
    }
  };

  useEffect(() => {
    if (formik.values.clinicId) {
      const selectedClinic = organization.clinics?.find(
        (cln) => cln._id === formik.values.clinicId
      );
      if (selectedClinic) dispatch(selectClinic(selectedClinic));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.clinicId]);

  useEffect(() => {
    if (clinic) {
      dispatch(retrieveRooms({ clinic: clinic._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  useEffect(() => {
    if (formik.values.staffId) {
      if (clinic?.staff) {
        const staff = clinic?.staff.find((stf) => stf._id === formik.values.staffId);
        if (staff) formik.setFieldValue('staffName', staff.name);
      }
      if (clinic?.supportRooms) {
        const room = rooms?.find((room) => room._id === formik.values.staffId);
        if (room) formik.setFieldValue('staffName', room.name);
      }
      dispatch(
        getStaffSchedule({ clinicId: formik.values.clinicId, staffId: formik.values.staffId })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formik.values.clinicId, formik.values.staffId]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DialogContentText>
          <Typography variant="body2" component="span">
            Control what services the clinic provides by adding them and latter add their fee
            corresponding to the staff providing the service.
          </Typography>
        </DialogContentText>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="clinicId"
          id="staff-assign-clinicId-onboarding"
          label="Clinic *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.clinicId}
          error={formik.touched.clinicId && Boolean(formik.errors.clinicId)}
          helperText={formik.touched.clinicId && formik.errors.clinicId}
        >
          <MenuItem value="" disabled>
            Select Clinic
          </MenuItem>
          {organization.clinics &&
            organization.clinics.map((clinic) => (
              <MenuItem key={clinic._id} value={clinic._id}>
                {clinic.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="staffId"
          name="staffId"
          size="small"
          label={clinic.supportRooms ? 'Staff/Room *' : 'Staff *'}
          fullWidth
          select
          onChange={formik.handleChange}
          value={formik.values.staffId}
          error={formik.touched.staffId && Boolean(formik.errors.staffId)}
          helperText={formik.touched.staffId && formik.errors.staffId}
        >
          {clinic.supportRooms ? (
            <MenuItem disabled value="">
              <em>----- Staff ----- </em>
            </MenuItem>
          ) : null}
          {clinic?.staff &&
            clinic?.staff.map((stf) => (
              <MenuItem key={stf._id} value={stf._id}>
                {stf.name}
              </MenuItem>
            ))}
          {clinic.supportRooms ? (
            <MenuItem disabled value="">
              <em>----- ROOMS ----- </em>
            </MenuItem>
          ) : null}
          {rooms &&
            rooms.map((room) => (
              <MenuItem key={room._id} value={room._id}>
                {room.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Appointment Duration *</Typography>
      </Grid>
      <Grid item>
        <TextField
          name="slotDuration"
          id="staff-Schedule-Duration-onboarding"
          label="Duration *"
          size="small"
          placeholder="Appointment duration in minutes"
          fullWidth
          type="number"
          onChange={formik.handleChange}
          value={formik.values.slotDuration}
          error={formik.touched.slotDuration && Boolean(formik.errors.slotDuration)}
          helperText={formik.touched.slotDuration && formik.errors.slotDuration}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Weekdays *</Typography>
        {formik.errors.scheduleType && (
          <FormHelperText error id="standard-code">
            {formik.errors.scheduleType}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {weekDays.map((day) => (
          <Grid item key={day.value}>
            <FormControlLabel
              control={<Checkbox name={day.value} onChange={formik.handleChange} size="small" />}
              label={day.label}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Working Hours *</Typography>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
            value={start}
            label="Start Time *"
            onChange={handleChangeStartDate}
            maxTime={end || undefined}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
            value={end}
            label="End Time *"
            onChange={handleChangeEndDate}
            minTime={start || undefined}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <LoadingButton loading={loading} variant="outlined" onClick={formik.submitForm}>
          Add Shift
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Current Schedule :</Typography>
      </Grid>
      {!schedule?.saturday &&
        !schedule?.sunday &&
        !schedule?.monday &&
        !schedule?.thursday &&
        !schedule?.wednesday &&
        !schedule?.tuesday &&
        !schedule?.friday && (
          <Grid item>
            <Typography variant="body2">Empty</Typography>
          </Grid>
        )}

      {schedule?.saturday && schedule.saturday.length > 0 && (
        <DayComponent value={schedule?.saturday} day="saturday" />
      )}
      {schedule?.sunday && schedule.sunday.length > 0 && (
        <DayComponent value={schedule?.sunday} day="sunday" />
      )}
      {schedule?.monday && schedule.monday.length > 0 && (
        <DayComponent value={schedule?.monday} day="monday" />
      )}
      {schedule?.thursday && schedule.thursday.length > 0 && (
        <DayComponent value={schedule?.thursday} day="thursday" />
      )}
      {schedule?.wednesday && schedule.wednesday.length > 0 && (
        <DayComponent value={schedule?.wednesday} day="wednesday" />
      )}
      {schedule?.tuesday && schedule.tuesday.length > 0 && (
        <DayComponent value={schedule?.tuesday} day="tuesday" />
      )}
      {schedule?.friday && schedule.friday.length > 0 && (
        <DayComponent value={schedule?.friday} day="friday" />
      )}
    </Grid>
  );
};

const DayComponent = ({ value, day }: { value: DayOfWeek[]; day: string }) => (
  <Grid item xs={12} container>
    <Grid item xs={2}>
      <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
        {day} :
      </Typography>
    </Grid>
    <Grid item xs={10} container>
      {value.map((shift, index) => (
        <Grid item xs={12} key={shift._id}>
          <Typography variant="body2">
            From {format(new Date(shift.start), 'h:mm a')} to{' '}
            {format(new Date(shift.end), 'h:mm a')}
          </Typography>
          {index + 1 !== value.length && (
            <Grid item xs={12} marginY={1}>
              <Divider />
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
    <Grid item xs={12} marginY={1}>
      <Divider />
    </Grid>
  </Grid>
);

export default CreateScheduleForm;
