import { useNavigate } from 'react-router-dom';

// project imports
// import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { token, data } = useAppSelector((state) => state.user);
  const { isSubscripted } = useAppSelector((state) => state.organization);
  const navigate = useNavigate();
  // const currentOrganization = localStorage.getItem('currentOrganization');

  useEffect(() => {
    if (!token) navigate('login', { replace: true });
    if (data.organizations && data.organizations?.length < 1 && token)
      navigate('create-organization');
    if (!isSubscripted) navigate('/pricing');
  }, [token, navigate, data.organizations, isSubscripted]);

  return children;
};

export default AuthGuard;
