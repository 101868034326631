// hooks
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Divider,
  TextField,
  Typography
} from '@mui/material';
// import { LoadingButton } from '@mui/lab';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import Chip from 'ui-component/extended/Chip';

// types
import { gridSpacing } from 'store/constant';
import { IPackageService } from 'redux/patientPackages/interfaces';
import { PaymentMethod } from 'redux/invoices/interfaces';

// Formik
import * as yup from 'yup';
import { useFormik } from 'formik';

// actions
import { SNACKBAR_OPEN } from 'store/actions';
import { buyPackage, getPackages } from 'redux/patientProfile/actions';
import PatientSection from '../CreateAppointment/PatientSection';
import { clearState } from 'redux/createAppointment';

// ===============================|| UI DIALOG - SCROLLABLE ||=============================== //

export type scrollType = 'body' | 'paper' | undefined;

const BuyPackageSchema = yup.object().shape({
  amount: yup.number().min(0, 'Can not collect less than 0'),
  method: yup.mixed<PaymentMethod>(),
  discount: yup.number().min(0, 'can not be less than 0%').max(100, 'can not be more 100%'),
  clinicPackage: yup.string().required('Package Is Required')
});

export default function BuyPackageDialog() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { clinic } = useAppSelector((state) => state.clinics);
  const { organization } = useAppSelector((state) => state.organization);
  const { patient } = useAppSelector((state) => state.createAppointment);
  const [pack, setPack] = useState<{
    name: string;
    fee: number;
    services: IPackageService[];
  }>({ name: '', fee: 0, services: [] });
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<scrollType>('paper');

  const handleClickOpen = (scrollType: scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    formik.resetForm();
    setPack({ name: '', fee: 0, services: [] });
    setOpen(false);
    dispatch(clearState());
  };

  const descriptionElementRef: React.Ref<HTMLSelectElement> = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement?.focus();
      }
    }
  }, [open]);

  const successFn = () => {
    handleClose();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Package Bought Successfully',
      variant: 'alert',
      alertSeverity: 'success'
    });
    if (patient?._id && organization?._id && clinic?._id)
      dispatch(
        getPackages({
          patientId: patient._id,
          organizationId: organization._id,
          clinicId: clinic._id
        })
      );
  };
  const submitBuyPackage = (values: {
    amount?: number;
    method?: PaymentMethod;
    discount?: number;
    clinicPackage: string;
  }) => {
    if (values.amount && !values.method)
      formik.setErrors({ amount: 'payment method can not exist without a paid amount' });
    else if (values.amount && !values.method)
      formik.setErrors({ amount: 'payment method can not exist without a paid amount' });
    else if (!values.amount && values.method)
      formik.setErrors({ amount: 'payment method can not exist without a paid amount' });
    else if (
      values?.discount &&
      values?.amount &&
      values?.amount - values?.discount * 100 > pack.fee
    )
      formik.setErrors({ amount: 'payment amount (with discount) can not exceed package fee' });
    else if (values?.amount && values?.amount > pack.fee)
      formik.setErrors({ amount: 'payment amount can not exceed package fee' });
    else if (!patient?._id)
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: 'No Patient Selected',
        variant: 'alert',
        alertSeverity: 'error'
      });
    else if (organization?._id && clinic?._id && patient?._id) {
      dispatch(
        buyPackage({
          clinic: {
            _id: clinic._id,
            name: clinic.name
          },
          organization: {
            _id: organization._id,
            name: organization.name
          },
          patient: {
            _id: patient._id,
            name: patient.name
          },
          clinicPackage: values.clinicPackage,
          name: pack.name,
          fee: pack.fee,
          services: pack.services,
          discount: values.discount,
          amount: values.amount,
          method: values.method,
          runFunction: successFn
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      clinicPackage: ''
    },
    validationSchema: BuyPackageSchema,
    onSubmit: submitBuyPackage,
    enableReinitialize: true
  });

  useEffect(() => {
    let targetPackage;
    if (clinic?.packages && formik.values.clinicPackage)
      targetPackage = clinic?.packages.find((p) => p._id === formik.values.clinicPackage);
    if (targetPackage) {
      const newServices: IPackageService[] = [];
      targetPackage.services.forEach((ser) => {
        newServices.push({
          _id: ser.service._id,
          name: ser.service.name,
          qty: ser.qty,
          qtyTotal: ser.qty
        });
      });
      setPack({ fee: targetPackage.fee, name: targetPackage.name, services: newServices });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.clinicPackage]);

  return (
    <>
      <Button onClick={handleClickOpen('paper')} size="small" variant="outlined" fullWidth>
        Buy Package
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title">Buy Package</DialogTitle>
        <DialogContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <PatientSection />
            </Grid>
            {/* select package to buy section */}
            <Grid item xs={12}>
              <SubCard title="Packages">
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <TextField
                      id="buy-package"
                      name="clinicPackage"
                      label="package - fee *"
                      size="small"
                      fullWidth
                      select
                      onChange={formik.handleChange}
                      value={formik.values.clinicPackage}
                      error={formik.touched.clinicPackage && Boolean(formik.errors.clinicPackage)}
                      helperText={formik.touched.clinicPackage && formik.errors.clinicPackage}
                    >
                      <MenuItem key="" value="" disabled>
                        Select Package
                      </MenuItem>
                      {clinic?.packages &&
                        clinic.packages.map((pack) => (
                          <MenuItem key={pack._id} value={pack._id}>
                            {pack.name} - {pack.fee}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} container spacing={gridSpacing}>
                    {pack?.services &&
                      pack?.services.map((ser, index) => (
                        <Grid item key={ser._id + index}>
                          <Chip label={`${ser.name} - ${ser.qty}`} chipcolor="secondary" />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </SubCard>
            </Grid>
            {/* payment section */}
            <Grid item xs={12}>
              <SubCard title="Payment">
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={9}>
                    <TextField
                      id="buy-package-method"
                      name="amount"
                      label="Amount"
                      size="small"
                      fullWidth
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.amount || ''}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="method"
                      name="method"
                      label="Method"
                      size="small"
                      fullWidth
                      select
                      onChange={formik.handleChange}
                      value={formik.values.method || ''}
                      error={formik.touched.method && Boolean(formik.errors.method)}
                      helperText={formik.touched.method && formik.errors.method}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value={PaymentMethod.cash}>Cash</MenuItem>
                      <MenuItem value={PaymentMethod.creditCard}>Credit Card</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item container justifyContent="flex-end">
                    <Grid item xs={6}>
                      <TextField
                        id="buy-package-discount"
                        name="discount"
                        label="Discount"
                        size="small"
                        fullWidth
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.discount || ''}
                        error={formik.touched.discount && Boolean(formik.errors.discount)}
                        helperText={formik.touched.discount && formik.errors.discount}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <SubCard
                      sx={{
                        bgcolor:
                          theme.palette.mode === 'dark'
                            ? theme.palette.dark.main
                            : theme.palette.primary.light
                      }}
                    >
                      <Grid container justifyContent="flex-end" spacing={gridSpacing}>
                        <Grid item sm={6} md={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <Typography align="right" variant="subtitle1">
                                    Fee :
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right" variant="body2">
                                    {pack.fee.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right" variant="subtitle1">
                                    Paid :
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right" variant="body2">
                                    {(formik.values?.amount || 0).toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right" variant="subtitle1">
                                    Discount ({formik.values.discount || 0}%) :
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right" variant="body2">
                                    {(((formik.values?.discount || 0) / 100) * pack.fee).toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider sx={{ bgcolor: 'dark.main' }} />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <Typography align="right" color="primary" variant="subtitle1">
                                    Remaining :
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right" color="primary" variant="subtitle1">
                                    {(
                                      pack.fee -
                                      ((formik.values.discount || 0) / 100) * pack.fee -
                                      (formik.values.amount || 0)
                                    ).toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </SubCard>
                  </Grid>
                </Grid>
              </SubCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
          <Button onClick={handleClose} color="error">
            cancel
          </Button>
          <Button onClick={formik.submitForm} variant="contained">
            confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
