import { createSlice } from '@reduxjs/toolkit';
import { IPatientState } from './interfaces';
import {
  createPatient,
  getSinglePatientWithPhone,
  getSinglePatientById,
  queryPatients,
  findDefaultTemplates
} from './actions';
import { Gender } from '../general.interface';

const patientsInitialState = {
  id: 0,
  _id: '',
  name: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  gender: Gender.MALE,
  createdAt: ''
};

const list = {
  data: [],
  searchFilters: {},
  count: 0,
  page: 0,
  pageSize: 0,
  pages: 0
};

const initialState: IPatientState = {
  patient: patientsInitialState,
  searchFilters: {},
  loading: false,
  error: false,
  list,
  patientListLoading: false,
  patientListError: false,
  refresh: false
};

const patients = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    clearPatient(state) {
      state.patient = patientsInitialState;
    },
    setPatient(state, action) {
      state.patient = action.payload;
    },
    clearPatientList(state) {
      state.list = list;
    },
    searchPatients(state, action) {
      state.searchFilters = action.payload;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryPatients.pending, (state) => {
        state.patientListLoading = true;
        state.patientListError = false;
      })
      .addCase(queryPatients.fulfilled, (state, action) => {
        state.patientListLoading = false;
        state.list = action.payload;
      })
      .addCase(queryPatients.rejected, (state, action) => {
        state.patientListLoading = false;
        state.patientListError = action.payload;
        state.list = list;
      })
      .addCase(getSinglePatientWithPhone.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.patient = patientsInitialState;
      })
      .addCase(getSinglePatientWithPhone.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
      })
      .addCase(getSinglePatientWithPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.patient = patientsInitialState;
      })
      .addCase(createPatient.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.patient = patientsInitialState;
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.patient = patientsInitialState;
      })
      .addCase(findDefaultTemplates.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.patient.defaultTemplates = [];
      })
      .addCase(findDefaultTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.patient.defaultTemplates = action.payload;
      })
      .addCase(findDefaultTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.patient.defaultTemplates = [];
      })
      .addCase(getSinglePatientById.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.patient = patientsInitialState;
      })
      .addCase(getSinglePatientById.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
      })
      .addCase(getSinglePatientById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.patient = patientsInitialState;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const {
  clearPatient,
  clearPatientList,
  setPatient,
  searchPatients,
  setRefresh
} = patients.actions;
export default patients.reducer;
