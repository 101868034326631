import { Grid } from '@mui/material';
import imgMain from 'assets/images/welcome-clinera.svg';

const CompletedScreen = () => (
  <Grid item xs={12} sx={{ position: 'relative' }}>
    <img
      alt="Auth method"
      src={imgMain}
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        width: 500,
        position: 'relative',
        zIndex: 5
      }}
    />
  </Grid>
);

export default CompletedScreen;
