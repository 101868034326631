import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { INameAndId } from '../general.interface';
import {
  removeFromLocalStorage,
  saveDataToLocalStorage,
  getValueFromLocalStorage
} from '../shared';
import APIS from './apis';
import { RegisterData, User } from './interfaces';

const saveUserProfileToLocalStorage = (user: any) => {
  saveDataToLocalStorage('userProfile', user);
};

const saveTokenToLocalStorage = (token: string) => {
  saveDataToLocalStorage('userToken', token);
};

const logOutLocalStorage = () => {
  removeFromLocalStorage('userProfile');
  removeFromLocalStorage('userToken');
};

const getUserProfileFromLocalStorage = () => getValueFromLocalStorage('userProfile');

export const login = createAsyncThunk(
  'user/login',
  async (
    loginData: {
      email: string;
      password: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<User | any> => {
    try {
      const { email, password, runFunction } = loginData;
      const response = await axiosInstance.post(APIS.login(), { email, password });
      const { user, token } = response.data;
      saveUserProfileToLocalStorage(user);
      saveTokenToLocalStorage(token);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logOut = createAsyncThunk(
  'user/logOut',
  async (logOutData: void, { rejectWithValue }): Promise<any> => {
    // try {
    logOutLocalStorage();
    //     return;
    // } catch (error: any) {
    //     return rejectWithValue({ message: 'could not log out' });
    // }
  }
);

export const register = createAsyncThunk(
  'user/register',
  async (
    registerData: { body: RegisterData; runFunction: () => void },
    { rejectWithValue }
  ): Promise<User | any> => {
    const { body, runFunction } = registerData;
    try {
      const response = await axiosInstance.post(APIS.register(), body);

      const { user, token } = response.data;

      saveUserProfileToLocalStorage(user);
      saveTokenToLocalStorage(token);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async (
    requestData: {
      _id: string;
      runFunction?: () => void;
      name?: string;
      email?: string;
      phone?: string;
      title?: string;
      password?: string;
      occupation?: string;
      speciality?: string;
      image?: string;
    },
    { rejectWithValue }
  ): Promise<User | any> => {
    const {
      _id,
      runFunction,
      name,
      email,
      phone,
      title,
      occupation,
      speciality,
      image
    } = requestData;
    const body = {
      name,
      email,
      phone,
      title,
      occupation,
      speciality,
      image
    };
    try {
      const response = await axiosInstance.patch(APIS.updateProfile(_id), body);
      saveUserProfileToLocalStorage(response.data);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSpecialities = createAsyncThunk(
  'user/getSpecialities',
  async (requestData: void, { rejectWithValue }): Promise<INameAndId[] | any> => {
    try {
      const response = await axiosInstance.get(APIS.getSpecialities());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInvitations = createAsyncThunk(
  'user/getInvitations',
  async (
    requestData: {
      email?: string;
    },
    { rejectWithValue }
  ): Promise<User | any> => {
    const { email } = requestData;
    const body = {
      email
    };
    try {
      const response = await axiosInstance.post(APIS.getInvitations(), body);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  'user/acceptInvitation',
  async (
    requestData: {
      token: string;
    },
    { rejectWithValue }
  ): Promise<User | any> => {
    const { token } = requestData;

    try {
      const userProfile = localStorage.getItem('userProfile');
      const response = await axiosInstance.post(APIS.acceptInvitation(token));
      if (userProfile) {
        const userProfileObj = JSON.parse(userProfile);
        const organizationToUpdate = userProfileObj?.organizations?.find(
          (org: any) => org._id === response.data.organization._id
        );

        if (organizationToUpdate) {
          // Update the clinics array by reference
          organizationToUpdate.clinics = [
            ...(organizationToUpdate.clinics || []), // Clone existing clinics or create a new array if it doesn't exist
            ...response.data.organization.clinics // Add the new clinic
          ];
        }
        userProfileObj.organizations = [
          ...(userProfileObj.organizations || []),
          organizationToUpdate || response.data.organization
        ];
        saveUserProfileToLocalStorage(userProfileObj);
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkUserToken = createAsyncThunk(
  'user/checkUserToken',
  async (requestData: { runFunction?: () => void }, { rejectWithValue }): Promise<User | any> => {
    const { runFunction } = requestData;
    try {
      let localToken = localStorage.getItem('userToken');
      if (localToken) localToken = JSON.parse(localToken);
      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
          'content-type': 'application/json'
        }
      };
      const response = await axiosInstance.get(APIS.checkUserToken(), config);
      const { user, token } = response.data;
      saveUserProfileToLocalStorage(user);
      saveTokenToLocalStorage(token);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyInvitation = createAsyncThunk(
  'user/verifyInvitation',
  async (
    requestData: {
      token: string;
    },
    { rejectWithValue }
  ): Promise<User | any> => {
    const { token } = requestData;

    try {
      const response = await axiosInstance.get(APIS.acceptInvitation(token));

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserData = createAsyncThunk('user/getUserData', async (_, { rejectWithValue }) => {
  try {
    const userProfile = getUserProfileFromLocalStorage();
    if (userProfile) {
      const response = await axiosInstance.get(APIS.getUser(userProfile._id));
      return response.data;
    }
    return await Promise.resolve({}); // Consistent return type
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
