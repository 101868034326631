import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
// import kanbanReducer from './kanbanReducer';
import appointments from 'redux/appointments';
import clinics from 'redux/clinics';
import generalServices from 'redux/generalServices';
import invoices from 'redux/invoices';
import organization from 'redux/organization';
import patientPackages from 'redux/patientPackages';
import patients from 'redux/patients';
import plans from 'redux/plans';
import schedules from 'redux/schedules';
import sideMenu from 'redux/sideMenu';
import staff from 'redux/staff';
import user from 'redux/user';
import createAppointment from 'redux/createAppointment';
import appointmentDetails from 'redux/appointmentDetails';
import patientProfile from 'redux/patientProfile';
import packageDetails from 'redux/packageDetails';
import groupPermissions from 'redux/permissions';
import medicalTemplate from 'redux/medicalTemplate';
import rooms from 'redux/rooms';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'berry-'
    },
    cartReducer
  ),
  // kanban: kanbanReducer,
  user,
  plans,
  organization,
  clinics,
  appointments,
  schedules,
  patients,
  staff,
  generalServices,
  invoices,
  patientPackages,
  sideMenu,
  createAppointment,
  appointmentDetails,
  patientProfile,
  packageDetails,
  groupPermissions,
  medicalTemplate,
  rooms
});

export default reducer;
