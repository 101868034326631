import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { IGeneralService } from './interfaces';
import APIS from './apis';

export const getGeneralServices = createAsyncThunk(
  'generalServices/getGeneralServices',
  async (
    requestData: {
      serviceName: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IGeneralService[] | any> => {
    const { serviceName, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getGeneralServices(serviceName));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const createGeneralServices = createAsyncThunk(
  'generalServices/createGeneralServices',
  async (
    requestData: {
      serviceName: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IGeneralService[] | any> => {
    const { serviceName, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(APIS.createGeneralServices(), {
        name: serviceName
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
