import { CLINIC_SERVICE_URL, USER_SERVICE_URL } from '../apis';

const STAFF_APIS = {
  getStaffOrganizationDetails: (staffId: string, organizationId: string) =>
    `${USER_SERVICE_URL}${staffId}/organization/${organizationId}`,
  addStaffToClinic: () => `${CLINIC_SERVICE_URL}clinics/staff`,
  removeStaffFromClinic: (clinicId: string, staffId: string) =>
    `${CLINIC_SERVICE_URL}${clinicId}/staff/${staffId}`
};
export default STAFF_APIS;
