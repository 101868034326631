export interface IError {
  status: number;
  message: string;
  error: string;
  validationErrors: {
    [x: string]: string;
  };
}

export interface INameAndId {
  _id: string;
  name: string;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female'
}

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inActive'
}