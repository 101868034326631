import { useNavigate } from 'react-router-dom';

// project imports
// import useAuth from 'hooks/useAuth';
import config from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
  const { token, data } = useAppSelector((state) => state.user);
  const { isSubscripted } = useAppSelector((state) => state.organization);
  const navigate = useNavigate();

  useEffect(() => {
    if (token && data.organizations && data.organizations?.length > 0 && isSubscripted) {
      navigate(config.defaultPath, { replace: true });
    }
    if (token && data.organizations && data.organizations?.length < 1)
      navigate('create-organization');
  }, [token, navigate, data.organizations, isSubscripted]);

  return children;
};

export default GuestGuard;
