import { PATIENT_SERVICE_URL } from '../apis';
import { Gender } from '../general.interface';

const PATIENT_APIS = {
  createPatient: () => `${PATIENT_SERVICE_URL}patients`,
  getPatientById: (patientId: string) => `${PATIENT_SERVICE_URL}patients/${patientId}`,
  updatePatient: (patientId: string) => `${PATIENT_SERVICE_URL}patients/${patientId}`,
  updatePatientTemplate: (patientId: string, templateId: string) =>
    `${PATIENT_SERVICE_URL}patients/${patientId}/template/${templateId}`,
  removePatient: (patientId: string) => `${PATIENT_SERVICE_URL}patients/${patientId}`,
  queryPatients: ({
    page,
    pageSize,
    organizationId,
    _id,
    phone,
    name,
    email,
    dateOfBirth,
    createdAt,
    gender
  }: {
    page: number;
    pageSize: number;
    organizationId: string;
    _id?: string;
    phone?: string;
    name?: string;
    email?: string;
    dateOfBirth?: Date | { from: Date; to: Date };
    createdAt?: { from: Date; to: Date };
    gender?: Gender;
  }) =>
    // eslint-disable-next-line prefer-template
    `${PATIENT_SERVICE_URL}patients/organization/${organizationId}?page=${page}&pageSize=${pageSize}` +
    (_id ? `&_id=${_id}` : '') +
    (email ? `&email=${email}` : '') +
    (phone ? `&phone=${phone}` : '') +
    (name ? `&name=${name}` : '') +
    (dateOfBirth ? `&dateOfBirth=${JSON.stringify(dateOfBirth)}` : '') +
    (createdAt ? `&createdAt=${JSON.stringify(createdAt)}` : '') +
    (gender ? `&gender=${gender}` : ''),
  findDefaultTemplates: (organizationId: string) => `${PATIENT_SERVICE_URL}patients/findDefaultTemplates/organization/${organizationId}`,
};
export default PATIENT_APIS;
