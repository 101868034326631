import { createSlice } from '@reduxjs/toolkit';
import { IStaffState } from './interfaces';
import { addStaffToClinic, getStaffOrganizationDetails, removeStaffFromClinic } from './actions';
import { Role } from '../organization/interfaces';
import { ClinicRole } from '../clinics/interfaces';

const StaffInitialState = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  occupation: ClinicRole.DOCTOR,
  organizations: {
    _id: '',
    name: '',
    role: Role.Staff,
    clinics: []
  }
};

const initialState: IStaffState = {
  staff: StaffInitialState,
  loading: false,
  error: false
};

const staff = createSlice({
  name: 'staff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStaffOrganizationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getStaffOrganizationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.staff = action.payload;
      })
      .addCase(getStaffOrganizationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addStaffToClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addStaffToClinic.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addStaffToClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeStaffFromClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeStaffFromClinic.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(removeStaffFromClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
// export const {  } = staff.actions;
export default staff.reducer;
