import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { DefaultRootStateProps } from 'types';

// assets
import { IconChevronRight } from '@tabler/icons';
// actions
import { SET_MENU } from 'store/actions';
import { retrieveClinic } from 'redux/clinics/actions';
import { getOrganizationsById } from 'redux/organization/actions';
import { selectOrganization, selectClinic, setClinicList } from 'redux/sideMenu';
import { getUserOrganizations, getUserClinics } from 'redux/sideMenu/actions';
import { setIsSubscriptedTrue, setIsSubscriptedFalse } from 'redux/organization';
import OnBoardingDialog from 'components/onboarding';

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
        marginRight: '10px'
      }
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px'
      }
    })
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  const { data: user } = useAppSelector((state) => state.user);
  const {
    organization: selectedOrganization,
    clinic: selectedClinic,
    organizations,
    clinics
  } = useAppSelector((state) => state.sideMenu);
  const { organization } = useAppSelector((state) => state.organization);
  // const { clinic } = useAppSelector((state) => state.clinics);

  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: DefaultRootStateProps) => state.customization.opened
  );
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    if (user._id) {
      dispatch(getUserOrganizations({ staffId: user._id }));
    }
  }, [dispatch, user._id]);

  useEffect(() => {
    if (organizations.length > 0) {
      let currentOrganization: any = localStorage.getItem('currentOrganization');
      if (currentOrganization) {
        try {
          currentOrganization = JSON.parse(currentOrganization);
          currentOrganization =
            organizations.find((org) => org._id === currentOrganization?._id) || organizations[0];
        } catch (err) {
          currentOrganization = organizations[0];
        }
      } else {
        currentOrganization = organizations[0];
      }
      localStorage.setItem('currentOrganization', JSON.stringify(currentOrganization));
      dispatch(selectOrganization(currentOrganization));
    }
  }, [dispatch, organizations]);

  useEffect(() => {
    if (selectedOrganization?._id) {
      dispatch(getOrganizationsById({ organizationId: selectedOrganization._id }));
    }
  }, [dispatch, selectedOrganization._id]);

  useEffect(() => {
    if (user?._id && organization?._id) {
      if (user._id === organization.owner._id) dispatch(setClinicList(organization.clinics));
      else dispatch(getUserClinics({ staffId: user._id, organizationId: organization?._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organization._id, user._id]);

  useEffect(() => {
    if (clinics.length > 0) {
      let currentClinic: any = localStorage.getItem('currentClinic');
      if (currentClinic) {
        try {
          currentClinic = JSON.parse(currentClinic);
          currentClinic = clinics.find((org) => org._id === currentClinic?._id) || clinics[0];
        } catch (err) {
          currentClinic = clinics[0];
        }
      } else {
        currentClinic = clinics[0];
      }
      localStorage.setItem('currentClinic', JSON.stringify(currentClinic));
      dispatch(selectClinic(currentClinic));
    }
  }, [dispatch, clinics]);

  useEffect(() => {
    if (selectedClinic?._id && selectedClinic?._id !== "" ) {
      dispatch(retrieveClinic({ clinicId: selectedClinic._id }));
    }
  }, [dispatch, selectedClinic._id]);

  useEffect(() => {
    if (!organization.currentPlan && organization._id) dispatch(setIsSubscriptedFalse());
    else dispatch(setIsSubscriptedTrue());
  }, [dispatch, organization]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
        {organization?.onBoarding && <OnBoardingDialog />}
        {/* <Customization /> */}
      </Main>
    </Box>
  );
};

export default MainLayout;
