import { useEffect, useState } from 'react';

// material-ui
import { Grid, MenuItem, TextField, DialogContentText, Typography } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import { IServices } from 'redux/clinics/interfaces';
// import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { gridSpacing } from 'store/constant';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { addServiceFee } from 'redux/clinics/actions';
import { LoadingButton } from '@mui/lab';

import { SNACKBAR_OPEN } from 'store/actions';
import { selectClinic } from 'redux/sideMenu';

const AddServiceFeeSchema = yup.object().shape({
  staffId: yup.string().required(),
  clinicId: yup.string().required(),
  serviceId: yup.string().required(),
  fee: yup.number().required().min(0)
});

const AddServiceFeeForm = ({ handleSteps }: { handleSteps: (action: 'next' | 'back') => void }) => {
  const dispatch = useAppDispatch();
  const { clinic, error, loading } = useAppSelector((state) => state.clinics);
  const { organization } = useAppSelector((state) => state.organization);
  const [selectedService, setSelectedService] = useState<IServices>();

  const successFn = () => {
    formik.resetForm();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Service Fee Added',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };
  const submitAddServiceFee = (values: {
    staffId: string;
    fee: number;
    serviceId: string;
    clinicId: string;
  }) => {
    dispatch(
      addServiceFee({
        clinicId: values.clinicId,
        serviceId: values.serviceId,
        serviceFee: { staffId: values.staffId, fees: values.fee },
        runFunction: successFn
      })
    );
  };
  const formik = useFormik({
    initialValues: {
      staffId: '',
      clinicId: clinic?._id || '',
      serviceId: selectedService?.serviceId._id || '',
      fee: 0
    },
    validationSchema: AddServiceFeeSchema,
    onSubmit: submitAddServiceFee,
    enableReinitialize: true
  });

  useEffect(() => {
    if (error?.validationErrors) formik.setErrors(error?.validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.validationErrors]);

  useEffect(() => {
    if (formik.values.clinicId) {
      const selectedClinic = organization.clinics?.find(
        (cln) => cln._id === formik.values.clinicId
      );
      if (selectedClinic) dispatch(selectClinic(selectedClinic));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.clinicId]);

  useEffect(() => {
    if (formik.values.serviceId) {
      const newSelectedService = clinic.services?.find(
        (ser) => ser.serviceId._id === formik.values.serviceId
      );
      if (newSelectedService) setSelectedService(newSelectedService);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.serviceId]);
  const findStaffName = (targetStaff: string) => {
    const staff = clinic.staff?.find((stf) => stf._id === targetStaff);
    return staff?.name || 'unknown';
  };

  return (
    <Grid item container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DialogContentText>
          <Typography variant="body2" component="span">
            Control what services the clinic provides by adding them and latter add their fee
            corresponding to the staff providing the service.
          </Typography>
        </DialogContentText>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="clinicId"
          id="staff-assign-clinicId-onboarding"
          label="Clinic *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.clinicId}
          error={formik.touched.clinicId && Boolean(formik.errors.clinicId)}
          helperText={formik.touched.clinicId && formik.errors.clinicId}
        >
          <MenuItem value="" disabled>
            Select Clinic
          </MenuItem>
          {organization.clinics &&
            organization.clinics.map((clinic) => (
              <MenuItem key={clinic._id} value={clinic._id}>
                {clinic.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="serviceId"
          id="service-fee-serviceId-onboarding"
          label="Service *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.serviceId}
          error={formik.touched.serviceId && Boolean(formik.errors.serviceId)}
          helperText={formik.touched.serviceId && formik.errors.serviceId}
        >
          <MenuItem value="" disabled>
            Select Service
          </MenuItem>
          {clinic?.services &&
            clinic?.services.map((service, index) => (
              <MenuItem key={service.serviceId._id} value={service.serviceId._id}>
                {service.serviceId.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item sm={6}>
        <TextField
          id="staffId"
          name="staffId"
          size="small"
          label="Staff *"
          fullWidth
          select
          onChange={formik.handleChange}
          value={formik.values.staffId}
          error={formik.touched.staffId && Boolean(formik.errors.staffId)}
          helperText={formik.touched.staffId && formik.errors.staffId}
        >
          {clinic?.staff &&
            clinic?.staff.map((stf) => (
              <MenuItem key={stf._id} value={stf._id}>
                {stf.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item sm={3}>
        <TextField
          id="fee"
          name="fee"
          label="Fee *"
          type="number"
          size="small"
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.fee}
          error={formik.touched.fee && Boolean(formik.errors.fee)}
          helperText={formik.touched.fee && formik.errors.fee}
        />
      </Grid>
      <Grid item sm={3} container justifyContent="flex-end">
        <Grid item>
          <LoadingButton loading={loading} variant="outlined" onClick={formik.submitForm}>
            Add
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {selectedService?.serviceFees &&
          selectedService?.serviceFees.map((service, index) => (
            <Grid item key={service._id + index}>
              <Typography sx={{ textTransform: 'capitalize' }}>
                {findStaffName(service.staffId)} ( {service.fees} )
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default AddServiceFeeForm;
