// material-ui
import { Grid, Autocomplete, TextField } from '@mui/material';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SyntheticEvent, useEffect } from 'react';

// actions
import { selectOrganization } from 'redux/sideMenu';

// types
import { INameAndId } from 'redux/general.interface';
import { setIsSubscriptedFalse, setIsSubscriptedTrue } from 'redux/organization';

const Organization = () => {
  const dispatch = useAppDispatch();

  const { organizations, organization: selectedOrganization } = useAppSelector(
    (state) => state.sideMenu
  );
  const { organization } = useAppSelector((state) => state.organization);

  const handleSelectOrg = (_event: SyntheticEvent, value: INameAndId) => {
    dispatch(selectOrganization(value));
  };

  useEffect(() => {
    if (organization.currentPlan) dispatch(setIsSubscriptedTrue());
    else dispatch(setIsSubscriptedFalse());
  }, [dispatch, organization]);

  return (
    <Grid item xs={12}>
      <Grid item>
        <Autocomplete
          getOptionLabel={(option) => option.name}
          disableClearable
          disablePortal
          isOptionEqualToValue={(option, value) => value._id === option._id || value._id === ''}
          value={selectedOrganization}
          onChange={(e, value) => handleSelectOrg(e, value)}
          options={organizations}
          renderInput={(params) => (
            <TextField {...params} label="Organization" value={selectedOrganization} />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Organization;
