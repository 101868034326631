import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { INameAndId } from '../general.interface';
import APIS from './apis';
import { IPackage, IPackageService } from './interfaces';

export const getPackages = createAsyncThunk(
  'patientPackages/getPackages',
  async (
    requestData: {
      patientId: string;
      organizationId: string;
      clinicId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPackage[] | any> => {
    const { patientId, clinicId, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        APIS.getPatientPackages(patientId, organizationId, clinicId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const createPackage = createAsyncThunk(
  'patientPackages/createPackage',
  async (
    requestData: {
      createBody: {
        patient: INameAndId;
        organization: INameAndId;
        clinicId: INameAndId;
        name: string;
        fee: number;
        services: IPackageService[];
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPackage[] | any> => {
    const { createBody, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(APIS.postPatientPackages(), createBody);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
