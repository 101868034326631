// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import Avatar from '../extended/Avatar';
import { gridSpacing } from 'store/constant';
import { UserProfile } from '_mockApis/user-profile/types';

// assets
const avatarImage = require.context('assets/images/profile', true);

export interface UserProfileCardProps extends UserProfile {
  profile?: string;
}

const capitalize = (word: string): string => word[0].toUpperCase() + word.substring(1);
// ==============================|| USER PROFILE CARD ||============================== //

const UserProfileCard = ({ avatar, name, profile, role, email }: UserProfileCardProps) => {
  const theme = useTheme();
  const avatarProfile = avatar && avatarImage(`./${avatar}`).default;
  const imageProfile = profile && avatarImage(`./${profile}`).default;

  return (
    <Card
      sx={{
        background:
          theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: theme.palette.mode === 'dark' ? 'none' : '1px solid',
        borderColor: theme.palette.grey[100],
        textAlign: 'center'
      }}
    >
      {imageProfile && (
        <CardMedia
          component="img"
          image={imageProfile}
          title="Slider5 image"
          sx={{ height: '125px' }}
        />
      )}
      <CardContent sx={{ p: 2, pb: '16px !important' }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Avatar
                  alt={name}
                  src={avatarProfile}
                  sx={{
                    width: 72,
                    height: 72,
                    m: imageProfile ? '-50px auto 0' : 'auto',
                    color: 'white',
                    backgroundColor: 'lightgray'
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems="center">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">{role ? `${capitalize(role)}, ${name}` : name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{email}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserProfileCard;
