import { SUBSCRIPTION_SERVICE_URL } from '../apis';

const PLANS_APIS = {
  createPlan: () => `${SUBSCRIPTION_SERVICE_URL}plans`,
  findAllPlan: (
    name?: string,
    clinicLimit?: number,
    staffLimit?: number,
    monthlyPrice?: number,
    annualPrice?: number,
    active?: boolean,
    page?: number,
    pageSize?: number
  ) =>
    // eslint-disable-next-line prefer-template
    `${SUBSCRIPTION_SERVICE_URL}plans?` + (active ? `&active=${active}` : ''),
  findOnePlan: (planId: any) => `${SUBSCRIPTION_SERVICE_URL}plans/${planId}`,
  updatePlan: (planId: any) => `${SUBSCRIPTION_SERVICE_URL}plans/${planId}`
};
export default PLANS_APIS;
