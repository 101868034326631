import { createSlice } from '@reduxjs/toolkit';

import { getGeneralServices, createGeneralServices } from './actions';
import { IGeneralServiceState } from './interfaces';

const initialState: IGeneralServiceState = {
  generalServices: [],
  createdService: { _id: '', name: '' },
  loading: false,
  error: false
};

const generalServices = createSlice({
  name: 'generalServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralServices.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getGeneralServices.fulfilled, (state, action) => {
        state.loading = false;
        state.generalServices = action.payload;
      })
      .addCase(getGeneralServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createGeneralServices.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createGeneralServices.fulfilled, (state, action) => {
        state.loading = false;
        state.createdService = action.payload;
      })
      .addCase(createGeneralServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export default generalServices.reducer;
