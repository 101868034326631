import { createSlice } from '@reduxjs/toolkit';
import { AppointmentState, IAppointmentState } from './interfaces';
import {
  createAppointment,
  getAvailableAppointments,
  getMinimalMetrics,
  queryAppointments,
  updateAppointment,
  updateAppointmentStaff
} from './actions';

const initialState: IAppointmentState = {
  appointment: {
    _id: '',
    patient: {
      _id: '',
      name: ''
    },
    schedule: {
      _id: '',
      staff: {
        _id: '',
        name: ''
      },
      clinic: {
        _id: '',
        name: ''
      }
    },
    expectedStartTime: '',
    expectedEndTime: '',
    currentState: AppointmentState.booked,
    status: [],
    medicalRecord: [],
    createdAt: '',
    updatedAt: ''
  },
  loading: false,
  error: false,
  appointmentListLoading: false,
  appointmentListError: false,
  availableAppointmentsList: [],
  getAvailableLoading: false,
  getAvailableError: false,
  minimalMetricsLoading: false,
  minimalMetricsError: false,
  refresh: false
};

const appointments = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    clearAvailableAppointmentsList(state) {
      state.availableAppointmentsList = [];
    },
    setRefresh(state) {
      state.refresh = !state.refresh;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryAppointments.pending, (state) => {
        state.appointmentListLoading = true;
        state.appointmentListError = false;
        state.appointmentList = undefined;
      })
      .addCase(queryAppointments.fulfilled, (state, action) => {
        state.appointmentListLoading = false;
        state.appointmentList = action.payload;
      })
      .addCase(queryAppointments.rejected, (state, action) => {
        state.appointmentListLoading = false;
        state.appointmentListError = action.payload;
      })
      .addCase(getAvailableAppointments.pending, (state) => {
        state.getAvailableLoading = true;
        state.getAvailableError = false;
        state.availableAppointmentsList = [];
      })
      .addCase(getAvailableAppointments.fulfilled, (state, action) => {
        state.getAvailableLoading = false;
        state.availableAppointmentsList = action.payload;
      })
      .addCase(getAvailableAppointments.rejected, (state, action) => {
        state.getAvailableLoading = false;
        state.getAvailableError = action.payload;
      })

      .addCase(createAppointment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointment = action.payload;
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAppointment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAppointmentStaff.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateAppointmentStaff.fulfilled, (state, action) => {
        state.loading = false;
        if (state.appointmentList) {
          state.appointmentList.data = state.appointmentList.data?.map((appointment) => {
            if (appointment._id === action.payload._id) {
              appointment = action.payload;
            }
            return appointment;
          });
        }
      })
      .addCase(updateAppointmentStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMinimalMetrics.pending, (state) => {
        state.minimalMetricsLoading = true;
        state.minimalMetricsError = false;
      })
      .addCase(getMinimalMetrics.fulfilled, (state, action) => {
        state.minimalMetricsLoading = false;
        state.minimalMetrics = action.payload;
      })
      .addCase(getMinimalMetrics.rejected, (state, action) => {
        state.minimalMetricsLoading = false;
        state.minimalMetricsError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { clearAvailableAppointmentsList, setRefresh } = appointments.actions;
export default appointments.reducer;
