import { INameAndId } from '../general.interface';

export interface IService {
  _id: string;
  name: string;
}

export interface IClinicPackage {
  _id: string;
  name: string;
  fee: number;
  services: {
    service: INameAndId;
    qty: number;
    _id: string;
  }[];
}
export interface IServicesFees {
  staffId: string;
  fees: number;
  _id: string;
}
export interface IServices {
  serviceId: IService;
  serviceFees?: IServicesFees[];
}
export enum ClinicRole {
  DOCTOR = 'doctor',
  NURSE = 'nurse',
  RECEPTIONIST = 'receptionist',
  ACCOUNTANT = 'accountant'
}
export interface IStaff {
  _id: string;
  name: string;
  role: ClinicRole;
}

export interface IClinic {
  _id: string;
  name: string;
  owner: string;
  organization: string;
  location?: string;
  phone?: number;
  supportQueue: boolean;
  supportRooms: boolean;
  active: boolean;
  services?: IServices[];
  staff?: IStaff[];
  packages?: IClinicPackage[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IClinicState {
  clinic: IClinic;
  package: IClinicPackage;
  clinicList?: IClinic[];
  loading: boolean;
  packageLoading: boolean;
  error: any;
  packageError: any;
}
