/* eslint-disable prefer-template */
import { FINANCE_SERVICE_URL } from '../apis';
import { EditPackageAction, feeStatus, IInvoiceType } from './interfaces';

const GENERAL_SERVICES_APIS = {
  getPatientInvoice: (patientId: string, organizationId: string) =>
    `${FINANCE_SERVICE_URL}invoice?organizationId=${organizationId}&patientId=${patientId}`,
  getAppointmentInvoice: (organizationId: string, appointmentId: string) =>
    `${FINANCE_SERVICE_URL}invoice?organizationId=${organizationId}&appointment=${appointmentId}`,
  updateInvoiceById: (invoiceId: string) => `${FINANCE_SERVICE_URL}invoice/${invoiceId}`,
  cashFlow: (invoiceId: string) => `${FINANCE_SERVICE_URL}invoice/${invoiceId}/cashflow`,
  addPackageToInvoice: (invoiceId: string) => `${FINANCE_SERVICE_URL}invoice/${invoiceId}/package`,
  buyPackage: () => `${FINANCE_SERVICE_URL}invoice/package`,
  editInvoicePackage: (invoiceId: string, packageId: string, action: EditPackageAction) =>
    `${FINANCE_SERVICE_URL}invoice/${invoiceId}/package/${packageId}/action/${action}`,
  removePackageFromInvoice: (invoiceId: string, packageId: string) =>
    `${FINANCE_SERVICE_URL}invoice/${invoiceId}/package/${packageId}`,
  getClinicMetrics: (organizationId: string, clinicId: string) =>
    `${FINANCE_SERVICE_URL}invoice/metrics/organization/${organizationId}/clinic/${clinicId}`,
  queryOrganizationInvoice: (
    organizationId: string,
    query?: {
      clinicId?: string;
      type?: IInvoiceType | '';
      clinicName?: string;
      patientName?: string;
      packageId?: string;
      appointment?: string;
      minDiscount?: number;
      maxDiscount?: number;
      minTotal?: number;
      maxTotal?: number;
      status?: feeStatus | '';
      minCreatedAt?: string;
      maxCreatedAt?: string;
      page?: number;
      pageSize?: number;
      sortType?: string;
      sortOrder?: 'asc' | 'desc';
    }
  ) =>
    `${FINANCE_SERVICE_URL}invoice?organizationId=${organizationId}` +
    (query?.clinicId ? `&clinicId=${query.clinicId}` : '') +
    (query?.type ? `&type=${query.type}` : '') +
    (query?.clinicName ? `&clinicName=${query.clinicName}` : '') +
    (query?.patientName ? `&patientName=${query.patientName}` : '') +
    (query?.packageId ? `&packageId=${query.packageId}` : '') +
    (query?.appointment ? `&appointment=${query.appointment}` : '') +
    (query?.minDiscount ? `&minDiscount=${query.minDiscount}` : '') +
    (query?.maxDiscount ? `&maxDiscount=${query.maxDiscount}` : '') +
    (query?.minTotal ? `&minTotal=${query.minTotal}` : '') +
    (query?.maxTotal ? `&maxTotal=${query.maxTotal}` : '') +
    (query?.status ? `&status=${query.status}` : '') +
    (query?.minCreatedAt ? `&minCreatedAt=${query.minCreatedAt}` : '') +
    (query?.maxCreatedAt ? `&maxCreatedAt=${query.maxCreatedAt}` : '') +
    (query?.page ? `&page=${query.page}` : '') +
    (query?.pageSize ? `&pageSize=${query.pageSize}` : '') +
    (query?.sortType ? `&sortType=${query.sortType}` : '') +
    (query?.sortOrder ? `&sortOrder=${query.sortOrder}` : '')
};
export default GENERAL_SERVICES_APIS;
