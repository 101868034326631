import { SUBSCRIPTION_SERVICE_URL, USER_SERVICE_URL } from '../apis';

const ORGANIZATIONS_APIS = {
  createOrganization: () => `${SUBSCRIPTION_SERVICE_URL}organizations`,
  getOrganizations: () => `${SUBSCRIPTION_SERVICE_URL}organizations`,
  getOwnerOrganization: () => `${SUBSCRIPTION_SERVICE_URL}organizations/owner`,
  getOrganizationsById: (organizationId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}`,
  updateOrganizationsById: (organizationId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}`,
  toggleAutoRenew: (organizationId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}`,
  subscribeOrganizationsToPlan: (organizationId: string, planId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}/plan/${planId}`,
  createClinic: (organizationId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}/clinics`,
  deleteClinic: (organizationId: string, clinicId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}/clinics/${clinicId}`,
  inviteStaff: (organizationId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}/staff/invite`,
  removeStaff: (organizationId: string, staffId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}/staff/${staffId}`,
  getOrganizationInvitations: (organizationId: string) =>
    `${USER_SERVICE_URL}verification/invitations/organization/${organizationId}`,
  deleteOrganizationInvitation: (invitationToken: string) =>
    `${USER_SERVICE_URL}verification/invitations/${invitationToken}/organization`,
  finishOnBoarding: (organizationId: string) =>
    `${SUBSCRIPTION_SERVICE_URL}organizations/${organizationId}/onBoarding/`
};
export default ORGANIZATIONS_APIS;
