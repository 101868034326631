import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import APIS from './apis';
import { IPlans } from './interfaces';

export const retrievePlans = createAsyncThunk(
  'plans/retrievePlans',
  async (
    requestData: {
      name?: string;
      clinicLimit?: number;
      staffLimit?: number;
      monthlyPrice?: number;
      annualPrice?: number;
      active?: boolean;
      page?: number;
      pageSize?: number;
    },
    { rejectWithValue }
  ): Promise<IPlans | any> => {
    const {
      name,
      clinicLimit,
      staffLimit,
      monthlyPrice,
      annualPrice,
      active,
      page,
      pageSize
    } = requestData;
    try {
      const response = await axiosInstance.get(
        APIS.findAllPlan(
          name,
          clinicLimit,
          staffLimit,
          monthlyPrice,
          annualPrice,
          active,
          page,
          pageSize
        )
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createPlan = createAsyncThunk(
  'plans/createPlan',
  async (
    requestData: {
      name: string;
      desc: string;
      clinicLimit: number;
      staffLimit: number;
      monthlyPrice: number;
      annualPrice: number;
      active: boolean;

      discount: number;
    },
    { rejectWithValue }
  ): Promise<IPlans | any> => {
    const {
      name,
      desc,
      clinicLimit,
      staffLimit,
      monthlyPrice,
      annualPrice,
      active,
      discount
    } = requestData;
    const body = {
      name,
      desc,
      clinicLimit,
      staffLimit,
      monthlyPrice,
      annualPrice,
      active,
      discount
    };
    try {
      const response = await axiosInstance.post(APIS.createPlan(), body);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const findPlan = createAsyncThunk(
  'plans/findPlan',
  async (
    requestData: {
      planId: string;
    },
    { rejectWithValue }
  ): Promise<IPlans | any> => {
    const { planId } = requestData;

    try {
      const response = await axiosInstance.get(APIS.findOnePlan(planId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePlan = createAsyncThunk(
  'plans/updatePlan',
  async (
    requestData: {
      planId: string;
      desc?: string;
      discount?: number;
      active?: boolean;
    },
    { rejectWithValue }
  ): Promise<IPlans | any> => {
    const { planId, desc, discount, active } = requestData;

    const body = {
      desc,
      discount,
      active
    };
    try {
      const response = await axiosInstance.post(APIS.updatePlan(planId), body);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
