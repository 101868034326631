import { createSlice } from '@reduxjs/toolkit';

import {
  addMedicalRecord,
  addPackageToInvoice,
  cancelAppointment,
  cashFlow,
  editInvoicePackage,
  getAppointmentInvoice,
  getPackages,
  getPatientById,
  removeMedicalRecord,
  removePackageFromInvoice,
  rescheduleAppointment,
  updateInvoiceDiscountOrService,
  updateMedicalRecord
} from './actions';
import { IAppointmentDetailsState } from './interfaces';

const initialState: IAppointmentDetailsState = {
  appointmentLoading: false,
  appointmentError: false,
  patientLoading: false,
  patientError: false,
  invoiceLoading: false,
  invoiceError: false,
  patientPackageLoading: false,
  patientPackageError: false,
  refresh: false
};

const appointmentDetails = createSlice({
  name: 'appointmentDetails',
  initialState,
  reducers: {
    setAppointment(state, action) {
      state.appointment = action.payload;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppointmentInvoice.pending, (state) => {
        state.invoiceLoading = true;
        state.invoiceError = false;
      })
      .addCase(getAppointmentInvoice.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoice = action.payload;
      })
      .addCase(getAppointmentInvoice.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = action.payload;
      })
      .addCase(getPackages.pending, (state) => {
        state.patientPackageLoading = true;
        state.patientPackageError = false;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.patientPackageLoading = false;
        state.patientPackages = action.payload;
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.patientPackageLoading = false;
        state.patientPackageError = action.payload;
      })
      .addCase(getPatientById.pending, (state) => {
        state.patientLoading = true;
        state.patientError = false;
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        state.patientLoading = false;
        state.patient = action.payload;
      })
      .addCase(getPatientById.rejected, (state, action) => {
        state.patientLoading = false;
        state.patientError = action.payload;
      })
      .addCase(updateInvoiceDiscountOrService.pending, (state) => {
        state.invoiceLoading = true;
        state.invoiceError = false;
      })
      .addCase(updateInvoiceDiscountOrService.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoice = action.payload;
      })
      .addCase(updateInvoiceDiscountOrService.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = action.payload;
      })
      .addCase(cashFlow.pending, (state) => {
        state.invoiceLoading = true;
        state.invoiceError = false;
      })
      .addCase(cashFlow.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoice = action.payload;
      })
      .addCase(cashFlow.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = action.payload;
      })
      .addCase(addPackageToInvoice.pending, (state) => {
        state.invoiceLoading = true;
        state.invoiceError = false;
      })
      .addCase(addPackageToInvoice.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoice = action.payload;
      })
      .addCase(addPackageToInvoice.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = action.payload;
      })
      .addCase(removePackageFromInvoice.pending, (state) => {
        state.invoiceLoading = true;
        state.invoiceError = false;
      })
      .addCase(removePackageFromInvoice.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoice = action.payload;
      })
      .addCase(removePackageFromInvoice.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = action.payload;
      })
      .addCase(editInvoicePackage.pending, (state) => {
        state.invoiceLoading = true;
        state.invoiceError = false;
      })
      .addCase(editInvoicePackage.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoice = action.payload;
      })
      .addCase(editInvoicePackage.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = action.payload;
      })
      .addCase(addMedicalRecord.pending, (state) => {
        state.appointmentLoading = true;
        state.appointmentError = false;
      })
      .addCase(addMedicalRecord.fulfilled, (state, action) => {
        state.appointmentLoading = false;
        state.appointment = action.payload;
      })
      .addCase(addMedicalRecord.rejected, (state, action) => {
        state.appointmentLoading = false;
        state.appointmentError = action.payload;
      })
      .addCase(updateMedicalRecord.pending, (state) => {
        state.appointmentLoading = true;
        state.appointmentError = false;
      })
      .addCase(updateMedicalRecord.fulfilled, (state, action) => {
        state.appointmentLoading = false;
        state.appointment = action.payload;
      })
      .addCase(updateMedicalRecord.rejected, (state, action) => {
        state.appointmentLoading = false;
        state.appointmentError = action.payload;
      })
      .addCase(removeMedicalRecord.pending, (state) => {
        state.appointmentLoading = true;
        state.appointmentError = false;
      })
      .addCase(removeMedicalRecord.fulfilled, (state, action) => {
        state.appointmentLoading = false;
        state.appointment = action.payload;
      })
      .addCase(removeMedicalRecord.rejected, (state, action) => {
        state.appointmentLoading = false;
        state.appointmentError = action.payload;
      })
      .addCase(rescheduleAppointment.pending, (state) => {
        state.appointmentLoading = true;
        state.appointmentError = false;
      })
      .addCase(rescheduleAppointment.fulfilled, (state, action) => {
        state.appointmentLoading = false;
        state.appointment = action.payload;
      })
      .addCase(rescheduleAppointment.rejected, (state, action) => {
        state.appointmentLoading = false;
        state.appointmentError = action.payload;
      })
      .addCase(cancelAppointment.pending, (state) => {
        state.appointmentLoading = true;
        state.appointmentError = false;
      })
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        state.appointmentLoading = false;
        state.appointment = action.payload;
      })
      .addCase(cancelAppointment.rejected, (state, action) => {
        state.appointmentLoading = false;
        state.appointmentError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { setAppointment, setRefresh } = appointmentDetails.actions;
export default appointmentDetails.reducer;
