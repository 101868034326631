import { createAsyncThunk } from '@reduxjs/toolkit';
import { INameAndId } from 'redux/general.interface';
import { DayOfWeek, ISchedule, ScheduleType } from 'redux/schedules/interfaces';
import axiosInstance from '../axios-instance';
import APIS from './apis';
import { IRoom } from './interfaces';

export const createSchedule = createAsyncThunk(
  'schedules/createSchedule',
  async (
    requestData: {
      createBody: {
        staff: INameAndId;
        clinic: INameAndId;
        slotDuration: number;
        room: INameAndId & {
          type?: string;
        };
        scheduleType: ScheduleType;
        saturday?: DayOfWeek[];
        sunday?: DayOfWeek[];
        monday?: DayOfWeek[];
        tuesday?: DayOfWeek[];
        wednesday?: DayOfWeek[];
        thursday?: DayOfWeek[];
        friday?: DayOfWeek[];
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { createBody, runFunction } = requestData;

    try {
      const response: any = await axiosInstance.post(APIS.createSchedule(), { ...createBody });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const retrieveRooms = createAsyncThunk(
  'rooms/retrieveRooms',
  async (
    requestData: {
      name?: string;
      clinic?: string;
    },
    { rejectWithValue }
  ): Promise<IRoom[] | any> => {
    const { name, clinic } = requestData;
    try {
      const response = await axiosInstance.post(APIS.findAllRooms(name, clinic));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createRoom = createAsyncThunk(
  'rooms/createRoom',
  async (
    requestData: {
      name: string;
      clinic: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IRoom | any> => {
    const { name, clinic, runFunction } = requestData;
    const body = {
      name,
      clinic
    };
    try {
      const response = await axiosInstance.post(APIS.createRoom(), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteRoom = createAsyncThunk(
  'rooms/deleteRoom',
  async (
    requestData: {
      roomId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { roomId, runFunction } = requestData;

    try {
      const response = await axiosInstance.delete(APIS.deleteRoom(roomId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateRoom = createAsyncThunk(
  'rooms/updateRoom',
  async (
    requestData: {
      roomId: string;
      name: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IRoom | any> => {
    const { roomId, name, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(APIS.updateRoom(roomId), { name });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCurrentRoomSchedule = createAsyncThunk(
  'rooms/getCurrentRoomSchedule',
  async (
    requestData: {
      clinicId: string;
      roomId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { clinicId, roomId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getCurrentRoomSchedule(clinicId, roomId));
      if (runFunction) runFunction();
      return response.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteShift = createAsyncThunk(
  'rooms/deleteShift',
  async (
    requestData: {
      scheduleId: string;
      day: string;
      shiftId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { scheduleId, day, shiftId, runFunction } = requestData;

    try {
      const response = await axiosInstance.delete(APIS.deleteShift(scheduleId, day, shiftId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  'rooms/updateSchedule',
  async (
    requestData: {
      scheduleId: string;
      body: {
        slotDuration?: number;
        saturday?: DayOfWeek;
        sunday?: DayOfWeek;
        monday?: DayOfWeek;
        tuesday?: DayOfWeek;
        wednesday?: DayOfWeek;
        thursday?: DayOfWeek;
        friday?: DayOfWeek;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { scheduleId, body, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(APIS.updateSchedule(scheduleId), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
