// material-ui
import { Grid, Autocomplete, TextField } from '@mui/material';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SyntheticEvent } from 'react';

// actions
import { selectClinic } from 'redux/sideMenu';

// types
import { INameAndId } from 'redux/general.interface';

// ==============================|| Select Clinic ||============================== //

const Clinic = () => {
  const dispatch = useAppDispatch();

  const { clinics, clinic } = useAppSelector((state) => state.sideMenu);

  const handleSelectOrg = (_event: SyntheticEvent, value: INameAndId) => {
    dispatch(selectClinic(value));
  };

  return (
    <Grid item xs={12}>
      <Grid item>
        <Autocomplete
          getOptionLabel={(option) => option.name}
          disableClearable
          disablePortal
          isOptionEqualToValue={(option, value) => value._id === option._id || value._id === ''}
          value={clinic}
          onChange={(e, value) => handleSelectOrg(e, value)}
          options={clinics}
          renderInput={(params) => <TextField {...params} label="Clinic" value={clinic} />}
        />
      </Grid>
    </Grid>
  );
};

export default Clinic;
