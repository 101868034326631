// Hooks
import { useEffect, useState, useRef, Ref } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

// material-ui
import { withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';

// Action
import { SNACKBAR_OPEN } from 'store/actions';

// Components
import CreateClinicForm from './CreateClinicForm';
import CreateRoomClinic from './CreateRoomClinic';
import InviteStaffForm from './InviteStaffForm';
import CreateScheduleForm from './CreateScheduleForm';
import AddServiceForm from './AddServiceForm';
import AddServiceFeeForm from './AddServiceFeeForm';
import CompletedScreen from './CompletedScreen';
import { finishOnBoarding } from 'redux/organization/actions';

// style constant
const BorderLinearProgress = withStyles((theme: Theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  bar: {
    borderRadius: 5
  }
}))(LinearProgress);

enum OnBoardingSteps {
  clinic = 'CREATE_CLINIC',
  room = 'CREATE_ROOM',
  invite = 'INVITE_STAFF',
  assign = 'ASSIGN_STAFF',
  service = 'ADD_SERVICE',
  serviceFee = 'ADD_SERVICE_FEE',
  schedule = 'CREATE_SCHEDULE',
  complete = 'COMPLETE'
}

const stepsTitles = {
  [OnBoardingSteps.clinic]: 'Welcome to clinera',
  [OnBoardingSteps.room]: 'Create Rooms',
  [OnBoardingSteps.invite]: 'Inviting Stuff',
  [OnBoardingSteps.assign]: '',
  [OnBoardingSteps.service]: 'Setting Clinic Services',
  [OnBoardingSteps.serviceFee]: "Setting Doctor's service",
  [OnBoardingSteps.schedule]: 'Setting Doctors Schedules',
  [OnBoardingSteps.complete]: ''
};

// ===============================|| UI DIALOG - SCROLLABLE ||=============================== //

export default function OnBoardingDialog() {
  const dispatch = useAppDispatch();
  const { organization, error } = useAppSelector((state) => state.organization);
  const { data: user } = useAppSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [step, setStep] = useState<OnBoardingSteps>(OnBoardingSteps.clinic);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSteps = (action: 'next' | 'back') => {
    switch (step) {
      case OnBoardingSteps.clinic:
        if (action === 'next') {
          organization.clinics?.find((clinic) => clinic.supportRooms)
            ? setStep(OnBoardingSteps.room)
            : setStep(OnBoardingSteps.invite);
          setProgress((1 / 5) * 100);
        }
        break;
      case OnBoardingSteps.room:
        if (action === 'next') {
          setStep(OnBoardingSteps.invite);
          setProgress((2 / 5) * 100);
        } else {
          setStep(OnBoardingSteps.clinic);
          setProgress(0);
        }
        break;
      case OnBoardingSteps.invite:
        if (action === 'next') {
          setStep(OnBoardingSteps.service);
          setProgress((2 / 5) * 100);
        } else {
          organization.clinics?.find((clinic) => clinic.supportRooms)
            ? setStep(OnBoardingSteps.room)
            : setStep(OnBoardingSteps.clinic);
          setProgress(0);
        }
        break;
      // case OnBoardingSteps.assign:
      //   if (action === 'next') {
      //     setStep(OnBoardingSteps.service);
      //     setProgress((3 / 6) * 100);
      //   } else {
      //     setStep(OnBoardingSteps.invite);
      //     setProgress((1 / 6) * 100);
      //   }
      //   break;
      case OnBoardingSteps.service:
        if (action === 'next') {
          setStep(OnBoardingSteps.serviceFee);
          setProgress((3 / 5) * 100);
        } else {
          setStep(OnBoardingSteps.invite);
          setProgress((1 / 5) * 100);
        }
        break;
      case OnBoardingSteps.serviceFee:
        if (action === 'next') {
          setStep(OnBoardingSteps.schedule);
          setProgress((4 / 5) * 100);
        } else {
          setStep(OnBoardingSteps.service);
          setProgress((2 / 5) * 100);
        }
        break;
      case OnBoardingSteps.schedule:
        if (action === 'next') {
          setStep(OnBoardingSteps.complete);
          setProgress((5 / 5) * 100);
        } else {
          setStep(OnBoardingSteps.serviceFee);
          setProgress((3 / 5) * 100);
        }
        break;
      default:
        break;
    }
  };

  const descriptionElementRef: Ref<HTMLSelectElement> = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement?.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (!organization?.onBoarding || user._id !== organization.owner._id) handleClose();
    else setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    if (error?.message)
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: error.message,
        variant: 'alert',
        alertSeverity: 'error'
      });
  }, [dispatch, error]);

  const handleFinishOnBoarding = () => {
    if (step === OnBoardingSteps.complete && organization?._id)
      dispatch(
        finishOnBoarding({
          organizationId: organization._id,
          runFunction: successFn
        })
      );
  };

  const successFn = () => {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Onboarding Completed',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {stepsTitles[step]}
        <Typography color="secondary" component="span" variant="h2">
          .
        </Typography>
      </DialogTitle>
      <DialogContent>
        {step === OnBoardingSteps.clinic && <CreateClinicForm handleSteps={handleSteps} />}
        {step === OnBoardingSteps.room && <CreateRoomClinic handleSteps={handleSteps} />}
        {step === OnBoardingSteps.invite && <InviteStaffForm handleSteps={handleSteps} />}
        {/* {step === OnBoardingSteps.assign && <AssignStaffForm handleSteps={handleSteps} />} */}
        {step === OnBoardingSteps.service && <AddServiceForm handleSteps={handleSteps} />}
        {step === OnBoardingSteps.serviceFee && <AddServiceFeeForm handleSteps={handleSteps} />}
        {step === OnBoardingSteps.schedule && <CreateScheduleForm handleSteps={handleSteps} />}
        {step === OnBoardingSteps.complete && <CompletedScreen />}
      </DialogContent>
      {step !== OnBoardingSteps.clinic && (
        <DialogActions sx={{ px: 2.5, pt: 2.5 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs>
                  <BorderLinearProgress variant="determinate" color="secondary" value={progress} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">{Math.round(progress)}%</Typography>
                </Grid>
              </Grid>
            </Grid>
            {step === OnBoardingSteps.complete ? (
              <Grid item>
                <Button
                  variant="contained"
                  disabled={organization?.clinics && organization.clinics.length === 0}
                  size="small"
                  onClick={handleFinishOnBoarding}
                >
                  Let&apos;s Begin
                </Button>
              </Grid>
            ) : (
              <Grid item container xs={6} spacing={1} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Button onClick={() => handleSteps('back')} color="error" size="small">
                    back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={organization?.clinics && organization.clinics.length === 0}
                    size="small"
                    onClick={() => handleSteps('next')}
                  >
                    next
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}
