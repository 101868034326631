// import { createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore
  // , FLUSH, PAUSE, PERSIST , PURGE, REGISTER, REHYDRATE
} from 'redux-persist';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

// const store = createStore(reducer);
const store = configureStore({
  reducer
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  //     }
  //   })
});
const persister = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export { store, persister };
