import { createSlice } from '@reduxjs/toolkit';
import { IOrganizationState } from './interfaces';
import {
  createClinic,
  createOrganization,
  deleteClinic,
  deleteSentInvitations,
  finishOnBoarding,
  getOrganizationsById,
  getOwnerOrganization,
  getSentInvitations,
  inviteStaff,
  removeStaff,
  subscribeToPlan,
  updateOrganizationsById
} from './actions';

const initialState: IOrganizationState = {
  organization: {
    _id: '',
    owner: { _id: '', name: '' },
    name: '',
    country: '',
    clinics: []
  },
  loading: false,
  error: false,
  isSubscripted: true
};

const organization = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearOrganizationError: (state: IOrganizationState) => {
      state.error = false;
    },
    setIsSubscriptedTrue: (state: IOrganizationState) => {
      state.isSubscripted = true;
    },
    setIsSubscriptedFalse: (state: IOrganizationState) => {
      state.isSubscripted = false;
    },

    addOrganizationClinic: (state: IOrganizationState, action) => {
      state.organization.clinics = state.organization.clinics ? [...state.organization.clinics, action.payload] : [action.payload]
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(subscribeToPlan.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(subscribeToPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(subscribeToPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOwnerOrganization.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOwnerOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(getOwnerOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOrganizationsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOrganizationsById.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(getOrganizationsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(createClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteClinic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(deleteClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(inviteStaff.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(inviteStaff.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(inviteStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOrganizationsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateOrganizationsById.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(updateOrganizationsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSentInvitations.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSentInvitations.fulfilled, (state, action) => {
        state.loading = false;
        state.invitations = action.payload;
      })
      .addCase(getSentInvitations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSentInvitations.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteSentInvitations.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteSentInvitations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeStaff.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeStaff.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(removeStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(finishOnBoarding.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(finishOnBoarding.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(finishOnBoarding.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => { });
  }
});
export const {
  clearOrganizationError,
  setIsSubscriptedTrue,
  setIsSubscriptedFalse,
  addOrganizationClinic
} = organization.actions;
export default organization.reducer;
