import { Grid, Typography, TextField, MenuItem, CircularProgress } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { setStaff } from 'redux/createAppointment';
import { getStaffSchedule } from 'redux/createAppointment/actions';
import { retrieveRooms } from 'redux/rooms/actions';
import { gridSpacing } from 'store/constant';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DateAndTimeSection from './TimeAndDateSection';

// eslint-disable-next-line arrow-body-style
const DateSection = () => {
  const dispatch = useAppDispatch();
  const { staff, schedule, loading } = useAppSelector((state) => state.createAppointment);
  const { clinic } = useAppSelector((state) => state.clinics);
  const { rooms } = useAppSelector((state) => state.rooms);
  const handelSetStaff = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;

    dispatch(setStaff(value));
  };

  useEffect(() => {
    dispatch(retrieveRooms({ clinic: clinic._id }));
    // eslint-disable-next-line
  }, [clinic]);

  useEffect(() => {
    if (staff) dispatch(getStaffSchedule({ clinicId: clinic?._id, staffId: staff }));
  }, [clinic._id, dispatch, staff]);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Typography variant="h3">{clinic.supportRooms ? 'Room' : 'Doctor'}</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id="staffId"
          name="staffId"
          size="small"
          fullWidth
          select
          onChange={handelSetStaff}
          value={staff}
        >
          {clinic.supportRooms &&
            rooms.map((stf) => (
              <MenuItem key={stf._id} value={stf._id}>
                {stf.name}
              </MenuItem>
            ))}
          {!clinic.supportRooms &&
            clinic?.staff &&
            clinic?.staff.map((stf) => (
              <MenuItem key={stf._id} value={stf._id}>
                {stf.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        {loading && <CircularProgress />}
        {!staff && <Typography>Select a Staff Member</Typography>}
        {staff && schedule && <DateAndTimeSection />}
        {staff && !schedule && !loading && (
          <Typography color="error">Staff Member Does Not Have a Schedule</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default DateSection;
