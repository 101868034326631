// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { gridSpacing } from 'store/constant';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getInvitations, acceptInvitation, checkUserToken } from 'redux/user/actions';
// import { useNavigate } from 'react-router-dom';

const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// notification status options
// const status = [
//   {
//     value: 'all',
//     label: 'All Notification'
//   },
//   {
//     value: 'new',
//     label: 'New'
//   },
//   {
//     value: 'unread',
//     label: 'Unread'
//   },
//   {
//     value: 'other',
//     label: 'Other'
//   }
// ];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const { data, invitations } = useAppSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    dispatch(getInvitations({ email: data.email }));
  }, [dispatch, data.email]);

  const handleAcceptInvitation = (token: string) => {
    dispatch(acceptInvitation({ token }));
    setOpen(false);
    dispatch(checkUserToken({}));
  };

  const handleDeclineInvitation = (token: string) => {
    // eslint-disable-next-line no-alert
    alert('To be implemented');
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <Badge color="secondary" variant="dot" invisible={invitations.length === 0}>
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.dark.main
                    : theme.palette.secondary.light,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.warning.dark
                    : theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.warning.dark
                      : theme.palette.secondary.dark,
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[800]
                      : theme.palette.secondary.light
                }
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="inherit"
            >
              <IconBell stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">All Notifications</Typography>

                            {invitations.length > 0 && (
                              <Chip
                                size="small"
                                label={invitations.length}
                                sx={{
                                  color: theme.palette.background.default,
                                  bgcolor: theme.palette.warning.dark
                                }}
                              />
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{
                          height: '100%',
                          maxHeight: 'calc(100vh - 205px)',
                          width: '100%'
                        }}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <ListItemWrapper>
                          <Grid container direction="column" className="list-container">
                            {invitations.length ? (
                              invitations.map((invitation) => (
                                <Grid key={invitation._id}>
                                  <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle2">
                                      {invitation.organizationName} invited you as {invitation.role}
                                      , Do you accept?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                      <Grid item>
                                        <Button
                                          color="secondary"
                                          variant="contained"
                                          disableElevation
                                          onClick={() =>
                                            handleAcceptInvitation(invitation.invToken)
                                          }
                                        >
                                          Accept
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          color="error"
                                          variant="contained"
                                          disableElevation
                                          onClick={() =>
                                            handleDeclineInvitation(invitation.invToken)
                                          }
                                        >
                                          Decline
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                            ) : (
                              <Grid item xs={12} sx={{ pb: 2 }}>
                                <Typography variant="subtitle2">
                                  You don&apos;t have any invitations yet
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </ListItemWrapper>
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  <Divider />
                  {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button size="small" disableElevation>
                      View All
                    </Button>
                  </CardActions> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
