// hooks
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// material-ui
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  MenuItem,
  TextField,
  Tooltip,
  IconButton
} from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';

// icons
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

// Formik
import * as yup from 'yup';
import { useFormik } from 'formik';

// types
import { gridSpacing } from 'store/constant';
import { LoadingButton } from '@mui/lab';
import { IServices } from 'redux/clinics/interfaces';
import { IInvoiceServices } from 'redux/invoices/interfaces';
import { SNACKBAR_OPEN } from 'store/actions';
import { changeServices } from 'redux/createAppointment';

const sxDivider = {
  borderColor: 'primary.light'
};

const ServicesSection = () => {
  const dispatch = useAppDispatch();
  const [addService, setAddService] = React.useState(false);
  const { services } = useAppSelector((state) => state.createAppointment);
  const toggleAddService = () => {
    setAddService(!addService);
  };
  const handleRemoveServiceFee = (serviceIndex: number) => {
    let newServices: IInvoiceServices[] = [];
    newServices = newServices.concat(services);
    newServices.splice(serviceIndex, 1);
    dispatch(changeServices(newServices));
  };
  return (
    <SubCard
      title="Services"
      secondary={
        <Button
          variant={addService ? 'contained' : 'outlined'}
          size="small"
          onClick={toggleAddService}
        >
          Add Service
        </Button>
      }
      content={false}
    >
      {addService && (
        <Grid margin={2}>
          <AddService toggleAddService={toggleAddService} />
        </Grid>
      )}
      {addService && (
        <Grid item xs={12}>
          <Divider sx={sxDivider} />
        </Grid>
      )}
      {services?.length > 0 && (
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ pl: 3 }}>Service Name</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Fee</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right" sx={{ pr: 3 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services.map((service, index) => (
                    <TableRow key={service._id + index}>
                      <TableCell sx={{ pl: 3 }}>
                        <Typography align="left" variant="subtitle1">
                          {service.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">{service.qty}</TableCell>
                      <TableCell align="right">{service.fee}</TableCell>
                      <TableCell align="right">{service.qty * service.fee}</TableCell>
                      <TableCell sx={{ pr: 3 }} align="right">
                        <Tooltip placement="top" title="Remove Service">
                          <IconButton
                            color="error"
                            aria-label="delete"
                            size="small"
                            onClick={() => handleRemoveServiceFee(index)}
                          >
                            <DeleteTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </SubCard>
  );
};

const AddServiceFeeSchema = yup.object().shape({
  _id: yup.string().required(),
  name: yup.string().required(),
  fee: yup.string().required().min(0, 'fee can not be negative'),
  qty: yup.number().required().min(1, 'add quantity')
});

const AddService = ({ toggleAddService }: { toggleAddService: () => void }) => {
  const dispatch = useAppDispatch();
  const { services } = useAppSelector((state) => state.createAppointment);
  const { clinic } = useAppSelector((state) => state.clinics);
  const [serviceGroup, setServiceGroup] = React.useState<IServices>();
  const [serviceGroupId, setServiceGroupId] = React.useState<string>('');

  const successFn = () => {
    formik.resetForm();
    setServiceGroupId('');
    setServiceGroup(undefined);
    toggleAddService();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Service added',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };

  const submitAddServiceFee = (values: { _id: string; name: string; fee: string; qty: number }) => {
    const fee = parseInt(values.fee, 10);
    let newServices: IInvoiceServices[] = [];
    if (services) newServices = newServices.concat(services);
    newServices.push({ ...values, fee });
    dispatch(changeServices(newServices));
    successFn();
  };

  const formik = useFormik({
    initialValues: {
      _id: '',
      name: '',
      fee: '',
      qty: 1
    },
    validationSchema: AddServiceFeeSchema,
    onSubmit: submitAddServiceFee,
    enableReinitialize: true
  });

  useEffect(() => {
    let serGro: IServices | undefined;
    if (serviceGroupId && clinic?.services) {
      serGro = clinic?.services.find((ser) => ser.serviceId._id === serviceGroupId);
    }
    if (serGro) {
      formik.setFieldValue('name', serGro.serviceId.name);
      formik.setFieldValue('_id', serGro.serviceId._id);
      setServiceGroup(serGro);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceGroupId]);

  const findStaffName = (targetStaff: string) => {
    let tStaff: any;
    if (clinic?.staff) tStaff = clinic?.staff?.find((stf) => stf._id === targetStaff);
    return tStaff?.name || 'Staff Not Found';
  };
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} md={4}>
        <TextField
          id="addServiceToInvoiceSelectId"
          name="name"
          label="Service *"
          size="small"
          type="search"
          fullWidth
          select
          onChange={(event) => setServiceGroupId(event.target.value)}
          value={serviceGroupId}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        >
          <MenuItem key="" value="" disabled>
            Select Service
          </MenuItem>
          {clinic?.services &&
            clinic?.services.map((ser) => (
              <MenuItem key={ser.serviceId._id} id={ser.serviceId._id} value={ser.serviceId._id}>
                {ser.serviceId.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          id="fee"
          name="fee"
          select
          // disabled={!!serviceGroup}
          onChange={formik.handleChange}
          value={formik.values.fee}
          error={formik.touched.fee && Boolean(formik.errors.fee)}
          helperText={formik.touched.fee && formik.errors.fee}
          label="Doctor & Fees *"
          fullWidth
          size="small"
        >
          <MenuItem key="" value="" disabled />
          {serviceGroup?.serviceFees &&
            serviceGroup?.serviceFees?.map((fee) => (
              <MenuItem key={fee._id} value={fee.fees}>
                {findStaffName(fee.staffId)} - {fee.fees}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={6} md={2}>
        <TextField
          id="add-service-qty"
          name="qty"
          label="Quantity *"
          fullWidth
          size="small"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.qty}
          error={formik.touched.qty && Boolean(formik.errors.qty)}
          helperText={formik.touched.qty && formik.errors.qty}
        />
      </Grid>

      <Grid item xs={6} md={2} container justifyContent="flex-end">
        <Grid item>
          <LoadingButton variant="outlined" onClick={formik.submitForm}>
            Add
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicesSection;
