import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IStaffData } from './interfaces';
import APIS from './apis';
import { ClinicRole } from '../clinics/interfaces';

export const getStaffOrganizationDetails = createAsyncThunk(
  'staff/getStaffOrganizationDetails',
  async (
    requestData: {
      organizationId: string;
      staffId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IStaffData | any> => {
    const { organizationId, staffId, runFunction } = requestData;
    try {
      const response = await axios.get(APIS.getStaffOrganizationDetails(staffId, organizationId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const addStaffToClinic = createAsyncThunk(
  'staff/addStaffToClinic',
  async (
    requestData: {
      clinicId: string;
      staff: {
        _id: string;
        name: string;
        role: ClinicRole;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { clinicId, staff, runFunction } = requestData;

    try {
      const response = await axios.post(APIS.addStaffToClinic(), { staff, clinicIds: [clinicId] });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const removeStaffFromClinic = createAsyncThunk(
  'staff/removeStaffFromClinic',
  async (
    requestData: {
      clinicId: string;
      staffId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { clinicId, staffId, runFunction } = requestData;

    try {
      const response = await axios.delete(APIS.removeStaffFromClinic(clinicId, staffId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
