import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'redux/axios-instance';
import MEDICAL_TEMPLATE_APIS from './apis';
import {
  IAddHeader,
  ICreateMRTAction,
  ICreateProfileSectionsAction,
  IMedicalTemplate,
  IProfileSections,
  IProfileSectionList
} from './interfaces';

export const queryMRT = createAsyncThunk(
  'MRT/queryMRT',
  async (
    requestData: {
      organizationId: string;
      page?: number;
      pageSize?: number;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IProfileSectionList | any> => {
    const { organizationId, page, pageSize, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        MEDICAL_TEMPLATE_APIS.queryMRT(organizationId, page, pageSize)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getMRTById = createAsyncThunk(
  'MRT/getMRTById',
  async (
    requestData: {
      medicalTemplateId: string;
      // runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IMedicalTemplate | any> => {
    const { medicalTemplateId, 
      // runFunction 
    } = requestData;
    try {
      const response = await axiosInstance.get(MEDICAL_TEMPLATE_APIS.getMRTById(medicalTemplateId));
      // if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createMedicalTemplate = createAsyncThunk(
  'MRT/createMedicalTemplate',
  async (
    requestData: {
      createBody: ICreateMRTAction;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IMedicalTemplate | any> => {
    const { createBody, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(
        MEDICAL_TEMPLATE_APIS.createMedicalTemplate(),
        createBody
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const createProfileSections = createAsyncThunk(
  'MRT/createProfileSections',
  async (
    requestData: {
      createBody: ICreateProfileSectionsAction;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IProfileSections | any> => {
    const { createBody, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(
        MEDICAL_TEMPLATE_APIS.createProfileSections(),
        createBody
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateMedicalTemplateInfo = createAsyncThunk(
  'MRT/updateMedicalTemplateInfo',
  async (
    requestData: {
      medicalTemplateId: string;
      updateData: ICreateProfileSectionsAction;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IMedicalTemplate | any> => {
    const { medicalTemplateId, updateData, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(
        MEDICAL_TEMPLATE_APIS.updateInfo(medicalTemplateId),
        updateData
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addHeader = createAsyncThunk(
  'MRT/addHeader',
  async (
    requestData: {
      medicalTemplateId: string;
      addHeaderBody: IAddHeader;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IMedicalTemplate | any> => {
    const { medicalTemplateId, addHeaderBody, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(
        MEDICAL_TEMPLATE_APIS.addHeader(medicalTemplateId),
        addHeaderBody
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeHeader = createAsyncThunk(
  'MRT/removeHeader',
  async (
    requestData: {
      medicalTemplateId: string;
      headerId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IMedicalTemplate | any> => {
    const { medicalTemplateId, headerId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(
        MEDICAL_TEMPLATE_APIS.removeHeader(medicalTemplateId, headerId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeMedicalTemplate = createAsyncThunk(
  'MRT/removeMedicalTemplate',
  async (
    requestData: {
      medicalTemplateId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IProfileSections | any> => {
    const { medicalTemplateId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(
        MEDICAL_TEMPLATE_APIS.removeMRT(medicalTemplateId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
