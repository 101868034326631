// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconBrandChrome,
  IconCalendar,
  IconUsers,
  IconReceipt2,
  // IconReport,
  IconAdjustmentsHorizontal,
  IconLogout
} from '@tabler/icons';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'side-menu',
  type: 'group',
  children: [
    {
      id: 'my-day',
      title: <FormattedMessage id="my-day" />,
      type: 'item',
      url: '/',
      icon: IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'calendar',
      title: <FormattedMessage id="calendar" />,
      type: 'item',
      url: '/calendar',
      icon: IconCalendar
    },
    {
      id: 'patients',
      title: <FormattedMessage id="patients" />,
      type: 'item',
      url: '/patients',
      icon: IconUsers
    },
    {
      id: 'billing',
      title: <FormattedMessage id="billing" />,
      type: 'item',
      url: '/billing',
      icon: IconReceipt2
    },
    // {
    //   id: 'reports',
    //   title: <FormattedMessage id="reports" />,
    //   type: 'item',
    //   url: '/reports',
    //   icon: IconReport
    // },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: IconAdjustmentsHorizontal,
      // roles: ['owner', 'manager']
    },
    {
      id: 'logout',
      title: <FormattedMessage id="logout" />,
      type: 'item',
      url: '/login',
      icon: IconLogout,
      logout: true
    }
  ]
};

export default other;
