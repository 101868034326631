import { createSlice } from '@reduxjs/toolkit';
import { IPatientPackageState } from './interfaces';
import { createPackage, getPackages } from './actions';

const packageInitialState = {
  _id: '',
  clinic: { _id: '', name: '' },
  organization: { _id: '', name: '' },
  patient: { _id: '', name: '' },
  clinicPackage: '',
  name: '',
  fee: 0,
  services: []
};
const packagesListInitialState = {
  data: [],
  page: 0,
  pages: 0,
  pageSize: 0,
  count: 0
};

const initialState: IPatientPackageState = {
  package: packageInitialState,
  packages: packagesListInitialState,
  loading: false,
  createLoading: false,
  error: false,
  createError: false
};

const patientPackages = createSlice({
  name: 'patientPackages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.packages = action.payload;
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPackage.pending, (state) => {
        state.createLoading = true;
        state.createError = false;
      })
      .addCase(createPackage.fulfilled, (state, action) => {
        state.createLoading = false;
        state.packages = action.payload;
      })
      .addCase(createPackage.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});

export default patientPackages.reducer;
