import { createAsyncThunk } from '@reduxjs/toolkit';
import STAFF_APIS from 'redux/staff/apis';
import axiosInstance from '../axios-instance';
import APIS from './apis';
import { ClinicRole, IClinic, IClinicPackage } from './interfaces';

export const retrieveClinic = createAsyncThunk(
  'clinics/retrieveClinic',
  async (
    requestData: { clinicId: string; runFunction?: () => void },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(APIS.getClinic(clinicId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateClinic = createAsyncThunk(
  'clinics/updateClinic',
  async (
    requestData: {
      clinicId: string;
      clinicInfo: { name?: string; phone?: number; location?: string; supportQueue?: boolean };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, clinicInfo, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(APIS.updateClinic(clinicId), clinicInfo);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addServiceFee = createAsyncThunk(
  'clinics/addServiceFee',
  async (
    requestData: {
      clinicId: string;
      serviceId: string;
      serviceFee: { staffId: string; fees: number };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, serviceId, serviceFee, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(
        APIS.createClinicServiceFees(clinicId, serviceId),
        serviceFee
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeServiceFee = createAsyncThunk(
  'clinics/removeServiceFee',
  async (
    requestData: {
      clinicId: string;
      serviceId: string;
      serviceFeeId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, serviceId, serviceFeeId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(
        APIS.removeClinicServiceFees(clinicId, serviceId, serviceFeeId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeService = createAsyncThunk(
  'clinics/removeService',
  async (
    requestData: {
      clinicId: string;
      serviceId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, serviceId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(APIS.removeClinicService(clinicId, serviceId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addService = createAsyncThunk(
  'clinics/addService',
  async (
    requestData: {
      clinicId: string;
      service: { _id?: string; name: string; price?: number; assignedDoctors?: string[] };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, service, runFunction } = requestData;
    try {
      const response = await axiosInstance.post(APIS.addClinicService(clinicId), service);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createPackage = createAsyncThunk(
  'clinics/createPackage',
  async (
    requestData: {
      clinicId: string;
      packageDetails: {
        name: string;
        fee: number;
        services: { service: string; qty: number }[];
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, packageDetails, runFunction } = requestData;
    try {
      const response = await axiosInstance.post(APIS.createPackage(clinicId), packageDetails);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deletePackage = createAsyncThunk(
  'clinics/deletePackage',
  async (
    requestData: {
      clinicId: string;
      packageId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicId, packageId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(APIS.deletePackage(clinicId, packageId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPackage = createAsyncThunk(
  'clinics/getPackage',
  async (
    requestData: {
      packageId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinicPackage | any> => {
    const { packageId, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(APIS.getPackage(packageId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addPackageService = createAsyncThunk(
  'clinics/addPackageService',
  async (
    requestData: {
      packageId: string;
      service: string;
      qty: number;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinicPackage | any> => {
    const { packageId, service, qty, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(APIS.addPackageService(packageId), {
        service,
        qty
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deletePackageService = createAsyncThunk(
  'clinics/deletePackageService',
  async (
    requestData: {
      packageId: string;
      serviceId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinicPackage | any> => {
    const { packageId, serviceId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(APIS.removePackageService(packageId, serviceId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePackageDetails = createAsyncThunk(
  'clinics/updatePackageDetails',
  async (
    requestData: {
      packageId: string;
      name: string;
      fee: number;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinicPackage | any> => {
    const { packageId, name, fee, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(APIS.updatePackageDetails(packageId), {
        name,
        fee
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addStaffToClinic = createAsyncThunk(
  'clinics/addStaffToClinic',
  async (
    requestData: {
      clinicIds: string[];
      staff: {
        _id: string;
        name: string;
        role: ClinicRole | string;
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const { clinicIds, staff, runFunction } = requestData;

    try {
      const response = await axiosInstance.post(STAFF_APIS.addStaffToClinic(), {
        staff,
        clinicIds
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const createClinic = createAsyncThunk(
  'clinics/createClinic',
  async (
    requestData: {
      organization: string;
      location: string;
      name: string;
      owner: string;
      occupation: string;
      phone: string;
      supportQueue: boolean;
      supportRooms: boolean;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IClinic | any> => {
    const {
      organization,
      location,
      name,
      owner,
      phone,
      occupation,
      supportQueue,
      supportRooms,
      runFunction
    } = requestData;
    try {
      const response = await axiosInstance.post(APIS.create(), {
        organization,
        location,
        name,
        owner,
        phone,
        occupation,
        supportQueue,
        supportRooms
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
