import { createSlice } from '@reduxjs/toolkit';
import { Gender } from 'redux/general.interface';
import {
  createPatient,
  getAvailableAppointments,
  searchPatientsWithPhone,
  getStaffSchedule,
  bookAppointment,
  bookAppointmentWithoutRooms,
  getAvailableResources
} from './actions';
import { ICreateAppointmentState, Steps } from './interfaces';

const patientsInitialState = {
  id: 0,
  _id: '',
  name: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  gender: Gender.MALE,
  createdAt: ''
};
const initialState: ICreateAppointmentState = {
  loading: false,
  error: false,
  step: Steps.patient,
  patient: patientsInitialState,
  availableSlots: [],
  staff: '',
  createPatient: false,
  services: [],
  resources: [],
  medicalRecord: []
};

const createAppointment = createSlice({
  name: 'createAppointment',
  initialState,
  reducers: {
    clearState(state) {
      state.patient = initialState.patient;
      state.step = initialState.step;
      state.availableSlots = initialState.availableSlots;
      state.staff = initialState.staff;
      state.error = initialState.error;
      state.createPatient = initialState.createPatient;
      state.schedule = undefined;
      state.services = [];
      state.medicalRecord = [];
    },
    setStepPatient(state) {
      state.step = Steps.patient;
    },
    setStepDate(state) {
      state.step = Steps.date;
    },
    setCreatePatient(state, action) {
      state.createPatient = action.payload;
    },
    setStaff(state, action) {
      state.staff = action.payload;
      state.schedule = undefined;
    },
    setSelectedSlot(state, action) {
      state.selectedSlot = action.payload;
    },
    changeServices(state, action) {
      state.services = action.payload;
    },
    changeMedicalRecord(state, action) {
      state.medicalRecord = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPatientsWithPhone.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.patient = patientsInitialState;
      })
      .addCase(searchPatientsWithPhone.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
      })
      .addCase(searchPatientsWithPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.patient = patientsInitialState;
      })
      .addCase(createPatient.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.patient = patientsInitialState;
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
        state.createPatient = false;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.patient = patientsInitialState;
      })
      .addCase(getAvailableAppointments.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.availableSlots = [];
      })
      .addCase(getAvailableAppointments.fulfilled, (state, action) => {
        state.loading = false;
        state.availableSlots = action.payload;
      })
      .addCase(getAvailableAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.availableSlots = [];
      })
      .addCase(getStaffSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.schedule = undefined;
      })
      .addCase(getStaffSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(getStaffSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.schedule = undefined;
      })
      .addCase(bookAppointment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(bookAppointment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(bookAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(bookAppointmentWithoutRooms.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(bookAppointmentWithoutRooms.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(bookAppointmentWithoutRooms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAvailableResources.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAvailableResources.fulfilled, (state, action) => {
        state.loading = false;
        state.resources = action.payload;
      })
      .addCase(getAvailableResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addDefaultCase((state, action) => {});
  }
});
export const {
  clearState,
  setStepPatient,
  setStepDate,
  setCreatePatient,
  setStaff,
  setSelectedSlot,
  changeServices,
  changeMedicalRecord
} = createAppointment.actions;
export default createAppointment.reducer;
