import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
// import { removeFromLocalStorage } from '../shared';
import APIS from './apis';
import { IOrganization } from './interfaces';

export const createOrganization = createAsyncThunk(
  'organization/createOrganization',
  async (
    requestData: {
      name: string;
      country: string;
      owner: { _id: string; name: string };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { name, country, owner, runFunction } = requestData;
    try {
      const response = await axiosInstance.post(APIS.createOrganization(), {
        name,
        country,
        owner
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getOwnerOrganization = createAsyncThunk(
  'organization/getOwnerOrganization',
  async (requestData: void, { rejectWithValue }): Promise<IOrganization | any> => {
    try {
      let localToken = localStorage.getItem('userToken');
      if (localToken) localToken = JSON.parse(localToken);
      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
          'content-type': 'application/json'
        }
      };
      const response = await axiosInstance.get(APIS.getOwnerOrganization(), config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getOrganizationsById = createAsyncThunk(
  'organization/getOrganizationsById',
  async (
    requestData: { organizationId: string },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getOrganizationsById(organizationId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateOrganizationsById = createAsyncThunk(
  'organization/updateOrganizationsById',
  async (
    requestData: {
      organizationId: string;
      name?: string;
      country?: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId, name, country, runFunction } = requestData;

    const body = {
      name,
      country
    };
    try {
      const response = await axiosInstance.patch(
        APIS.updateOrganizationsById(organizationId),
        body
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const toggleAutoRenew = createAsyncThunk(
  'organization/toggleAutoRenew',
  async (
    requestData: {
      organizationId: string;
      autoRenew: boolean;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId, autoRenew } = requestData;

    const body = {
      autoRenew
    };
    try {
      const response = await axiosInstance.patch(APIS.toggleAutoRenew(organizationId), body);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const subscribeToPlan = createAsyncThunk(
  'organization/subscribeOrganizationsToPlan',
  async (
    requestData: {
      organizationId: string;
      planId: string;
      duration: number;
      autoRenew?: boolean;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId, duration, autoRenew, planId } = requestData;

    const body = {
      duration,
      autoRenew
    };
    try {
      const response = await axiosInstance.post(
        APIS.subscribeOrganizationsToPlan(organizationId, planId),
        body
      );
      // removeFromLocalStorage('userProfile');
      // removeFromLocalStorage('userToken');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createClinic = createAsyncThunk(
  'organization/createClinic',
  async (
    requestData: {
      organizationId: string;
      name: string;
      owner: string;
      runFunction?: () => void;
      phone?: string;
      location?: string;
      supportQueue?: boolean;
      supportRooms?: boolean;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const {
      organizationId,
      name,
      phone,
      owner,
      location,
      supportQueue,
      supportRooms,
      runFunction
    } = requestData;

    const body = {
      name,
      phone,
      owner,
      location,
      supportQueue,
      supportRooms
    };
    try {
      const response = await axiosInstance.patch(APIS.createClinic(organizationId), body);

      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteClinic = createAsyncThunk(
  'organization/deleteClinic',
  async (
    requestData: {
      organizationId: string;
      clinicId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId, clinicId, runFunction } = requestData;

    try {
      const response = await axiosInstance.delete(APIS.deleteClinic(organizationId, clinicId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const inviteStaff = createAsyncThunk(
  'organization/inviteStaff',
  async (
    requestData: {
      organizationId: string;
      clinicId: string;
      email: string;
      role: string;
      occupation: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<string | any> => {
    const { organizationId, clinicId, email, role, occupation, runFunction } = requestData;

    const body = {
      email,
      role,
      occupation,
      clinicId
    };

    try {
      const response = await axiosInstance.post(APIS.inviteStaff(organizationId), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSentInvitations = createAsyncThunk(
  'organization/getSentInvitations',
  async (
    requestData: {
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<string | any> => {
    const { organizationId, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(APIS.getOrganizationInvitations(organizationId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteSentInvitations = createAsyncThunk(
  'organization/deleteSentInvitations',
  async (
    requestData: {
      invitationToken: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<string | any> => {
    const { invitationToken, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(
        APIS.deleteOrganizationInvitation(invitationToken)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeStaff = createAsyncThunk(
  'organization/removeStaff',
  async (
    requestData: {
      organizationId: string;
      staffId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId, staffId, runFunction } = requestData;

    try {
      const response = await axiosInstance.delete(APIS.removeStaff(organizationId, staffId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const finishOnBoarding = createAsyncThunk(
  'organization/finishOnBoarding',
  async (
    requestData: {
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IOrganization | any> => {
    const { organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(APIS.finishOnBoarding(organizationId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
