import { createSlice } from '@reduxjs/toolkit';
import { getOrganizationClinics, getUserClinics, getUserOrganizations } from './actions';
import { ISideMenuState } from './interfaces';

const initialState: ISideMenuState = {
  organizations: [],
  clinics: [],
  organization: { _id: '', name: '' },
  clinic: { _id: '', name: '' },
  loading: false,
  error: false
};

const sideMenu = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    selectOrganization(state, action) {
      state.organization = action.payload;
      localStorage.setItem('currentOrganization', JSON.stringify(action.payload));
    },
    selectClinic(state, action) {
      state.clinic = action.payload;
      localStorage.setItem('currentClinic', JSON.stringify(action.payload));
    },
    setClinicList(state, action) {
      state.clinics = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserOrganizations.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(getUserOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserClinics.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserClinics.fulfilled, (state, action) => {
        state.loading = false;
        state.clinics = action.payload;
      })
      .addCase(getUserClinics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOrganizationClinics.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOrganizationClinics.fulfilled, (state, action) => {
        state.loading = false;
        state.clinics = action.payload;
      })
      .addCase(getOrganizationClinics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { selectOrganization, selectClinic, setClinicList } = sideMenu.actions;
export default sideMenu.reducer;
