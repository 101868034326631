import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'redux/axios-instance';
import PATIENT_PACKAGE_APIS from 'redux/patientPackages/apis';
import INVOICES_APIS from 'redux/invoices/apis';
import PATIENTS_APIS from 'redux/patients/apis';
import { IPackage, IPackageService } from 'redux/patientPackages/interfaces';
import { IPatient } from 'redux/patients/interfaces';
import { IInvoice, PaymentMethod } from 'redux/invoices/interfaces';
import { AppointmentState, IAppointmentList } from 'redux/appointments/interfaces';
import APPOINTMENTS_APIS from 'redux/appointments/apis';
import PATIENT_MEDICAL_TABS_APIS, { TAB_DATA_APIS } from './apis';
import { ICreateTabDataDto, IPatientMedicalTab, ITabData, ITabDataList } from './interfaces';

export const getPatient = createAsyncThunk(
  'patientProfile/getPatient',
  async (
    requestData: {
      id: any;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { id, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(PATIENTS_APIS.getPatientById(id));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePatient = createAsyncThunk(
  'patientProfile/updatePatient',
  async (
    requestData: {
      patientId: string;
      patient: Partial<IPatient>;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { patientId, patient, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(PATIENTS_APIS.updatePatient(patientId), {
        ...patient,
        _id: undefined,
        createdAt: undefined,
        updatedAt: undefined
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePatientTemplateData = createAsyncThunk(
  'patientProfile/updatePatientTemplateData',
  async (
    requestData: {
      patientId: string;
      patient: Partial<IPatient>;
      templateId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { patientId, templateId, patient, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(
        PATIENTS_APIS.updatePatientTemplate(patientId, templateId),
        {
          ...patient,
          _id: undefined,
          createdAt: undefined,
          updatedAt: undefined
        }
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removePatient = createAsyncThunk(
  'patientProfile/removePatient',
  async (
    requestData: {
      patientId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { patientId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(PATIENTS_APIS.removePatient(patientId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPackages = createAsyncThunk(
  'patientProfile/getPatientPackages',
  async (
    requestData: {
      patientId: string;
      organizationId: string;
      clinicId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPackage[] | any> => {
    const { patientId, clinicId, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        PATIENT_PACKAGE_APIS.getPatientPackages(patientId, organizationId, clinicId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const buyPackage = createAsyncThunk(
  'patientProfile/buyPackage',
  async (
    requestData: {
      clinic: {
        _id: string;
        name: string;
      };
      organization: {
        _id: string;
        name: string;
      };
      patient: {
        _id: string;
        name: string;
      };
      clinicPackage: string;
      name: string;
      fee: number;
      services: IPackageService[];
      discount?: number;
      amount?: number;
      method?: PaymentMethod;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IInvoice | any> => {
    const { runFunction } = requestData;

    try {
      const response = await axiosInstance.post(INVOICES_APIS.buyPackage(), {
        ...requestData,
        runFunction: undefined
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const queryPatientAppointments = createAsyncThunk(
  'patientProfile/queryPatientAppointments',
  async (
    requestData: {
      clinicId: string;
      query: {
        patientId: string;
        staffId?: string;
        status?: AppointmentState | '';
        minStart?: string;
        maxStart?: string;
        minCreatedAt?: string;
        maxCreatedAt?: string;
        page?: number;
        pageSize?: number;
        sortType?: string;
        sortOrder?: 'asc' | 'desc';
      };
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointmentList | any> => {
    const { clinicId, query, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(
        APPOINTMENTS_APIS.queryAppointments(clinicId, query)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createPatientTabs = createAsyncThunk(
  'patientProfile/createPatientTabs',
  async (
    requestData: {
      patientId: string;
      medicalTemplateId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatientMedicalTab | any> => {
    const { patientId, medicalTemplateId, runFunction } = requestData;
    try {
      const response = await axiosInstance.post(
        PATIENT_MEDICAL_TABS_APIS.createTab(patientId, medicalTemplateId)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const findPatientTabs = createAsyncThunk(
  'patientProfile/findPatientTabs',
  async (
    requestData: {
      patientId: string;
    },
    { rejectWithValue }
  ): Promise<{ recording: IPatientMedicalTab[]; profile: IPatientMedicalTab[] } | any> => {
    const { patientId } = requestData;
    try {
      const response = await axiosInstance.get(
        PATIENT_MEDICAL_TABS_APIS.findPatientTabs(patientId)
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteTab = createAsyncThunk(
  'patientProfile/deleteTab',
  async (
    requestData: {
      tabId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatientMedicalTab[] | any> => {
    const { tabId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(PATIENT_MEDICAL_TABS_APIS.deleteTab(tabId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createTabData = createAsyncThunk(
  'patientProfile/createTabData',
  async (
    requestData: {
      createTabDataDto: ICreateTabDataDto;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ITabData | any> => {
    const { createTabDataDto, runFunction } = requestData;
    try {
      const response = await axiosInstance.post(TAB_DATA_APIS.createTabData(), createTabDataDto);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllTabData = createAsyncThunk(
  'patientProfile/getAllTabData',
  async (
    requestData: {
      tabId: string;
      page?: number;
      pageSize?: number;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ITabDataList | any> => {
    const { tabId, page, pageSize, runFunction } = requestData;
    try {
      const response = await axiosInstance.get(TAB_DATA_APIS.getAllTabData(tabId, page, pageSize));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateTabData = createAsyncThunk(
  'patientProfile/updateTabData',
  async (
    requestData: {
      tabId: string;
      row?: any;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ITabDataList | any> => {
    const { tabId, row, runFunction } = requestData;
    try {
      const response = await axiosInstance.patch(TAB_DATA_APIS.updateTabData(tabId), row);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteTabData = createAsyncThunk(
  'patientProfile/deleteTabData',
  async (
    requestData: {
      tabDataId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<string | any> => {
    const { tabDataId, runFunction } = requestData;
    try {
      const response = await axiosInstance.delete(TAB_DATA_APIS.deleteTabData(tabDataId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
