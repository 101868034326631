import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'redux/axios-instance';
import PATIENT_APIS from 'redux/patients/apis';
import APPOINTMENTS_APIS from 'redux/appointments/apis';
import SCHEDULES_APIS from 'redux/schedules/apis';
import { IPatient } from 'redux/patients/interfaces';
import { IAppointmentAvailable } from './interfaces';
import { ISchedule } from 'redux/schedules/interfaces';
import { IAppointment, IAppointmentMedicalRecord } from 'redux/appointments/interfaces';
import { INameAndId } from 'redux/general.interface';
import { IOrganization } from 'redux/organization/interfaces';
import { IInvoiceServices } from 'redux/invoices/interfaces';

// helper functions

const convertToHHMM = (date: Date) =>
  date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24'
  });

export const searchPatientsWithPhone = createAsyncThunk(
  'createAppointment/searchPatientsWithPhone',
  async (
    requestData: {
      phone: string;
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { phone, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        PATIENT_APIS.queryPatients({ page: 1, pageSize: 1, organizationId, phone })
      );
      if (runFunction) runFunction();
      return response.data.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createPatient = createAsyncThunk(
  'createAppointment/createPatient',
  async (
    requestData: {
      phone: string;
      name: string;
      email?: string;
      gender: string;
      dateOfBirth: string;
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { phone, name, email, gender, dateOfBirth, organizationId, runFunction } = requestData;

    const body = {
      organizationId,
      phone,
      name,
      email,
      gender,
      dateOfBirth
    };
    try {
      const response = await axiosInstance.post(PATIENT_APIS.createPatient(), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAvailableAppointments = createAsyncThunk(
  'createAppointment/getAvailableAppointments',
  async (
    requestData: {
      clinic: string;
      staff: string;
      day: Date;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointmentAvailable[] | any> => {
    const { clinic, staff, day, runFunction } = requestData;

    const today = new Date();
    const selectedDay = new Date(day);
    let startDate = day.toISOString();

    today.setUTCHours(0, 0, 0, 0);
    selectedDay.setUTCHours(0, 0, 0, 0);

    if (today.toISOString() !== selectedDay.toISOString()) {
      startDate = selectedDay.toISOString();
    }

    try {
      const response = await axiosInstance.get(
        APPOINTMENTS_APIS.getAvailableAppointments(startDate, clinic, staff)
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStaffSchedule = createAsyncThunk(
  'createAppointment/getStaffSchedule',
  async (
    requestData: {
      clinicId: string;
      staffId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { clinicId, staffId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        SCHEDULES_APIS.getAllClinicOrStaffSchedules(clinicId, staffId)
      );
      if (runFunction) runFunction();
      return response.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const bookAppointmentWithoutRooms = createAsyncThunk(
  'createAppointment/bookAppointmentWithoutRooms',
  async (
    requestData: {
      scheduleId: string;
      shift: string;
      patient: INameAndId;
      start: string;
      expectedEnd?: string;
      organization: IOrganization;
      services?: IInvoiceServices[];
      medicalRecord?: IAppointmentMedicalRecord[];
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const {
      scheduleId,
      shift,
      patient,
      start,
      services,
      medicalRecord,
      organization,
      runFunction,
      expectedEnd
    } = requestData;

    const body: any = {
      schedule: scheduleId,
      shift,
      patient,
      start,
      discount: 0,
      services,
      medicalRecord,
      status: 'Pending',
      organization: {
        // eslint-disable-next-line no-underscore-dangle
        _id: organization._id,
        name: organization.name
      }
    };

    body.start = new Date(body.start).toISOString();
    // Optional args
    if (expectedEnd) body.expectedEnd = new Date(expectedEnd).toISOString();

    try {
      const response = await axiosInstance.post(APPOINTMENTS_APIS.createAppointmentWithoutRooms(), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const bookAppointment = createAsyncThunk(
  'createAppointment/bookAppointment',
  async (
    requestData: {
      scheduleId: string;
      room?: string;
      shift: string;
      patient: INameAndId;
      staff: INameAndId;
      date: Date;
      expectedStartTime: Date | string;
      expectedEndTime: Date | string;
      organization: IOrganization;
      services?: IInvoiceServices[];
      resources?: string[];
      medicalRecord?: IAppointmentMedicalRecord[];
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IAppointment | any> => {
    const {
      scheduleId,
      room,
      shift,
      patient,
      expectedStartTime,
      date,
      staff,
      services,
      medicalRecord,
      organization,
      expectedEndTime,
      resources,
      runFunction
    } = requestData;

    const body: any = {
      schedule: scheduleId,
      room,
      shift,
      patient,
      discount: 0,
      services,
      medicalRecord,
      status: 'Pending',
      organization: {
        // eslint-disable-next-line no-underscore-dangle
        _id: organization._id,
        name: organization.name
      },
      expectedStartTime: new Date(expectedStartTime).toISOString(),
      expectedEndTime: new Date(expectedEndTime).toISOString(),
      date: new Date(date).toISOString(),
      resources,
      staff
    };

    try {
      const response = await axiosInstance.post(APPOINTMENTS_APIS.createAppointment(), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAvailableResources = createAsyncThunk(
  'resources/getAvailable',
  async (
    requestData: {
      room: string;
      date: Date;
      start: Date;
      end: Date;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<ISchedule | any> => {
    const { room, start, end, date, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(SCHEDULES_APIS.getAvailableResources(), {
        params: {
          room,
          start: convertToHHMM(new Date(start)),
          end: convertToHHMM(new Date(end)),
          date: new Date(date).toISOString()
        }
      });
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
