import { createSlice } from '@reduxjs/toolkit';
import { IScheduleState, ScheduleType } from './interfaces';
import {
  createSchedule,
  getClinicSchedules,
  getRoomsAvailability,
  getStaffSchedule,
  updateSchedule
} from './actions';

const scheduleInitialState = {
  _id: '',
  staff: {
    _id: '',
    name: ''
  },
  clinic: {
    _id: '',
    name: ''
  },
  slotDuration: 0,
  scheduleType: ScheduleType.slot
};

const initialState: IScheduleState = {
  schedule: scheduleInitialState,
  staffScheduleList: [],
  staffRoomScheduleEvents: [],
  allStaffRoomEvents: [],
  loading: false,
  error: false
};

const schedules = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    clearSchedule(state: any) {
      state.schedule = scheduleInitialState;
    },
    clearScheduleList(state: any) {
      state.staffScheduleList = [];
    },
    clearScheduleError(state: any) {
      state.error = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.schedule = scheduleInitialState;
      })
      .addCase(getStaffSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(getStaffSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getClinicSchedules.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getClinicSchedules.fulfilled, (state, action) => {
        state.loading = false;
        state.staffScheduleList = action.payload;
      })
      .addCase(getClinicSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(createSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSchedule.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedule = action.payload;
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRoomsAvailability.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getRoomsAvailability.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.staffOriented) state.allStaffRoomEvents = action.payload.data;
        state.staffRoomScheduleEvents = action.payload.data;
      })
      .addCase(getRoomsAvailability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { clearSchedule, clearScheduleList, clearScheduleError } = schedules.actions;
export default schedules.reducer;

/**
 * Paste one or more documents here
 */
