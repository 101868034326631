import { createSlice } from '@reduxjs/toolkit';
import {
  addGroupToPolicy,
  addPermissionToGroup,
  addUserToGroup,
  createGroupPermission,
  deleteGroup,
  getActions,
  getGroupPermissions,
  removePolicyFromGroup,
  removeUserFromGroup,
  getPolicies,
  createPolicy
} from './actions';
import { IPermissionsState } from './interfaces';

const initialState: IPermissionsState = {
  permissionsGroups: [],
  permissionsGroupsLoading: false,
  permissionsGroupsError: false,
  loading: false,
  error: false,
  refresh: false,
  policies: [],
  policiesLoading: false,
  policiesError: false
};

const groupPermissions = createSlice({
  name: 'groupPermissions',
  initialState,
  reducers: {
    setRefresh(state, action) {
      state.refresh = action.payload;
    },
    setGroup(state, action) {
      state.permissionsGroup = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupPermissions.pending, (state) => {
        state.permissionsGroupsLoading = true;
        state.permissionsGroupsError = false;
      })
      .addCase(getGroupPermissions.fulfilled, (state, action) => {
        state.permissionsGroupsLoading = false;
        state.permissionsGroups = action.payload;
      })
      .addCase(getGroupPermissions.rejected, (state, action) => {
        state.permissionsGroupsLoading = false;
        state.permissionsGroupsError = action.payload;
      })
      .addCase(getActions.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getActions.fulfilled, (state, action) => {
        state.loading = false;
        state.actionOptions = action.payload;
      })
      .addCase(getActions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createGroupPermission.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createGroupPermission.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createGroupPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addPermissionToGroup.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addPermissionToGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsGroup = action.payload;
      })
      .addCase(addPermissionToGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addUserToGroup.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addUserToGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsGroup = action.payload;
      })
      .addCase(addUserToGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeUserFromGroup.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeUserFromGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsGroup = action.payload;
      })
      .addCase(removeUserFromGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addGroupToPolicy.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addGroupToPolicy.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsGroup = action.payload;
      })
      .addCase(addGroupToPolicy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removePolicyFromGroup.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removePolicyFromGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsGroup = action.payload;
      })
      .addCase(removePolicyFromGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPolicies.pending, (state) => {
        state.policiesLoading = true;
        state.policiesError = false;
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        state.policiesLoading = false;
        state.policies = action.payload;
      })
      .addCase(getPolicies.rejected, (state, action) => {
        state.policiesLoading = false;
        state.policiesError = action.payload;
      })
      .addCase(createPolicy.pending, (state) => {
        state.policiesLoading = true;
        state.policiesError = false;
      })
      .addCase(createPolicy.fulfilled, (state, action) => {
        state.policiesLoading = false;
        state.policies = [...state.policies, action.payload.policy];
        if (action.payload.group) state.permissionsGroup = action.payload.group;
      })
      .addCase(createPolicy.rejected, (state, action) => {
        state.policiesLoading = false;
        state.policiesError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});

export const { setRefresh, setGroup } = groupPermissions.actions;
export default groupPermissions.reducer;
