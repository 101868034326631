import { createSlice } from '@reduxjs/toolkit';

import {
  addHeader,
  createMedicalTemplate,
  getMRTById,
  queryMRT,
  removeHeader,
  removeMedicalTemplate,
  updateMedicalTemplateInfo
} from './actions';
import { IMedicalTemplateState } from './interfaces';

const initialState: IMedicalTemplateState = {
  loading: false,
  error: false,
  listLoading: false,
  listError: false,
  refresh: false
};

const medicalTemplate = createSlice({
  name: 'medicalTemplate',
  initialState,
  reducers: {
    setMedicalTemplate(state, action) {
      state.medicalTemplate = action.payload;
    },
    clearMedicalTemplate(state) {
      state.medicalTemplate = undefined;
      state.error = false;
      state.loading = false;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryMRT.pending, (state) => {
        state.listLoading = true;
        state.listError = false;
      })
      .addCase(queryMRT.fulfilled, (state, action) => {
        state.listLoading = false;
        state.medicalTemplateList = action.payload;
      })
      .addCase(queryMRT.rejected, (state, action) => {
        state.listLoading = false;
        state.listError = action.payload;
      })
      .addCase(getMRTById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMRTById.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalTemplate = action.payload;
      })
      .addCase(getMRTById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createMedicalTemplate.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createMedicalTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalTemplate = action.payload;
      })
      .addCase(createMedicalTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMedicalTemplateInfo.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateMedicalTemplateInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalTemplate = action.payload;
      })
      .addCase(updateMedicalTemplateInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addHeader.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addHeader.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalTemplate = action.payload;
      })
      .addCase(addHeader.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeHeader.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeHeader.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalTemplate = action.payload;
      })
      .addCase(removeHeader.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeMedicalTemplate.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeMedicalTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalTemplate = undefined;
      })
      .addCase(removeMedicalTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { setMedicalTemplate, clearMedicalTemplate, setRefresh } = medicalTemplate.actions;
export default medicalTemplate.reducer;
