// material-ui
import {
  Button,
  Checkbox,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import Note from '../note';
import { gridSpacing } from 'store/constant';

// formik
import * as yup from 'yup';
import { useFormik } from 'formik';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';

// Actions
import { createClinic } from 'redux/clinics/actions';
import { LoadingButton } from '@mui/lab';
import { SNACKBAR_OPEN } from 'store/actions';
import { useEffect, useState } from 'react';
import { addOrganizationClinic } from 'redux/organization';
import { ClinicRole } from 'redux/clinics/interfaces';

const CreateClinicSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string(),
  location: yup.string().required(),
  supportRooms: yup.boolean().required(),
  occupation: yup.string().required()
});

const CreateClinicForm = ({ handleSteps }: { handleSteps: (action: 'next' | 'back') => void }) => {
  const dispatch = useAppDispatch();
  const [appointmentType, setAppointmentType] = useState('slot');
  const { organization, loading, error } = useAppSelector((state) => state.organization);

  const successFn = () => {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Clinic Created',
      variant: 'alert',
      alertSeverity: 'success'
    });
    formik.resetForm();
    handleSteps('next');
  };

  const handleAppointmentTypeChange = (event: any, value: any) => {
    setAppointmentType(value);
  };

  const submitCreateClinic = async (values: {
    name: string;
    phone: string;
    location: string;
    occupation: string;
    supportRooms: boolean;
  }) => {
    const response = await dispatch(
      createClinic({
        organization: organization._id,
        owner: organization.owner._id,
        name: values.name,
        location: values.location,
        phone: values.phone,
        occupation: values.occupation,
        supportQueue: appointmentType !== 'slot',
        supportRooms: values.supportRooms,
        runFunction: successFn
      })
    );

    dispatch(addOrganizationClinic(response.payload));
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      location: '',
      supportRooms: false,
      occupation: ''
    },
    validationSchema: CreateClinicSchema,
    onSubmit: submitCreateClinic,
    enableReinitialize: true
  });

  useEffect(() => {
    if (error?.validationErrors) formik.setErrors(error?.validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.validationErrors]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item>
        <DialogContentText>
          <Typography variant="body2" color="secondary" component="h3">
            Lets Start by setting up your <b>First Clinic</b> keeping in mind you can add other
            clinics or edit this one later Provide your clinic’s name, address and contact details
          </Typography>
          <Typography variant="body2" color="grey.900" component="h3">
            Provide your clinic’s name, address and contact details
          </Typography>
        </DialogContentText>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          autoFocus
          size="small"
          id="name"
          name="name"
          label="Clinic Name *"
          type="text"
          disabled={loading}
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          autoFocus
          size="small"
          id="location"
          name="location"
          label="Location *"
          type="text"
          fullWidth
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.location}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          autoFocus
          size="small"
          id="phone"
          label="Phone *"
          name="phone"
          type="tel"
          disabled={loading}
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.phone}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="occupation"
          id="staff-assign-role-onboarding"
          label="Owner's profession *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.occupation}
          error={formik.touched.occupation && Boolean(formik.errors.occupation)}
          helperText={formik.touched.occupation && formik.errors.occupation}
        >
          <MenuItem value="" disabled>
            Select Occupation
          </MenuItem>
          <MenuItem value={ClinicRole.DOCTOR}>Doctor</MenuItem>
          <MenuItem value={ClinicRole.ACCOUNTANT}>Accountant</MenuItem>
          <MenuItem value={ClinicRole.RECEPTIONIST}>Receptionist</MenuItem>
          <MenuItem value={ClinicRole.NURSE}>Nurse</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.supportRooms}
              onChange={formik.handleChange}
              id="supportRooms"
              name="supportRooms"
              color="primary"
            />
          }
          label={<Typography variant="subtitle1">Rooms System</Typography>}
        />
        <Note note="Check this box if your clinic has more than one doctor or different rooms in it" />
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <Typography variant="subtitle1">Appointment Type</Typography>
          <RadioGroup
            row
            aria-label="Appointment Type"
            value={appointmentType}
            onChange={handleAppointmentTypeChange}
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="slot" control={<Radio />} label="Time Slots" />
            <FormControlLabel value="queue" control={<Radio />} label="Queue" />
          </RadioGroup>
          <Note
            note={
              appointmentType === 'slot'
                ? 'Time slots represent the specific intervals during which you can schedule patient appointments or procedures'
                : 'Queue refers to the ordered list of patients waiting for their turn to be seen or attended to in the clinic.'
            }
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} container spacing={1} alignItems="center" justifyContent="flex-end">
        {organization?.clinics && organization.clinics.length > 0 && (
          <Grid item>
            <Button
              color="error"
              onClick={() => handleSteps('next')}
              disabled={loading}
              size="small"
            >
              Skip
            </Button>
          </Grid>
        )}
        <Grid item>
          <LoadingButton
            variant="outlined"
            loading={loading}
            onClick={formik.submitForm}
            disabled={loading}
            size="small"
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateClinicForm;
