import React, {
  createContext,
  //  useEffect,
  useReducer
} from 'react';
// import { useAppSelector } from 'store/hooks';
// third-party
// import { Chance } from 'chance';
// import jwtDecode from 'jwt-decode';

// reducer - state management
// import {
//   // LOGIN,
//   LOGOUT
// } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
// import Loader from 'ui-component/Loader';
// import axios from 'utils/axios';
import {
  initialLoginContextProps
  // KeyedObject
} from 'types';
import { JWTContextType } from 'types/auth';

// const chance = new Chance();

// constant
const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// const setSession = (serviceToken?: string | null) => {
//   if (serviceToken) {
//     localStorage.setItem('userToken', serviceToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
//   } else {
//     localStorage.removeItem('userToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [
    state
    // dispatch
  ] = useReducer(accountReducer, initialState);
  // const { token } = useAppSelector((state) => state.user);
  // useEffect(() => {
  //   const init = async () => {
  //     try {
  //       const serviceToken = window.localStorage.getItem('userToken');

  //       if (serviceToken) {
  //         setSession(serviceToken);

  //         // } else {
  //         //   dispatch({
  //         //     type: LOGOUT
  //         //   });
  //       }
  //     } catch (err) {
  //       // console.error(err);
  //       // dispatch({
  //       //   type: LOGOUT
  //       // });
  //     }
  //   };

  //   init();
  // }, [token]);

  const login = async (email: string, password: string) => {};

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {};

  const logout = () => {};

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  // if (state.isInitialized !== undefined && !state.isInitialized) {
  //   return <Loader />;
  // }

  return (
    <JWTContext.Provider
      value={{ ...state, login, logout, register, resetPassword, updateProfile }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
