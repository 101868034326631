import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios-instance';
import { INameAndId } from '../general.interface';

import APIS from './apis';

export const getUserOrganizations = createAsyncThunk(
  'sideMenu/getUserOrganizations',
  async (
    requestData: {
      staffId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<INameAndId[] | any> => {
    const { staffId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getUserOrganizations(staffId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getUserClinics = createAsyncThunk(
  'sideMenu/getUserClinics',
  async (
    requestData: {
      staffId: string;
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<INameAndId[] | any> => {
    const { staffId, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getUserClinics(organizationId, staffId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getOrganizationClinics = createAsyncThunk(
  'sideMenu/getOrganizationClinics',
  async (
    requestData: {
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<INameAndId[] | any> => {
    const { organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getOrganizationClinics(organizationId));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
