import {
  Grid,
  // Autocomplete,
  TextField,
  DialogContentText,
  Typography,
  MenuItem
} from '@mui/material';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SNACKBAR_OPEN } from 'store/actions';
import { addService } from 'redux/clinics/actions';
// import { getGeneralServices } from 'redux/generalServices/actions';
import { selectClinic } from 'redux/sideMenu';

const AddServiceSchema = yup.object().shape({
  name: yup.string().required(),
  clinicId: yup.string().required()
});

const AddServiceForm = ({ handleSteps }: { handleSteps: (action: 'next' | 'back') => void }) => {
  const dispatch = useAppDispatch();
  const { clinic, loading } = useAppSelector((state) => state.clinics);
  const { organization } = useAppSelector((state) => state.organization);
  // const { generalServices, error, loading } = useAppSelector((state) => state.generalServices);

  const successFn = () => {
    formik.resetForm();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Service Added',
      variant: 'alert',
      alertSeverity: 'success'
    });
  };

  const submitAddService = (values: { name: string; clinicId: string }) => {
    dispatch(
      addService({
        clinicId: values.clinicId,
        service: { name: values.name },
        runFunction: successFn
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      clinicId: clinic?._id || ''
    },
    validationSchema: AddServiceSchema,
    onSubmit: submitAddService,
    enableReinitialize: true
  });

  // useEffect(() => {
  //   if (error?.message)
  //     dispatch({
  //       type: SNACKBAR_OPEN,
  //       open: true,
  //       message: error.message,
  //       variant: 'alert',
  //       alertSeverity: 'error'
  //     });
  // }, [dispatch, error]);

  // useEffect(() => {
  //   if (formik.values.name) dispatch(getGeneralServices({ serviceName: formik.values.name }));
  // }, [dispatch, formik.values.name]);

  useEffect(() => {
    if (formik.values.clinicId) {
      const selectedClinic = organization.clinics?.find(
        (cln) => cln._id === formik.values.clinicId
      );
      if (selectedClinic) dispatch(selectClinic(selectedClinic));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.clinicId]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item>
        <DialogContentText>
          <Typography variant="body2" component="span">
            Control what services the clinic provides by adding them and latter add their fee
            corresponding to the staff providing the service.
          </Typography>
        </DialogContentText>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="clinicId"
          id="staff-assign-clinicId-onboarding"
          label="Clinic *"
          size="small"
          fullWidth
          select
          disabled={loading}
          onChange={formik.handleChange}
          value={formik.values.clinicId}
          error={formik.touched.clinicId && Boolean(formik.errors.clinicId)}
          helperText={formik.touched.clinicId && formik.errors.clinicId}
        >
          <MenuItem value="" disabled>
            Select Clinic
          </MenuItem>
          {organization.clinics &&
            organization.clinics.map((clinic) => (
              <MenuItem key={clinic._id} value={clinic._id}>
                {clinic.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={9}>
        {/* <Autocomplete
          freeSolo
          id="autoCompleteService"
          value={formik.values.name}
          disableClearable
          loading={loading}
          options={generalServices.map((option) => option.name)}
          onChange={formik.handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched?.name && Boolean(formik.errors?.name)}
              helperText={formik.touched?.name && formik.errors?.name}
              label="Service Name *"
              size="small"
              id="autoCompleteServiceText"
              name="name"
              disabled={loading}
              InputProps={{
                ...params.InputProps,
                type: 'search'
              }}
            />
          )}
        /> */}
        <TextField
          label="Service Name *"
          size="small"
          id="autoCompleteServiceText"
          name="name"
          fullWidth
          disabled={loading}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched?.name && Boolean(formik.errors?.name)}
          helperText={formik.touched?.name && formik.errors?.name}
        />
      </Grid>
      <Grid item xs={3} container justifyContent="flex-end">
        <Grid item>
          <LoadingButton
            loading={loading}
            onClick={formik.submitForm}
            disabled={loading}
            variant="outlined"
          >
            Add Service
          </LoadingButton>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {clinic?.services &&
          clinic?.services.map((service, index) => (
            <Grid item key={service.serviceId._id}>
              <Typography sx={{ textTransform: 'capitalize' }}>{service.serviceId.name}</Typography>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default AddServiceForm;
