import { useEffect, useState, useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PatientSection from './PatientSection';
import DateSection from './DateSection';
import { clearState, setStepPatient, setStepDate } from 'redux/createAppointment';
import { Steps } from 'redux/createAppointment/interfaces';
import { bookAppointmentWithoutRooms } from 'redux/createAppointment/actions';
import { SNACKBAR_OPEN } from 'store/actions';
import ServicesSection from './ServiceSection';
import { gridSpacing } from 'store/constant';
import MedicalRecordSection from './MedicalRecordSection';
import { setRefresh } from 'redux/appointments';

// ===============================|| UI DIALOG - SCROLLABLE ||=============================== //

export type scrollType = 'body' | 'paper' | undefined;

export default function CreateAppointmentDialog(props: { patientProfile: string; }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    patient,
    step,
    selectedSlot,
    services, 
    schedule, 
    medicalRecord,
    error
  } = useAppSelector((state) => state.createAppointment);
  const { clinic } = useAppSelector((state) => state.clinics);
  const { organization } = useAppSelector((state) => state.organization);

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<scrollType>('paper');

  const { patientProfile } = props;

  const handleClickOpen = (scrollType: scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStep = (value: Steps) => {
    switch (value) {
      case Steps.patient:
        dispatch(setStepPatient());
        break;
      case Steps.date:
        dispatch(setStepDate());
        break;
      default:
        break;
    }
  };

  const descriptionElementRef: React.Ref<HTMLSelectElement> = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement?.focus();
      }
    }
  }, [open]);
  useEffect(() => {
    if (!open) {
      dispatch(clearState());
    }
  }, [dispatch, open]);
  const handleCreateAppointment = () => {
    if (patient && selectedSlot && schedule && clinic.supportRooms === false)
      dispatch(
        bookAppointmentWithoutRooms({
          organization,
          patient: { _id: patient?._id, name: patient?.name },
          shift: selectedSlot?._id,
          start: selectedSlot.start,
          scheduleId: schedule._id,
          services,
          medicalRecord,
          runFunction: successFn
        })
      );
  };

  // const handleUpdateSuccess = () => {};

  const successFn = () => {
    handleClose();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Appointment Created',
      variant: 'alert',
      alertSeverity: 'success'
    });
    // handleUpdateSuccess();
    dispatch(setRefresh());
  };

  useEffect(() => {
    if (error?.message)
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: error.message,
        variant: 'alert',
        alertSeverity: 'error'
      });
  }, [dispatch, error]);

  return (
    <>
      {patientProfile === "false" ? (
        <Button onClick={handleClickOpen('paper')} fullWidth variant="contained">
          Create Appointment
        </Button>)
        : (
          <Button onClick={handleClickOpen('paper')} size="small" variant="contained">
            Add +
          </Button>
        )}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title">Book Appointment</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          {step === Steps.patient && (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <PatientSection />
              </Grid>
              <Grid item xs={12}>
                <ServicesSection />
              </Grid>
              <Grid item xs={12}>
                <MedicalRecordSection />
              </Grid>
            </Grid>
          )}
          {step === Steps.date && <DateSection />}
          {error?.message && !error?.validationErrors?.phone && (
            <Typography color="error" variant="subtitle1">
              {error.message}
            </Typography>
          )}
          {error?.validationErrors?.phone && (
            <Typography color="error" variant="subtitle1">
              Phone Number Format is not supported
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
          {step === Steps.patient ? (
            <Button
              sx={{ color: theme.palette.error.dark }}
              onClick={handleClose}
              color="secondary"
            >
              Cancel
            </Button>
          ) : (
            <Button
              sx={{ color: theme.palette.error.dark }}
              onClick={() => handleStep(Steps.patient)}
              color="secondary"
            >
              back
            </Button>
          )}
          {step === Steps.patient ? (
            <Button
              variant="contained"
              size="small"
              disabled={!patient?._id}
              onClick={() => handleStep(Steps.date)}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!selectedSlot}
              size="small"
              onClick={handleCreateAppointment}
            >
              Create Appointment
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
