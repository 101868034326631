import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfileSections } from 'redux/medicalTemplate/interfaces';
import axiosInstance from '../axios-instance';
import { Gender } from '../general.interface';
import APIS from './apis';
import { IAddress, IEmergencyContact, IPatient } from './interfaces';

export const queryPatients = createAsyncThunk(
  'patients/queryPatients',
  async (
    requestData: {
      page: number;
      pageSize: number;
      organizationId: string;
      _id?: string;
      name?: string;
      email?: string;
      phone?: string;
      dateOfBirth?: Date | { from: Date; to: Date };
      createdAt?: { from: Date; to: Date };
      gender?: Gender;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient[] | any> => {
    const {
      _id,
      phone,
      email,
      name,
      dateOfBirth,
      createdAt,
      gender,
      organizationId,
      runFunction
    } = requestData;

    let { page, pageSize } = requestData;
    page += 1;
    if (!pageSize || pageSize < 1) pageSize = 10;

    try {
      const response = await axiosInstance.get(
        APIS.queryPatients({
          page,
          pageSize,
          organizationId,
          _id,
          phone,
          name,
          email,
          dateOfBirth,
          createdAt,
          gender
        }),
        {
          headers: {
            organization: '61c45601b9af322b361a489a',
            clinic: '61c46dc1212002130fe64709'
          }
        }
      );
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getSinglePatientWithPhone = createAsyncThunk(
  'patients/getSinglePatientWithPhone',
  async (
    requestData: {
      phone: string;
      organizationId: string;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { phone, organizationId, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(
        APIS.queryPatients({ page: 1, pageSize: 1, organizationId, phone })
      );
      if (runFunction) runFunction();
      return response.data.patientList[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getSinglePatientById = createAsyncThunk(
  'patients/id',
  async (
    requestData: {
      id: any;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { id, runFunction } = requestData;

    try {
      const response = await axiosInstance.get(APIS.getPatientById(id));
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const findDefaultTemplates = createAsyncThunk(
  'patients/findDefaultTemplates',
  async (
    requestData: {
      organizationId: string;
    },
    { rejectWithValue }
  ): Promise<IProfileSections[] | any> => {
    const { organizationId } = requestData;
    try {
      const response = await axiosInstance.get(APIS.findDefaultTemplates(organizationId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createPatient = createAsyncThunk(
  'patients/createPatient',
  async (
    requestData: {
      organizationId: string;
      phone: string;
      name: string;
      email?: string;
      gender: string;
      dateOfBirth: string;
      address?: IAddress;
      emergencyContact?: IEmergencyContact;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const {
      organizationId,
      phone,
      name,
      email,
      gender,
      dateOfBirth,
      address,
      emergencyContact,
      runFunction
    } = requestData;

    const body = {
      organizationId,
      phone,
      name,
      email,
      gender,
      dateOfBirth,
      address,
      emergencyContact
    };
    try {
      const response = await axiosInstance.post(APIS.createPatient(), body);
      if (runFunction) runFunction();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePatient = createAsyncThunk(
  'patients/updatePatient',
  async (
    requestData: {
      id: string;
      patient: IPatient;
      setErrors: any;
      stopEdit: any;
      runFunction?: () => void;
    },
    { rejectWithValue }
  ): Promise<IPatient | any> => {
    const { id, patient, setErrors, stopEdit, runFunction } = requestData;

    try {
      const response = await axiosInstance.patch(APIS.updatePatient(id), {
        ...patient,
        _id: undefined,
        createdAt: undefined,
        updatedAt: undefined
      });
      if (runFunction) runFunction();
      stopEdit(false);
      return response.data;
    } catch (error: any) {
      const errorObj: any = rejectWithValue(error?.response?.data);
      setErrors(errorObj.payload?.validationErrors);
      return errorObj;
    }
  }
);
