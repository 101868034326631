import { useEffect, useState } from 'react';
import { Button, Grid, Typography, CircularProgress } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import DatePicker from './DatePicker';
import { IAppointmentAvailable } from 'redux/createAppointment/interfaces';
import { setSelectedSlot } from 'redux/createAppointment';
import { getAvailableAppointments } from 'redux/createAppointment/actions';
import format from 'date-fns/format';
import { gridSpacing } from 'store/constant';

const DateAndTimeSection = () => {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { availableSlots, loading, selectedSlot, staff } = useAppSelector(
    (state) => state.createAppointment
  );
  const { clinic } = useAppSelector((state) => state.clinics);
  const handelSelectTime = (time: IAppointmentAvailable) => {
    dispatch(setSelectedSlot(time));
  };

  useEffect(() => {
    if (staff) {
      dispatch(getAvailableAppointments({ clinic: clinic?._id, staff, day: selectedDate }));
    }
  }, [clinic._id, dispatch, selectedDate, staff]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} md={12} lg={7} container>
        <Grid item container alignItems="center" marginY={2}>
          <CalendarTodayIcon />
          <Typography component="span" variant="subtitle1" marginLeft={1}>
            Date
          </Typography>
        </Grid>
        <Grid border="2px solid lightGrey" maxHeight="400px" borderRadius={2} padding="1px">
          <DatePicker value={selectedDate} setValue={setSelectedDate} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={5}>
        <Grid item container alignItems="center" marginY={2}>
          <AccessTimeIcon />
          <Typography component="span" variant="subtitle1" marginLeft={1}>
            Time
          </Typography>
        </Grid>
        <Grid
          border="2px solid lightGrey"
          borderRadius={2}
          maxHeight="370px"
          minHeight="370px"
          overflow="scroll"
          paddingX={1}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            availableSlots?.map((appointment) => (
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 1 }}
                color={selectedSlot?.start === appointment.start ? 'secondary' : 'primary'}
                key={appointment.start}
                className="selectDate"
                onClick={() => handelSelectTime(appointment)}
              >
                {`${format(new Date(appointment.start), 'h:mm a')}
      to ${format(new Date(appointment.end), 'h:mm a')}`}
              </Button>
            ))
          )}
          {availableSlots && availableSlots.length === 0 && (
            <Typography variant="subtitle1" textAlign="center" color="info">
              No Available Appointments
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DateAndTimeSection;
