import { INameAndId } from 'redux/general.interface';

export enum ScheduleType {
  slot = 'slot',
  queue = 'queue'
}

export interface DayOfWeek {
  start: string;
  end: string;
  _id: string;
}
export interface ISchedule {
  _id: string;
  staff: INameAndId;
  clinic: INameAndId;
  room?: boolean;
  slotDuration: number;
  scheduleType: ScheduleType;
  saturday?: DayOfWeek[];
  sunday?: DayOfWeek[];
  monday?: DayOfWeek[];
  tuesday?: DayOfWeek[];
  wednesday?: DayOfWeek[];
  thursday?: DayOfWeek[];
  friday?: DayOfWeek[];
}

export interface IRoomStaffSchedule {
  rooms: (INameAndId & { staff: INameAndId })[];
  start: Date;
  end: Date;
  staff?: INameAndId;
}
export interface IScheduleState {
  schedule: ISchedule;
  staffScheduleList: ISchedule[];
  staffRoomScheduleEvents: IRoomStaffSchedule[];
  allStaffRoomEvents: IRoomStaffSchedule[];
  loading: boolean;
  error: any;
}
