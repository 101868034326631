import { INameAndId } from '../general.interface';

export interface IAppointmentAvailable {
  start: string;
  end: string;
}

export interface IAppointmentSchedule {
  _id: string;
  staff: INameAndId;
  clinic: INameAndId;
  room?: boolean;
}

export interface IMinMetrics {
  booked: number;
  arrived: number;
  waiting: number;
  started: number;
  finished: number;
  canceled: number;
}

export interface IAppointmentMedicalRecord {
  _id: string;
  title: string;
  description: string;
  author: INameAndId;
  date: string;
}
export interface IAppointmentList {
  page: number;
  pages: number;
  count: number;
  pageSize: number;
  data: IAppointment[];
}

export enum AppointmentState {
  booked = 'booked',
  arrived = 'arrived',
  confirmed = 'confirmed',
  waiting = 'waiting',
  started = 'started',
  finished = 'finished',
  canceled = 'canceled'
}

export interface IStatus {
  state: AppointmentState;
  date: string;
}

export interface IAppointment {
  _id: string;
  patient: INameAndId;
  schedule: IAppointmentSchedule;
  start?: string;
  expectedEnd?: string;
  expectedStartTime: string;
  expectedEndTime: string;
  status: IStatus[];
  currentState: AppointmentState;
  isRescheduled?: string[];
  queueNumber?: number;
  isCanceled?: string;
  cancelReason?: string;
  medicalRecord: IAppointmentMedicalRecord[];
  createdAt: string;
  updatedAt: string;
  staff?: INameAndId; // For appointment with room schedule
}

export interface IAppointmentState {
  appointment: IAppointment;
  appointmentList?: IAppointmentList;
  loading: boolean;
  error: any;
  minimalMetrics?: IMinMetrics;
  minimalMetricsLoading: boolean;
  minimalMetricsError: any;
  appointmentListLoading: boolean;
  appointmentListError: any;
  availableAppointmentsList: IAppointmentAvailable[];
  getAvailableLoading: boolean;
  getAvailableError: any;
  refresh: boolean;
}
