import { createSlice } from '@reduxjs/toolkit';
import { IPatientProfileState } from './interfaces';
import {
  buyPackage,
  createPatientTabs,
  createTabData,
  deleteTab,
  deleteTabData,
  findPatientTabs,
  getAllTabData,
  getPackages,
  getPatient,
  queryPatientAppointments,
  removePatient,
  updatePatient,
  updatePatientTemplateData
} from './actions';

const initialState: IPatientProfileState = {
  appointmentsLoading: false,
  appointmentsError: false,
  patientLoading: false,
  patientError: false,
  packagesLoading: false,
  packagesError: false,
  invoiceLoading: false,
  invoiceError: false,
  tabsLoading: false,
  tabsError: false,
  tabs: [],
  refresh: false,
  tabDataListLoading: false,
  tabDataListError: false,
  tabDataLoading: false,
  tabDataError: false,
  refreshTab: false
};

const patientProfile = createSlice({
  name: 'patientProfile',
  initialState,
  reducers: {
    resetState(state) {
      state.patientLoading = false;
      state.patientError = false;
      state.patient = undefined;
      state.appointmentsLoading = false;
      state.appointmentsError = false;
      state.appointments = undefined;
      state.packagesLoading = false;
      state.packagesError = false;
      state.packages = undefined;
      state.tabsLoading = false;
      state.tabsError = false;
      state.tabs =[];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatient.pending, (state) => {
        state.patientLoading = true;
        state.patientError = false;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.patientLoading = false;
        state.patient = action.payload;
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.patientLoading = false;
        state.patientError = action.payload;
      })
      .addCase(updatePatient.pending, (state) => {
        state.patientLoading = true;
        state.patientError = false;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.patientLoading = false;
        state.patient = action.payload;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.patientLoading = false;
        state.patientError = action.payload;
      })
      .addCase(removePatient.pending, (state) => {
        state.patientLoading = true;
        state.patientError = false;
      })
      .addCase(removePatient.fulfilled, (state) => {
        state.patientLoading = false;
      })
      .addCase(removePatient.rejected, (state, action) => {
        state.patientLoading = false;
        state.patientError = action.payload;
      })
      .addCase(getPackages.pending, (state) => {
        state.packagesLoading = true;
        state.packagesError = false;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.packagesLoading = false;
        state.packages = action.payload;
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.packagesLoading = false;
        state.packagesError = action.payload;
      })
      .addCase(buyPackage.pending, (state) => {
        state.packagesLoading = true;
        state.packagesError = false;
      })
      .addCase(buyPackage.fulfilled, (state, action) => {
        state.packagesLoading = false;
        state.invoice = action.payload;
      })
      .addCase(buyPackage.rejected, (state, action) => {
        state.packagesLoading = false;
        state.packagesError = action.payload;
      })
      .addCase(queryPatientAppointments.pending, (state) => {
        state.appointmentsLoading = true;
        state.appointmentsError = false;
      })
      .addCase(queryPatientAppointments.fulfilled, (state, action) => {
        state.appointmentsLoading = false;
        state.appointments = action.payload;
      })
      .addCase(queryPatientAppointments.rejected, (state, action) => {
        state.appointmentsLoading = false;
        state.appointmentsError = action.payload;
      })
      .addCase(createPatientTabs.pending, (state) => {
        state.tabsLoading = true;
        state.tabsError = false;
      })
      .addCase(createPatientTabs.fulfilled, (state) => {
        state.tabsLoading = false;
        state.refresh = !state.refresh;
      })
      .addCase(createPatientTabs.rejected, (state, action) => {
        state.tabsLoading = false;
        state.tabsError = action.payload;
      })
      .addCase(findPatientTabs.pending, (state) => {
        state.tabsLoading = true;
        state.tabsError = false;
      })
      .addCase(findPatientTabs.fulfilled, (state, action) => {
        state.tabsLoading = false;
        state.tabs = action.payload;
      })
      .addCase(findPatientTabs.rejected, (state, action) => {
        state.tabsLoading = false;
        state.tabsError = action.payload;
      })
      .addCase(deleteTab.pending, (state) => {
        state.tabsLoading = true;
        state.tabsError = false;
      })
      .addCase(deleteTab.fulfilled, (state) => {
        state.tabsLoading = false;
        state.refresh = !state.refresh;
      })
      .addCase(deleteTab.rejected, (state, action) => {
        state.tabsLoading = false;
        state.tabsError = action.payload;
      })
      .addCase(createTabData.pending, (state) => {
        state.tabDataLoading = true;
        state.tabDataError = false;
      })
      .addCase(createTabData.fulfilled, (state) => {
        state.tabDataLoading = false;
        state.refreshTab = !state.refreshTab;
      })
      .addCase(createTabData.rejected, (state, action) => {
        state.tabDataLoading = false;
        state.tabDataError = action.payload;
      })
      .addCase(getAllTabData.pending, (state) => {
        state.tabDataListLoading = true;
        state.tabDataListError = false;
      })
      .addCase(getAllTabData.fulfilled, (state, action) => {
        state.tabDataListLoading = false;
        state.tabDataList = action.payload;
      })
      .addCase(getAllTabData.rejected, (state, action) => {
        state.tabDataListLoading = false;
        state.tabDataListError = action.payload;
      })
      .addCase(deleteTabData.pending, (state) => {
        state.tabDataLoading = true;
        state.tabDataError = false;
      })
      .addCase(deleteTabData.fulfilled, (state) => {
        state.tabDataLoading = false;
        state.refreshTab = !state.refreshTab;
      })
      .addCase(deleteTabData.rejected, (state, action) => {
        state.tabDataLoading = false;
        state.tabDataError = action.payload;
      })
      .addCase(updatePatientTemplateData.pending, (state) => {
        state.patientLoading = true;
        state.patientError = false;
      })
      .addCase(updatePatientTemplateData.fulfilled, (state, action) => {
        state.patientLoading = false;
        state.patient = action.payload;
      })
      .addCase(updatePatientTemplateData.rejected, (state, action) => {
        state.patientLoading = false;
        state.patientError = action.payload;
      })
      .addDefaultCase((state, action) => {});
  }
});
export const { resetState } = patientProfile.actions;
export default patientProfile.reducer;
