import { FINANCE_SERVICE_URL } from '../apis';

const PATIENT_PACKAGES_APIS = {
  getPatientPackages: (patientId: string, organizationId: string, clinicId: string) =>
    // eslint-disable-next-line prefer-template
    `${FINANCE_SERVICE_URL}packages/?patientId=${patientId}` +
    (clinicId ? `&clinicId=${clinicId}` : '') +
    (organizationId ? `&organizationId=${organizationId}` : ''),
  postPatientPackages: () => `${FINANCE_SERVICE_URL}packages`,
  getPackagesById: (packageId: string) => `${FINANCE_SERVICE_URL}packages/${packageId}`
};
export default PATIENT_PACKAGES_APIS;
