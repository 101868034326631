/* eslint-disable prefer-template */
import { PATIENT_SERVICE_URL } from '../apis';

const PATIENT_MEDICAL_TABS_APIS = {
  createTab: (patientId: string, medicalTemplateId: string) =>
    `${PATIENT_SERVICE_URL}patient-medical-tabs/patient/${patientId}/medical-template/${medicalTemplateId}`,
  findPatientTabs: (patientId: string) =>
    `${PATIENT_SERVICE_URL}patient-medical-tabs/patient/${patientId}`,
  deleteTab: (tabId: string) => `${PATIENT_SERVICE_URL}patient-medical-tabs/${tabId}`,
  deleteAllPatientTabs: (patientId: string) =>
    `${PATIENT_SERVICE_URL}patient-medical-tabs/patient/${patientId}`
};
export default PATIENT_MEDICAL_TABS_APIS;

export const TAB_DATA_APIS = {
  createTabData: () => `${PATIENT_SERVICE_URL}tab-data`,
  getAllTabData: (tabId: string, page?: number, pageSize?: number) =>
    `${PATIENT_SERVICE_URL}tab-data?tabId=${tabId}` +
    (page ? `&page=${page}` : '') +
    (pageSize ? `&pageSize=${pageSize}` : ''),
  updateTabData: (tabId: string) => `${PATIENT_SERVICE_URL}tab-data/${tabId}`,
  deleteTabData: (dataId: string) => `${PATIENT_SERVICE_URL}tab-data/${dataId}`
};
